import React, { Fragment, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import "../../styles/home.scss";
import "../../styles/reset.scss";
import Moment from 'react-moment';
import 'moment-timezone';
import UsersService from '../../services/users';
import AgendasService from '../../services/agendas';
import Modal from "../layouts/modal";
import ModalDangerSmall from '../layouts/modalDangerSmall';

function ContentHome() {
    const [name, setName] = useState();
    const [agendas, setAgendas] = useState();
    const [agendaAtiva, setAgendaAtiva] = useState();
    const [modalCriarAgenda, setModalCriarAgenda] = useState(false);
    const [modalAgenda, setModalAgenda] = useState(false);
    const [modalEditarAgenda, setModalEditarAgenda] = useState(false);
    const [modalDeleteAgenda, setModalDeleteAgenda] = useState(false);
    const [anotacoes, setAnotacoes] = useState();
    const [anotacaoAtiva, setAnotacaoAtiva] = useState();
    const [modalCriarAnotacao, setModalCriarAnotacao] = useState(false);
    const [modalAnotacao, setModalAnotacao] = useState(false);
    const [modalEditarAnotacao, setModalEditarAnotacao] = useState(false);
    const [modalDeleteAnotacao, setModalDeleteAnotacao] = useState(false);
    Moment.globalLocale = 'pt-BR';

    async function getAgendas() {
        try {
            const res = await AgendasService.index();
            setAgendas(res.data);
        } catch (error) {
            console.log(error);
        }
    }

    async function getAnotacoes() {
        try {
            const res = await UsersService.anotacoes();
            setAnotacoes(res.data.anotacoes);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        (async function() {
            const response = await UsersService.logado();
            setName(response.data.user.name.split(' ').shift());
        })();

        getAgendas();
        getAnotacoes();
    }, []);

    const createAnotacao = async event => {
        event.preventDefault();

        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData);
        
        try {
            await UsersService.criar_anotacao(data);
            getAnotacoes();
            setModalCriarAnotacao(false);
        } catch (error) {
            console.log(error)
        }
    }

    const editAnotacao = async event => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData);

        try {
            await UsersService.editar_anotacao(data, anotacaoAtiva._id);
            getAnotacoes();
            setModalEditarAnotacao(false);
        } catch (error) {
            console.log(error)
        }
    }

    async function deleteAnotacao(id) {
        try {
            await UsersService.delete_anotacao(id);
            getAnotacoes();
            setModalDeleteAnotacao(false);
        } catch (error) {
            console.log(error)
        }
    }

    const createAgenda = async event => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const form = Object.fromEntries(formData);
        form.data = new Date(`${form.data} ${form.horario}`);

        delete form.horario;

        try {
            await UsersService.criar_agenda(form);
            getAgendas();
            setModalCriarAgenda(false);
        } catch (error) {
            console.log(error)
        }
    }

    const editAgenda = async event => {
        event.preventDefault();

        const formData = new FormData(event.target);
        const req = Object.fromEntries(formData);

        if (req.horario === "") {
            delete req.horario;

            if (req.data === "") {
                delete req.data;
            } else {
                const dataAtual = new Date(agendaAtiva.data)
                req.data = new Date(`${req.data}, ${dataAtual.getHours().toString()}:${dataAtual.getMinutes().toString()}`);
            }
        } else {
            if (req.data === "") {
                const dataAtual = new Date(agendaAtiva.data)
                console.log(dataAtual);
                req.data = new Date(`${dataAtual.getUTCFullYear()}, ${dataAtual.getUTCMonth()+1}, ${dataAtual.getUTCDate()}, ${req.horario}`);
            } else {
                req.data = new Date(`${req.data} ${req.horario}`);
                delete req.horario;
            }
        }

        try {
            await UsersService.editar_agenda(req, agendaAtiva._id);
            getAgendas();
            setModalEditarAgenda(false);
        } catch (error) {
            console.log(error)
        }
    }

    async function deleteAgenda(id) {
        try {
            await UsersService.delete_agenda(id);
            getAgendas();
            setModalDeleteAgenda(false);
        } catch (error) {
            console.log(error)
        }
    }

    function ordemData(a, b){
        return a.data > b.data ? -1 : a.data < b.data ? 1 : 0;
    }

    document.title = 'Inicio - JMX Corretora de Seguros';

    return(
        <Fragment>
            { (anotacoes !== "") && (agendas !== "") && (name !== "") ? <main id='main-home'>
                <div className="saudacoes-user">
                    <h2>Olá, <span>{name}</span>!</h2>
                    <p>Tenha um ótimo dia!</p>
                </div>
                <div className="area-notificacoes">
                    <div className="cabecalho-box">
                        <h3>Notificações</h3>
                        {/* <button className="button-red">
                            <FontAwesomeIcon icon={faTrash} />
                        </button> */}
                    </div>
                    <ul className="lista-notificacoes">
                        {/* <li><FontAwesomeIcon icon={faStar} />Sinistros</li>
                        <li><FontAwesomeIcon icon={faStar} />Sinistro - Flavim do pneu</li>
                        <li><FontAwesomeIcon icon={faStar} />Sinistro - Chaolin matador de porco</li>
                        <li><FontAwesomeIcon icon={faStar} />Sinistro - Zorro</li>
                        <li><FontAwesomeIcon icon={faStar} />Sinistro - Chapolin colorado</li> */}
                    </ul>
                </div>
                <div className="area-agenda">
                    <div className="cabecalho-box">
                        <h3>Agenda</h3>
                        <button className="button-green" onClick={() => {setModalCriarAgenda(true);}}>
                            <FontAwesomeIcon icon={faPlus} />
                        </button>
                    </div>
                    <div className="compromissos-geral">
                        <div className="compromisso-mes">
                            {/* <div className="mes-compromisso-agenda">
                                <h5>Março de 2021</h5>
                            </div> */}
                            {agendas?.filter(({ finalizado }) => finalizado === false).sort(ordemData).map((item, index) => (
                                <div className="box-compromisso-agenda" key={index} onClick={() => {setModalAgenda(true); setAgendaAtiva(item)}}>
                                    <div className="dia-compromisso">
                                        <h4>
                                            <Moment format="DD/MM">
                                                {item.data}
                                            </Moment>
                                        </h4>
                                    </div>
                                    <div className="content-compromisso">
                                        <ul>
                                            <li>{item.assunto}</li>
                                        </ul>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="anotacoes-user">
                    <div className="cabecalho-box">
                        <h3>Anotações</h3>
                        <button className="button-green" onClick={() => {setModalCriarAnotacao(true)}}>
                            <FontAwesomeIcon icon={faPlus} />
                        </button>
                    </div>
                    <ul className="lista-anotacoes">
                        {anotacoes?.slice().reverse().map((item, index) => (
                            <li key={index} onClick={() => {setModalAnotacao(true); setAnotacaoAtiva(item)}}>
                                <h4>{item.titulo}</h4>
                                {/* <p>{item.descricao}</p> */}
                            </li>
                        ))}
                    </ul>
                </div>
            </main>
            :
            <div className='loading'>
                <div className='load-circle'>
                    
                </div>
            </div>
            }
            {modalCriarAgenda ? (
                <Modal titulo="Nova Agenda" onClose={() => {setModalCriarAgenda(false)}}>
                    <form onSubmit={createAgenda}>
                        <div id="campos-nova-agenda">
                            <div className="campo-form campo-55">
                                <label htmlFor="assunto">Assunto</label>
                                <input type="text" name="assunto" id="assunto" required/>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="data">Data</label>
                                <input type="date" name="data" id="data" required/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="horario">Horário</label>
                                <input type="time" name="horario" id="horario" required/>
                            </div>
                            <div className="campo-form campo-100">
                                <label htmlFor="descricao">Descrição</label>
                                <textarea type="text" name="descricao" id="descricao" rows="5" required/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalCriarAgenda(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Cadastrar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalAgenda ? (
                <Modal titulo="Informações da Agenda" width="40%" onClose={() => {setModalAgenda(false)}}>
                    <div className='info-modal'>
                        <div className="campo-info campo-60">
                            <h4>Assunto:</h4>
                            <h5>{agendaAtiva.assunto}</h5>
                        </div>
                        <div className="campo-info campo-40">
                            <h4>Conclusão em:</h4>
                            <h5><Moment format='DD/MM/YYYY [às] HH:mm'>{agendaAtiva.data}</Moment></h5>
                        </div>
                        <div className="campo-info campo-100">
                            <h4>Descrição:</h4>
                            <h5>{agendaAtiva.descricao}</h5>
                        </div>
                    </div>
                    {/* <p className='paragrafo-modal'>{agendaAtiva.descricao}</p> */}
                    <div className='buttons-info'>
                        <button type="button" className="button-red" onClick={() => {setModalAgenda(false);}}>Cancelar</button>
                        <button type="button" className="button-darkred" onClick={() => {setModalAgenda(false); setModalDeleteAgenda(true)}}>Excluir</button>
                        <button type="button" className="button-cyan" onClick={() => {setModalAgenda(false); setModalEditarAgenda(true)}}>Editar</button>
                        <button type="button" className="button-green" onClick={async() => {await UsersService.finalizar_agenda(agendaAtiva._id); getAgendas(); setModalAgenda(false)}}>Concluir</button>
                    </div>
                </Modal>
            ): null}
            {modalEditarAgenda ? (
                <Modal titulo="Editar Agenda" onClose={() => {setModalEditarAgenda(false)}}>
                    <form onSubmit={editAgenda}>
                        <div id="campos-nova-agenda">
                            <div className="campo-form campo-55">
                                <label htmlFor="assunto">Assunto</label>
                                <input type="text" name="assunto" id="assunto" defaultValue={agendaAtiva.assunto}/>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="data">Data</label>
                                <input type="date" name="data" id="data"/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="horario">Horário</label>
                                <input type="time" name="horario" id="horario"/>
                            </div>
                            <div className="campo-form campo-100">
                                <label htmlFor="descricao">Descrição</label>
                                <textarea type="text" name="descricao" id="descricao" rows="5" defaultValue={agendaAtiva.descricao}/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalEditarAgenda(false); setModalAgenda(true)}}>Cancelar</button>
                            <button type="submit" className="button-green">Salvar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalDeleteAgenda ? (
                <ModalDangerSmall mensagem={['Deseja excluir a agenda', <br/>,<span>{agendaAtiva.assunto}</span>,"?"]} onClose={() => {setModalDeleteAgenda(false)}}>
                    <Fragment>
                        <div className='buttons-form'>
                            <button type="button" className="button-red" onClick={() => {setModalDeleteAgenda(false); setModalAgenda(true)}}>Cancelar</button>
                            <button type="submit" className="button-blue" onClick={() => {deleteAgenda(agendaAtiva._id)}}>Excluir</button>
                        </div>
                    </Fragment>
                </ModalDangerSmall>
            ): null}
            {modalCriarAnotacao ? (
                <Modal titulo="Nova Anotação" width="45%" onClose={() => {setModalCriarAnotacao(false)}}>
                    <form onSubmit={createAnotacao}>
                        <div id="campos-nova-anotacao">
                            <div className="campo-form campo-100">
                                <label htmlFor="titulo">Titulo</label>
                                <input type="text" name="titulo" id="titulo" required/>
                            </div>
                            <div className="campo-form campo-100">
                                <label htmlFor="descricao">Descrição</label>
                                <textarea type="text" name="descricao" id="descricao" rows="5" required/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalCriarAnotacao(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Cadastrar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalAnotacao ? (
                <Modal titulo="Anotação" width="35%" onClose={() => {setModalAnotacao(false)}}>
                    <div className="info-modal">
                        <div className="campo-info campo-100">
                            <h4>Título:</h4>
                            <h5>{anotacaoAtiva.titulo}</h5>
                        </div>
                        <div className="campo-info campo-100">
                            <h4>Descrição:</h4>
                            <h5>{anotacaoAtiva.descricao}</h5>
                        </div>
                    </div>
                    <div className='buttons-info'>
                        <button type="reset" className="button-red" onClick={() => {setModalAnotacao(false)}}>Cancelar</button>
                        <button type="button" className="button-darkred" onClick={() => {setModalAnotacao(false); setModalDeleteAnotacao(true)}}>Excluir</button>
                        <button type="button" className="button-cyan" onClick={() => {setModalAnotacao(false); setModalEditarAnotacao(true)}}>Editar</button>
                    </div>
                </Modal>
            ): null}
            {modalEditarAnotacao ? (
                <Modal titulo="Editar Anotação" width="45%" onClose={() => {setModalEditarAnotacao(false)}}>
                    <form onSubmit={editAnotacao}>
                        <div id="campos-nova-anotacao">
                            <div className="campo-form campo-100">
                                <label htmlFor="titulo">Titulo</label>
                                <input type="text" name="titulo" id="titulo" defaultValue={anotacaoAtiva.titulo}/>
                            </div>
                            <div className="campo-form campo-100">
                                <label htmlFor="descricao">Descrição</label>
                                <textarea type="text" name="descricao" id="descricao" rows="5"  defaultValue={anotacaoAtiva.descricao}/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalEditarAnotacao(false); setModalAnotacao(true)}}>Cancelar</button>
                            <button type="submit" className="button-green">Salvar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalDeleteAnotacao ? (
                <ModalDangerSmall mensagem={['Deseja excluir a anotação', <br/>,<span>{anotacaoAtiva.titulo}</span>,"?"]} onClose={() => {setModalDeleteAnotacao(false)}}>
                    <Fragment>
                        <div className='buttons-form'>
                            <button type="button" className="button-red" onClick={() => {setModalDeleteAnotacao(false); setModalAnotacao(true)}}>Cancelar</button>
                            <button type="submit" className="button-blue" onClick={() => {deleteAnotacao(anotacaoAtiva._id)}}>Excluir</button>
                        </div>
                    </Fragment>
                </ModalDangerSmall>
            ): null}
        </Fragment>
    )
};
   
export default ContentHome;