import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import "../../styles/modal-danger-small.scss";

export default function ModalDangerSmall(props) {
    return(
        <Fragment>
            <div className="modal-small">
                <div className="fundo-modal" onClick={props.onClose}>

                </div>
                <div className='content-modal'>
                    <FontAwesomeIcon icon={faTriangleExclamation}/>
                    <h5>{props.mensagem}</h5>
                    {props.children}
                </div>
            </div>
        </Fragment>
    )
};