import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faBriefcase, faChartColumn, faCircleQuestion, faCode, faEllipsis, faEnvelope, faFileLines, faFingerprint, faHandshake, faShieldHalved, faTrashCan, faUser, faUserSecret, faUserTie } from '@fortawesome/free-solid-svg-icons'
import "../../styles/administracao.scss";
import "../../styles/reset.scss";
import UsersService from '../../services/users';
import Loading from '../layouts/loading';

function ContentAdministracao() {
    const [permissao, setPermissao] = useState();
    const [carregado, setCarregado] = useState(false);

    useEffect(() => {
        getPermission();
        setCarregado(true);
    }, []);

    async function getPermission() {
        const response = await UsersService.logado();
        setPermissao(response.data.user.permission);
    }

    document.title = 'Administração - JMX Corretora de Seguros';

    return(
        <Fragment>
            { carregado ?
                <main id='main-administracao'>
                    {permissao <= 4 ? (
                    <Link to="/administracao/usuarios">
                        <div className="list-itens">
                            <FontAwesomeIcon icon={faUser} />
                            <div className="content-item">
                                <h3>Usuários</h3>
                            </div>
                        </div>
                    </Link>) : null}
                    {permissao <= 4 ? (
                    <Link to="/administracao/empresas">
                        <div className="list-itens">
                            <FontAwesomeIcon icon={faBriefcase} />
                            <div className="content-item">
                                <h3>Empresas</h3>
                            </div>
                        </div>
                    </Link>) : null}
                    {permissao <= 4 ? (
                    <Link to="/administracao/apolices">
                        <div className="list-itens">
                            <FontAwesomeIcon icon={faFileLines} />
                            <div className="content-item">
                                <h3>Apólices</h3>
                            </div>
                        </div>
                    </Link>) : null}
                    {permissao <= 4 ? (
                    <Link to="/administracao/seguradoras">
                        <div className="list-itens">
                            <FontAwesomeIcon icon={faShieldHalved} />
                            <div className="content-item">
                                <h3>Seguradoras</h3>
                            </div>
                        </div>
                    </Link>) : null}
                    {permissao <= 4 ? (
                        <Link to="/administracao/visitas">
                            <div className="list-itens">
                                <FontAwesomeIcon icon={faHandshake} />
                                <div className="content-item">
                                    <h3>Visitas</h3>
                                </div>
                            </div>
                        </Link>)
                    : null}
                    {permissao <= 4 ? (
                        <Link to="/administracao/suporte">
                            <div className="list-itens">
                                <FontAwesomeIcon icon={faCircleQuestion} />
                                <div className="content-item">
                                    <h3>Suporte</h3>
                                </div>
                            </div>
                        </Link>) 
                    : null}
                    {permissao === 1 ? (
                    <Link to="/administracao/log">
                        <div className="list-itens">
                            <FontAwesomeIcon icon={faUserSecret} />
                            <div className="content-item">
                                <h3>Logs</h3>
                            </div>
                        </div>
                    </Link>) : null}
                    {permissao === 1 ? (
                    <Link to="/administracao/excluidos">
                        <div className="list-itens">
                            <FontAwesomeIcon icon={faTrashCan} />
                            <div className="content-item">
                                <h3>Excluídos</h3>
                            </div>
                        </div>
                    </Link>) : null}
                    {permissao === 1 ? (
                    <Link to="/administracao/estatisticas">
                        <div className="list-itens">
                            <FontAwesomeIcon icon={faChartColumn} />
                            <div className="content-item">
                                <h3>Estatisticas</h3>
                            </div>
                        </div>
                    </Link>) : null}
                    {permissao === 1 ? (
                    <Link to="/administracao/banco-de-senhas">
                        <div className="list-itens">
                            <FontAwesomeIcon icon={faFingerprint} />
                            <div className="content-item">
                                <h3>Banco de Senhas</h3>
                            </div>
                        </div>
                    </Link>) : null}
                    {permissao === 1 ? (
                    <Link to="/administracao/emails">
                        <div className="list-itens">
                            <FontAwesomeIcon icon={faEnvelope} />
                            <div className="content-item">
                                <h3>E-mails</h3>
                            </div>
                        </div>
                    </Link>) : null}
                    {permissao === 1 ? (
                    <Link to="/administracao/notificacoes">
                        <div className="list-itens">
                            <FontAwesomeIcon icon={faBell} />
                            <div className="content-item">
                                <h3>Notificações</h3>
                            </div>
                        </div>
                    </Link>) : null}
                    {permissao <= 2 ? (
                    <Link to="/administracao/outros">
                        <div className="list-itens">
                            <FontAwesomeIcon icon={faUserTie} />
                            <div className="content-item">
                                <h3>Corretores</h3>
                            </div>
                        </div>
                    </Link>
                    ) : null}
                    {/* {permissao === 1 ? (
                    <Link to="/administracao/testes">
                        <div className="list-itens">
                            <FontAwesomeIcon icon={faCode} />
                            <div className="content-item">
                                <h3>Testes</h3>
                            </div>
                        </div>
                    </Link>) : null} */}
                </main>
            :
                <Loading/>
            }
        </Fragment>
    )
};
   
export default ContentAdministracao;