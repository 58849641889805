import { Fragment } from 'react';
import Header from '../../components/header/index';
import ContentCorretora from '../../components/corretora/index';

const CorretoraScreen = (props) => (
  <Fragment>
    <Header ativo={props.ativo}/>
    <ContentCorretora/>
  </Fragment>
)

export default CorretoraScreen;