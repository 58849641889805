import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faCircle } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';
import 'moment-timezone';
import "../../styles/sinistros.scss";
import "../../styles/reset.scss";
import Api from '../../services/api';
import Modal from "../layouts/modal";
import SinistrosService from '../../services/sinistros';
import EmpresasService from '../../services/empresas';
import { PatternFormat } from 'react-number-format';

export default function ContentCorretora() {
    const navigate = useNavigate();
    const [sinistros, setSinistros] = useState([]);
    const [seguradoras, setSeguradoras] = useState([]);
    const [users, setUsers] = useState([]);
    const [empresas, setEmpresas] = useState();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [ramo, setRamo] = useState();
    const [tipo, setTipo] = useState();
    const [motivo, setMotivo] = useState();
    const [titular, setTitular] = useState("cpf");
    const [tipoSinistro, setTipoSinistro] = useState();
    const [inputDisabled, setInputDisabled] = useState(true);
    const [finds, setFinds] = useState();

    async function getSinistros() {
        const data = await SinistrosService.index();
        setSinistros(data.data);
    };

    async function getEmpresas() {
        const data = await EmpresasService.index();
        setEmpresas(data.data);
    };

    const createSinistro = async event => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData);

        if (data.empresa === 'nao_vincular') {
            delete data.empresa;
        };
        
        try {
            await SinistrosService.criar_sinistro(data);
            getSinistros();
            setModalIsOpen(false);
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (ramo === "Vida") {
            if (tipo === "Sinistro") {
                setMotivo(["Invalidez por Acidente", "Invalidez por Doença", "Morte Conjuge", "Morte Filho", "Morte Titular", "Outros"]);
                setInputDisabled(false)
                setTipoSinistro("vida")
            } else if (tipo === "Assistência"){
                setMotivo(["Alimentação", "Funeral", "Natalidade", "Outros"]);
                setInputDisabled(false)
                setTipoSinistro("vida")
            } else{
                setMotivo(["Selecione um tipo"]);
                setInputDisabled(true)
                setTipoSinistro()
            }
        } else if (ramo === "Auto"){
            if (tipo === "Sinistro") {
                setMotivo(["Colisão", "Furto e Roubo", "Outros"]);
                setInputDisabled(false)
                setTipoSinistro("auto")
            } else if (tipo === "Assistência"){
                setMotivo(["Guincho", "Pane Mecânica/Elétrica", "Veículo Reserva", "Vidros", "Outros"]);
                setInputDisabled(false)
                setTipoSinistro("auto")
            } else{
                setMotivo(["Selecione um tipo"]);
                setInputDisabled(true)
                setTipoSinistro()
            }
        } else if (ramo === "Residêncial"){
            if (tipo === "Sinistro") {
                setMotivo(["Incêndio", "Furto e Roubo", "Outros"]);
                setInputDisabled(false)
                setTipoSinistro("residencial")
            } else if (tipo === "Assistência"){
                setMotivo(["Chaveiro", "Danos Elétricos", "Pagamento de Aluguel", "Vidros", "Outros"]);
                setInputDisabled(false)
                setTipoSinistro("residencial")
            } else{
                setMotivo(["Selecione um tipo"]);
                setInputDisabled(true)
                setTipoSinistro()
            }
        } else {
            if (tipo === "Sinistro" || tipo === "Assistência") {
                setMotivo(["Selecione um ramo"]);
                setInputDisabled(true)
                setTipoSinistro()
            } else{
                setMotivo(["Selecione um ramo e um tipo"]);
                setInputDisabled(true)
                setTipoSinistro()
            }
        }
    }, [tipo, ramo]);

    useEffect(() => {
        Api.get('/seguradoras').then(({ data }) => {
            setSeguradoras(data);
        })

        Api.get('/usuarios').then(({ data }) => {
            setUsers(data.user);
        })

        getEmpresas();
        getSinistros();
    }, []);

    function filtrarBusca(array) {
        if (finds === undefined) {
            return array;
        } else {
            return array.sinistradoNome.toLowerCase().includes(finds.toLowerCase()) || array.titularNome.toLowerCase().includes(finds.toLowerCase()) || array.protocolo.toLowerCase().includes(finds.toLowerCase()) || array.nSinistro.toLowerCase().includes(finds.toLowerCase()) || array.apolice.toLowerCase().includes(finds.toLowerCase());
        }
    }
    
    document.title = 'Sinistros - JMX Corretora de Seguros';

    return(
        <Fragment>
            <main id='main-sinistros'>
                <div className="conteudo-sinistros">
                    <div className="cabecalho-conteudo">
                        <h2>Sinistros</h2>
                        <div className="buttons-conteudo">
                            <button className="button-red" onClick={()=> navigate(`/corretora`)}>Voltar</button>
                            <button className="button-blue" onClick={() => {window.print()}}>Imprimir</button>
                            <button className="button-green add-sinistro" onClick={() => {setModalIsOpen(true)}}>Novo sinistro</button>
                        </div>
                    </div>
                    <div className="filtro-conteudo">
                        <input type="text" name="src-geral-sinistros" id="src-geral-sinistro" placeholder="Faça sua busca aqui" onChange={(evt) => {setFinds(evt.target.value)}}/>
                        <button className="filtro-avancado">Filtro Avançado</button>
                        <button className="filtro-buscar button-green">
                        <FontAwesomeIcon icon={faMagnifyingGlass}/>
                        </button>
                    </div>
                    <div className="tabela-dados">
                        <div className="cabecalho-dados">
                            <div className="column-20">Sinistrado</div>
                            <div className="column-20">Segurado</div>
                            <div className="column-25">Tipo/Motivo</div>
                            <div className="column-10">Data Ocor.</div>
                            <div className="column-25">Status</div>
                        </div>
                        <div className="corpo-dados">
                            { sinistros.filter(filtrarBusca).filter(({ excluido }) => excluido === false).length === 0 ? 
                                <div className="no-info">
                                    Não foi encontrado nenhum sinistro.
                                </div>
                            :
                            (sinistros.filter(filtrarBusca).filter(({ excluido }) => excluido === false).slice(0).reverse().map((item) => (
                                <div key={item._id} className="row-dados" onClick={() => navigate(`/corretora/sinistros/${item._id}`)}>
                                    <div className="column-20">
                                        {(() => {
                                            switch (true) {
                                                case (item.ramo === "Vida"):
                                                    return(item.sinistradoNome)
                                                case (item.ramo === "Auto"):
                                                    return(`${item.sinistradoPlacas} - ${item.sinistradoModelo} / ${item.sinistradoMarca}`)
                                                case (item.ramo === "Residêncial"):
                                                    return(`${item.sinistradoEndereco}, ${item.sinistradoNumero}, ${item.sinistradoCidade} / ${item.sinistradoEstado.toUpperCase()}`)
                                                default:
                                                break;
                                            }
                                        })()}
                                    </div>
                                    <div className="column-20">
                                        {item.titularNome}
                                    </div>
                                    <div className="column-25">
                                        {item.ramo} / {item.tipo} / {item.motivo}
                                    </div>
                                    <div className="column-10">
                                        { item.dataSinistro === null || item.dataSinistro === undefined ?
                                            "--"
                                        :
                                            <Moment format="DD/MM/YYYY">
                                                {item.dataSinistro}
                                            </Moment>
                                        }
                                    </div>
                                    <div className="column-25">
                                        {(() => {
                                            switch (true) {
                                                case (item.status.at(-1).situacao === "Cadastrado"):
                                                        return(<Fragment>
                                                            <FontAwesomeIcon icon={faCircle} className="sit-cadastrado"></FontAwesomeIcon> <span> {item.status.at(-1).situacao} </span>
                                                        </Fragment>)
                                                case (item.status.at(-1).situacao === "Juntando Documentação"):
                                                        return(<Fragment>
                                                            <FontAwesomeIcon icon={faCircle} className="sit-juntdoc"></FontAwesomeIcon> <span> {item.status.at(-1).situacao} </span>
                                                        </Fragment>)
                                                case (item.status.at(-1).situacao === "Finalizado"):
                                                        return(<Fragment>
                                                            <FontAwesomeIcon icon={faCircle} className="sit-finalizado"></FontAwesomeIcon> <span> {item.status.at(-1).situacao} </span>
                                                        </Fragment>)
                                                case (item.status.at(-1).situacao === "Aguardando Análise da Seguradora"):
                                                        return(<Fragment>
                                                            <FontAwesomeIcon icon={faCircle} className="sit-aguardanalise"></FontAwesomeIcon> <span> {item.status.at(-1).situacao} </span>
                                                        </Fragment>)
                                                case (item.status.at(-1).situacao === "Pagamento Recusado"):
                                                        return(<Fragment>
                                                            <FontAwesomeIcon icon={faCircle} className="sit-pagrecu"></FontAwesomeIcon> <span> {item.status.at(-1).situacao} </span>
                                                        </Fragment>)
                                                case (item.status.at(-1).situacao === "Juntando Documentação Complementar"):
                                                        return(<Fragment>
                                                            <FontAwesomeIcon icon={faCircle} className="sit-juntdoccomp"></FontAwesomeIcon> <span> {item.status.at(-1).situacao} </span>
                                                        </Fragment>)
                                                case (item.status.at(-1).situacao === "Aguardando Pagamento"):
                                                        return(<Fragment>
                                                            <FontAwesomeIcon icon={faCircle} className="sit-agupag"></FontAwesomeIcon> <span> {item.status.at(-1).situacao} </span>
                                                        </Fragment>)
                                                default:
                                                break;
                                            }
                                        })()}
                                    </div>
                                </div>
                            )))}
                            { sinistros.filter(({ excluido }) => excluido === false).length === 0 ?
                                null
                            :
                                <h5>Exibindo {sinistros.filter(filtrarBusca).filter(({ excluido }) => excluido === false).length} {sinistros.filter(({ excluido }) => excluido === false).length === 1 ? "resultado" : "resultados"}</h5>
                            }
                        </div>
                    </div>
                </div>
            </main>
            {modalIsOpen ? (
                <Modal titulo="Novo Sinistro" width="55%" onClose={() => {setModalIsOpen(false); setTipoSinistro(); setRamo(); setTipo(); setTitular("cpf")}}>
                    <form onSubmit={createSinistro}>
                        <div id="campos-novo-sinistro">
                            <div className="campo-form campo-30">
                                <label htmlFor="ramo-sinistro">Ramo</label>
                                <select name="ramo" id="ramo-sinistro" required defaultValue="" onChange={(event) => setRamo(event.target.value)}>
                                    <option value="" disabled>Selecione</option>
                                    <option value="Vida">Vida</option>
                                    <option value="Auto">Auto</option>
                                    <option value="Residêncial">Residêncial</option>
                                </select>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="tipo-sinistro">Tipo</label>
                                <select name="tipo" id="tipo-sinistro" required defaultValue="" onChange={(event) => setTipo(event.target.value)}>
                                    <option value="" disabled>Selecione</option>
                                    <option value="Sinistro">Sinistro</option>
                                    <option value="Assistência">Assistência</option>
                                </select>
                            </div>
                            <div className="campo-form campo-40">
                                <label htmlFor="motivo-sinistro">Motivo</label>
                                <select name="motivo" id="motivo-sinistro">
                                    {motivo?.map((item, index) => (
                                        <option key={index} value={item === "Selecione um ramo e um tipo" || item === "Selecione um ramo" || item === "Selecione um tipo" ? "default" : item }>{item}</option>
                                    ))}
                                </select>
                            </div>
                            <h4>Dados do Titular do Seguro</h4>
                            <div className="campo-form campo-25">
                                <div className="radio-box">
                                    <input type="radio" name="doctitular" id="cpf-titular" defaultChecked disabled={inputDisabled} onChange={() => {setTitular('cpf')}}/>
                                    <label htmlFor="cpf-titular">CPF</label>
                                    <input type="radio" name="doctitular" id="cnpj-titular" disabled={inputDisabled} onChange={() => {setTitular('cnpj')}}/>
                                    <label htmlFor="cnpj-titular">CNPJ</label>
                                </div>
                                { titular === "cnpj" ? 
                                    // <input type="text" name="titularDoc" id="doc-titular" className="mask-cnpj" disabled={inputDisabled}/> 
                                    <PatternFormat name='doc-titular' id='doc-titular' format="##.###.###/####-##" required/>
                                : 
                                    // <input type="text" name="titularDoc" id="doc-titular" className="mask-cpf" disabled={inputDisabled}/> 
                                    <PatternFormat name='doc-titular' id='doc-titular' format="###.###.###-##" required/>
                                }
                                
                            </div>
                            <div className={ titular === "cpf" ? "campo-form campo-40" : "campo-form campo-75"}>
                                { titular === "cnpj" ? 
                                    <Fragment>
                                        <label htmlFor="nome-titular">Razão Social</label>
                                    </Fragment>
                                :
                                    <Fragment>
                                        <label htmlFor="nome-titular">Nome do titular</label>
                                    </Fragment>
                                }
                                <input type="text" name="titularNome" id="nome-titular" disabled={inputDisabled}/>
                            </div>
                            {titular === "cpf" ?
                                <div className="campo-form campo-35">
                                    <label htmlFor="empresa">Empresa</label>
                                    <select name="empresa" id="empresa" required defaultValue={''} disabled={inputDisabled}>
                                        <option value='' disabled>Selecione</option>
                                        <option value='nao_vincular'>Não vincular</option>
                                        {empresas.map((item, index) => (
                                            <option key={index} value={item._id}>{item.razao_social}</option>
                                        ))}
                                    </select>
                                </div>
                            :
                                null
                            }
                            { tipoSinistro === "vida" ? 
                                <Fragment>
                                    <h4>Dados do Sinistrado</h4>
                                    <div className="campo-form campo-25">
                                        <label htmlFor="cpf-sinistrado">CPF</label>
                                        <PatternFormat name='cpf-sinistrado' id='cpf-sinistrado' format="###.###.###-##" required/>
                                        {/* <input type="text" name="sinistradoCpf" id="cpf-sinistrado" className='mask-cpf'/> */}
                                    </div>
                                    <div className="campo-form campo-50">
                                        <label htmlFor="nome-sinistrado">Nome do sinistrado</label>
                                        <input type="text" name="sinistradoNome" id="nome-sinistrado"/>
                                    </div>
                                    <div className="campo-form campo-25">
                                        <label htmlFor="nasc-sinistrado">Data de Nasc.</label>
                                        <input type="date" name="sinistradoNasc" id="nasc-sinistrado" min='1910-01-01' max='2020-01-01'/>
                                    </div>
                                </Fragment>
                            : null }
                            { tipoSinistro === "auto" ? 
                                <Fragment>
                                    <h4>Dados do Veículo</h4>
                                    <div className="campo-form campo-15">
                                        <label htmlFor="placas-sinistrado">Placas</label>
                                        <input type="text" name="sinistradoPlacas" id="placas-sinistrado"/>
                                    </div>
                                    <div className="campo-form campo-20">
                                        <label htmlFor="renavam-sinistrado">Renavam</label>
                                        <PatternFormat name='renavam-sinistrado' id='renavam-sinistrado' format="###########" required/>
                                        {/* <input type="number" name="sinistradoRenavam" id="renavam-sinistrado" maxLength ="11"/> */}
                                    </div>
                                    <div className="campo-form campo-20">
                                        <label htmlFor="marca-sinistrado">Marca</label>
                                        <input type="text" name="sinistradoMarca" id="marca-sinistrado"/>
                                    </div>
                                    <div className="campo-form campo-25">
                                        <label htmlFor="modelo-sinistrado">Modelo</label>
                                        <input type="text" name="sinistradoModelo" id="modelo-sinistrado"/>
                                    </div>
                                    <div className="campo-form campo-20">
                                        <label htmlFor="ano-sinistrado">Ano/Fab</label>
                                        {/* <input type="text" name="sinistradoAnoFab" id="ano-sinistrado"/> */}
                                        <PatternFormat name='ano-sinistrado' id='ano-sinistrado' format="####/####" required/>
                                    </div>
                                </Fragment>
                            : null }
                            { tipoSinistro === "residencial" ? 
                                <Fragment>
                                    <h4>Dados da Residência</h4>
                                    <div className="campo-form campo-20">
                                        <label htmlFor="cep-sinistro">CEP</label>
                                        <input type="text" name="sinistradoCep" id="cep-sinistro" className="mask-cep"/>
                                    </div>
                                    <div className="campo-form campo-60">
                                        <label htmlFor="logradouro-sinistro">Logradouro</label>
                                        <input type="text" name="sinistradoEndereco" id="logradouro-sinistro"/>
                                    </div>
                                    <div className="campo-form campo-20">
                                        <label htmlFor="numero-sinistro">Número</label>
                                        <input type="text" name="sinistradoNumero" id="numero-sinistro"/>
                                    </div>
                                    <div className="campo-form campo-25">
                                        <label htmlFor="complemento-sinistro">Complemento</label>
                                        <input type="text" name="sinistradoComplemento" id="complemento-sinistro"/>
                                    </div>
                                    <div className="campo-form campo-25">
                                        <label htmlFor="bairro-sinistro">Bairro</label>
                                        <input type="text" name="sinistradoBairro" id="bairro-sinistro"/>
                                    </div>
                                    <div className="campo-form campo-25">
                                        <label htmlFor="cidade-sinistro">Cidade</label>
                                        <input type="text" name="sinistradoCidade" id="cidade-sinistro"/>
                                    </div>
                                    <div className="campo-form campo-25">
                                        <label htmlFor="estado-sinistro">Estado</label>
                                        <select name="sinistradoEstado" id="estado-sinistro" defaultValue="" required>
                                            <option value="">Selecione</option>
                                            <option value="AC">Acre</option>
                                            <option value="AL">Alagoas</option>
                                            <option value="AM">Amazonas</option>
                                            <option value="AP">Amapá</option>
                                            <option value="BA">Bahia</option>
                                            <option value="CE">Ceará</option>
                                            <option value="DF">Distrito Federal</option>
                                            <option value="ES">Espirito Santo</option>
                                            <option value="GO">Goiás</option>
                                            <option value="MA">Maranhão</option>
                                            <option value="MT">Mato Grosso</option>
                                            <option value="MS">Mato Grosso do Sul</option>
                                            <option value="MG">Minas Gerais</option>
                                            <option value="PA">Pará</option>
                                            <option value="PB">Paraíba</option>
                                            <option value="PR">Paraná</option>
                                            <option value="PE">Pernambuco</option>
                                            <option value="PI">Piauí</option>
                                            <option value="RJ">Rio de Janeiro</option>
                                            <option value="RN">Rio Grande do Norte</option>
                                            <option value="RS">Rio Grande do Sul</option>
                                            <option value="SC">Santa Catarina</option>
                                            <option value="SP">São Paulo</option>
                                            <option value="SE">Sergipe</option>
                                            <option value="RO">Rondônia</option>
                                            <option value="RR">Roraima</option>
                                            <option value="TO">Tocantins</option>
                                        </select>
                                    </div>
                                </Fragment>
                            : null }
                            <h4>Dados do Seguro</h4>
                            <div className="campo-form campo-40">
                                <label htmlFor="seguradora-sinistro">Seguradora</label>
                                <select name="seguradora" id="seguradora-sinistro" defaultValue="" required disabled={inputDisabled}>
                                    <option value="" disabled>Selecione</option>
                                    {seguradoras?.map((item, index) => (
                                        <option key={index} value={item._id}>{item.fantasia}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="campo-form campo-35">
                                <label htmlFor="apolice-sinistro">Apólice</label>
                                <input type="text" name="apolice" id="apolice" disabled={inputDisabled}/>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="data-ocorrencia">Data de Ocor.</label>
                                <input type="date" name="dataSinistro" id="data-ocorrencia" disabled={inputDisabled}/>
                            </div>
                            <div className="campo-form campo-40">
                                <label htmlFor="corretor-sinistro">Corretor</label>
                                <select name="corretor" id="corretor-sinistro" defaultValue="" required disabled={inputDisabled}>
                                    <option value="" disabled>Selecione</option>
                                    {users?.filter(({ corretor }) => corretor === true).map((item, index) => (
                                        <option key={index} value={item._id}>{item.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="protocolo-sinistro">Prot. de Abertura</label>
                                <input type="text" name="protocolo" id="protocolo-sinistro" className="mask-number" disabled={inputDisabled}/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="n-sinistro">N° de sinistro</label>
                                <input type="text" name="nSinistro" id="n-sinistro" className="mask-number" disabled={inputDisabled}/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={(() => {setModalIsOpen(false); setTipoSinistro(); setRamo(); setTipo(); setTitular("cpf");})}>Cancelar</button>
                            <button type="submit" className="button-green">Cadastrar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
        </Fragment>
    )
};