import React, { Fragment, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faAddressCard, faHeartCircleExclamation, faHeart, faCircle, faUserXmark } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';
import 'moment-timezone';
import "../../../styles/empresa.scss";
import "../../../styles/reset.scss";
import UsersService from '../../../services/users';
import Modal from '../../layouts/modal';
import ModalDangerSmall from '../../layouts/modalDangerSmall';
import EmpresasService from '../../../services/empresas';
import Loading from '../../layouts/loading';
import Notification from '../../layouts/notification';
import { NumericFormat } from 'react-number-format';
import UsersEmpresaService from '../../../services/usersEmpresa';

export default function ContentSinistro() {
    const [modalEditarEmpresa, setModalEditarEmpresa] = useState(false);
    const [modalEditarAcesso, setModalEditarAcesso] = useState(false);
    const [acessoAtivo, setAcessoAtivo] = useState();
    const [modalAdicionarAcesso, setModalAdicionarAcesso] = useState(false);
    const [modalResetarAcesso, setModalResetarAcesso] = useState(false);
    const [modalRemoverAcesso, setModalRemoverAcesso] = useState(false);
    const [modalExcluirEmpresa, setModalExcluirEmpresa] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [carregado, setCarregado] = useState(false);
    const [empresa, setEmpresa] = useState([]);
    const [sinistros, setSinistros] = useState([]);
    const [acessos, setAcessos] = useState([]);
    const [user, setUser] = useState();
    const [userAcesso, setUserAcesso] = useState();
    const [abaAtiva, setAbaAtiva] = useState("vidas");
    const navigate = useNavigate();
    const { id } = useParams();

    function createNotification(tipo, titulo, mensagem, tempo) {
        // TIPOS: Sucesso / Erro / Alerta / Info
        let notificacao = []
        notificacao.tipo = tipo;
        notificacao.titulo = titulo;
        notificacao.mensagem = mensagem;
        notificacao.id = notifications.length === 0 ? 1 : notifications.at(-1).id + 1 
        
        setNotifications((prevNotifications) => [...prevNotifications, notificacao]);
        
        setTimeout(() => {
            removeNotification(notificacao.id)
        }, tempo * 1000 || 5000);
    }

    function removeNotification(notificationRemove) {
        setNotifications((prevNotifications) =>
            prevNotifications.filter((notification) => notification.id !== notificationRemove)
        );
    };

    async function getUser() {
        const res = await UsersService.logado();
        setUser(res.data.user);
    };

    async function getEmpresa() {
        const res = await EmpresasService.empresa(id);
        setEmpresa(res.data);
    };

    async function getSinistros() {
        const res = await EmpresasService.sinistros(id);
        setSinistros(res.data);
    };

    async function getAcessosEmpresa() {
        const res = await EmpresasService.acessos(id);
        setAcessos(res.data);
    };

    useEffect(() => {
        (async function() {
            await getEmpresa();
            await getUser();
            await getSinistros();
            await getAcessosEmpresa();
            setCarregado(true);
        })();
    }, []);

    async function getUserAcesso(param) {
        const res = await EmpresasService.search_acesso(param);
        if (res.data !== undefined && res.data !== null) {
            setUserAcesso(res.data)
        } else {
            setUserAcesso("");
        }
    }

    async function adicionarAcesso(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let form = Object.fromEntries(formData);

        try {
            if (userAcesso !== undefined && userAcesso !== null && userAcesso !== "") {
                const userExiste = acessos.find(a => a.usuario && a.usuario._id === userAcesso._id);
                
                if (userExiste) {
                    createNotification("Alerta", "Acesso", "Usuário já adicionado.", 3)
                } else {
                    await EmpresasService.add_acesso(id, userAcesso._id);
                    getAcessosEmpresa();
                    setModalAdicionarAcesso(false);
                    setUserAcesso("");
                }
            } else {
                form.empresas = [{ empresa: id }];
                await EmpresasService.criar_acesso(id, form);
                setModalAdicionarAcesso(false);
                getAcessosEmpresa();
            }
        } catch (error) {
            console.log(error)
        }
    };

    async function editarAcesso(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let form = Object.fromEntries(formData);

        try {
            await UsersEmpresaService.edit(acessoAtivo._id, form);
            await getAcessosEmpresa();
            setModalEditarAcesso(false);
            setUserAcesso("");
        } catch (error) {
            console.log(error)
        }
    };

    async function resetarAcesso() {
        try {
            if (acessoAtivo.password) {
                const res = await UsersEmpresaService.reset_acesso(acessoAtivo._id)
                createNotification("Sucesso", "Sucesso", res.data.message, 5)
                setModalResetarAcesso(false);
            } else {
                createNotification("Erro", "Senha não cadastrada", "Usuário com senha não cadastrada ainda, solicite para que seja criada a primeira senha.", 10)
                setModalResetarAcesso(false);
            }
        } catch (error) {
            console.log(error)
        }
    };

    async function editarEmpresa(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let form = Object.fromEntries(formData);

        if (form.apolice === "" || form.apolice === "default") {
            delete form.apolice;
        }

        const regex = /[\d,]+/g;

        form.premio = form.premio.match(regex).join('').replace(',', '.');
        form.premio = Number(form.premio);

        try {
            await EmpresasService.editar_empresa(id, form);
            window.location.reload();
        } catch (error) {
            console.log(error)
        }
    };

    async function desabilitarEmpresa() {
        await EmpresasService.desabilitar_empresa(id);
        setModalExcluirEmpresa(false);
        window.location.href = '/administracao/empresas'
    };

    async function removerAcesso(userId) {
        await EmpresasService.remover_acesso(id, userId);
        setModalRemoverAcesso(false);
        getAcessosEmpresa();
    };

// AGENDA
    // async function createAgenda(event) {
    //     event.preventDefault();

    //     const formData = new FormData(event.target);
    //     let form = Object.fromEntries(formData);
    //     form.data = new Date(`${form.data} ${form.horario}`);

    //     delete form.horario;

    //     try {
    //         console.log(form);
    //         await SinistrosService.criar_agenda(id, form);
    //         await getAgendas();
    //         setModalCadastrarAgenda(false);
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // async function deleteAgenda() {
    //     await AgendasService.delete(agendaAtiva);
    //     setModalExcluirAgenda(false);
    //     getAgendas();
    // };

// BENEFICIÁRIOS
    // async function createBeneficiario(event) {
    //     event.preventDefault();

    //     const formData = new FormData(event.target);
    //     const data = [];
    //     data.push(Object.fromEntries(formData));
        
    //     try {
    //         await Api.post(`/sinistros/${id}/beneficiarios`, data);
    //         setModalCadastrarBeneficiario(false);
    //         getBeneficiarios();
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // async function editBeneficiario(event) {
    //     const otherId = beneficiarioAtivo._id;
    //     event.preventDefault();
    //     const formData = new FormData(event.target);
    //     const data = Object.fromEntries(formData);
        
    //     try {
    //         await SinistrosService.edit_beneficiario(id, otherId, data);
    //         setModalEditarBeneficiario(false);
    //         getBeneficiarios();
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // async function deleteBeneficiario(otherId) {
    //     try {
    //         await SinistrosService.delete_beneficiario(id, otherId);
    //         setModalExcluirBeneficiario(false);
    //         getBeneficiarios();
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // function getBeneficiarioAtivo(id) {
    //     setBeneficiarioAtivo(beneficiarios?.filter(({ _id }) => _id === id)[0]);
    // }

// SINISTRO
    // async function desativarSinistro(id) {
    //     window.location.href = '/corretora/sinistros';
    //     await SinistrosService.excluir_sinistro(id);
    // };

    // async function editSinistro(event) {
    //     const otherId = beneficiarioAtivo._id;
    //     event.preventDefault();
    //     const formData = new FormData(event.target);
    //     const data = Object.fromEntries(formData);
        
    //     try {
    //         await SinistrosService.edit_beneficiario(id, otherId, data);
    //         setModalEditarBeneficiario(false);
    //         getBeneficiarios();
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

// ORDENAR DATA
    function ordemData(a, b){
        return a.data > b.data ? -1 : a.data < b.data ? 1 : 0;
    }

    document.title = `${empresa.razao_social} - JMX Corretora de Seguros`;

    return(
        <Fragment>
            { carregado ? 
            <main id='main-empresa'>
                <div className="conteudo-empresa">
                    <div className="cabecalho-conteudo">
                        <h2>{empresa.razao_social}</h2>
                        <div className="buttons-conteudo">
                            <button className="button-red" onClick={()=> navigate(`/administracao/empresas`)}>Voltar</button>
                            <button className="button-blue" onClick={() => {window.print()}}>Imprimir</button>
                            <button className="button-cyan" onClick={() => {setModalEditarEmpresa(true)}}>Editar empresa</button>
                            {user.permission <= 2 || user.permission === 6 ? <button className="button-darkred" onClick={() => {setModalExcluirEmpresa(true)}}>Excluir</button> : null }
                        </div>
                    </div>
                    <div className="box-content informacoes-empresa">
                        <div className="campo-info campo-20">
                            <h5>CNPJ</h5>
                            <p>{empresa.cnpj}</p>
                        </div>
                        <div className="campo-info campo-40">
                            <h5>Razão Social</h5>
                            <p>{empresa.razao_social}</p>
                        </div>
                        <div className="campo-info campo-40">
                            <h5>Nome Fantasia</h5>
                            <p>{empresa.fantasia ? empresa.fantasia : empresa.razao_social}</p>
                        </div>
                        <div className="campo-info campo-10">
                            <h5>CNAE</h5>
                            <p>{empresa.cnae}</p>
                        </div>
                        <div className="campo-info campo-90">
                            <h5>Descrição do CNAE</h5>
                            <p>{empresa.descricao_cnae}</p>
                        </div>
                        <div className="campo-info campo-15">
                            <h5>Telefone</h5>
                            <p>{empresa.telefone ? empresa.telefone : "--"}</p>
                        </div>
                        <div className="campo-info campo-20">
                            <h5>Telefone Secundário</h5>
                            <p>{empresa.telefone_secundario ? empresa.telefone_secundario : "--"}</p>
                        </div>
                        <div className="campo-info campo-15">
                            <h5>Celular</h5>
                            <p>{empresa.celular ? empresa.celular : "--"}</p>
                        </div>
                        <div className="campo-info campo-25">
                            <h5>E-mail</h5>
                            <p>{empresa.email ? empresa.email : "--"}</p>
                        </div>
                        <div className="campo-info campo-25">
                            <h5>E-mail Secundário</h5>
                            <p>{empresa.email_secundario ? empresa.email_secundario : "--"}</p>
                        </div>
                        <div className="campo-info campo-40">
                            <h5>Representante</h5>
                            <p>{empresa.representante_legal ? empresa.representante_legal : "--"}</p>
                        </div>
                        <div className="campo-info campo-30">
                            <h5>E-mail do Representante</h5>
                            <p>{empresa.representante_legal_email ? empresa.representante_legal_email : "--"}</p>
                        </div>
                        <div className="campo-info campo-30">
                            <h5>Telefone do Representante</h5>
                            <p>{empresa.representante_legal_telefone ? empresa.representante_legal_telefone : "--"}</p>
                        </div>
                        <div className="campo-info campo-40">
                            <h5>Representante Financeiro</h5>
                            <p>{empresa.representante_financeiro ? empresa.representante_financeiro : "--"}</p>
                        </div>
                        <div className="campo-info campo-30">
                            <h5>E-mail do Representante Financeiro</h5>
                            <p>{empresa.representante_financeiro_email ? empresa.representante_financeiro_email : "--"}</p>
                        </div>
                        <div className="campo-info campo-30">
                            <h5>Telefone do Representante Financeiro</h5>
                            <p>{empresa.representante_financeiro_telefone ? empresa.representante_financeiro_telefone : "--"}</p>
                        </div>
                        <div className="campo-info campo-20">
                            <h5>Empresa Gerenciada</h5>
                            <p>{empresa.empresa_gerenciada ? "Sim" : "Não"}</p>
                        </div>
                        <div className="campo-info campo-15">
                            <h5>CEP</h5>
                            <p>{empresa.cep}</p>
                        </div>
                        <div className="campo-info campo-65">
                            <h5>Endereço</h5>
                            <p>{empresa.logradouro}, {empresa.numero} - {empresa.complemento} - {empresa.bairro}, {empresa.cidade}/{empresa.estado}</p>
                        </div>
                    </div>
                    <div className="content-abas">
                        <ul className='seletor-abas'>
                            <li onClick={() => setAbaAtiva("vidas")} className={abaAtiva === "vidas" ? 'aba-active' : null}>
                                <FontAwesomeIcon icon={faHeart}/>
                                <h2>Vidas</h2>
                            </li>
                            <li onClick={() => setAbaAtiva("sinistros")} className={abaAtiva === "sinistros" ? 'aba-active' : null}>
                                <FontAwesomeIcon icon={faHeartCircleExclamation}/>
                                <h2>Sinistros</h2>
                            </li>
                            <li onClick={() => setAbaAtiva("acessos")} className={abaAtiva === "acessos" ? 'aba-active' : null}>
                                <FontAwesomeIcon icon={faAddressCard}/>
                                <h2>Acessos</h2>
                            </li>
                        </ul>
                        <div className='conteudo-aba'>
                            {(() => {
                                switch (true) {
                                    case (abaAtiva === "vidas"):
                                        return(
                                            <div className="conteudo-vidas">
                                                {/* <div className="opcoes-aba">
                                                    <button className="button-green" onClick={() => {setModalCadastrarHistorico(true);}}>Adicionar Histórico</button>
                                                </div> */}
                                                { !empresa.empresa_gerenciada ? 
                                                    <p className='no-info'>Empresa não gerenciada pelo sistema da JMX.</p>
                                                :
                                                    !empresa.vidas ?
                                                        <p className='no-info'>Não foi cadastrado nenhuma vida nessa empresa.</p>
                                                    :
                                                    empresa?.acessos.sort(ordemData).map((item, index) => (
                                                        <div></div>
                                                    ))
                                                }
                                            </div>
                                        )
                                    case (abaAtiva === "sinistros"):
                                        return(
                                            <div className="conteudo-sinistros">
                                                {/* <div className="opcoes-aba">
                                                    <button className="button-green" onClick={() => {setModalAdicionarSinistro(true)}}>Adicionar Sinistro</button>
                                                </div> */}
                                                { !sinistros || sinistros.length === 0 ?
                                                    <p className='no-info'>Não foi cadastrado nenhum sinistro para essa empresa.</p>
                                                :
                                                    <div className="tabela-dados">
                                                        <div className="cabecalho-dados">
                                                            <div className="column-20">Sinistrado</div>
                                                            <div className="column-20">Segurado</div>
                                                            <div className="column-25">Tipo/Motivo</div>
                                                            <div className="column-10">Data Ocor.</div>
                                                            <div className="column-25">Status</div>
                                                        </div>
                                                        <div className="corpo-dados">
                                                            {sinistros.map((item, index) => (
                                                                <div className="row-dados" key={index} onClick={() => navigate(`/corretora/sinistros/${item._id}`)}>
                                                                    <div className="column-20">
                                                                        {item.sinistradoNome}
                                                                    </div>
                                                                    <div className="column-20">
                                                                        {item.titularNome}
                                                                    </div>
                                                                    <div className="column-25">
                                                                        {item.ramo} / {item.tipo} / {item.motivo}
                                                                    </div>
                                                                    <div className="column-10">
                                                                        <Moment format='DD/MM/YYYY'>{item.dataSinistro}</Moment>
                                                                    </div>
                                                                    <div className="column-25">
                                                                        {(() => {
                                                                            switch (true) {
                                                                                case (item.status.at(-1).situacao === "Cadastrado"):
                                                                                        return(<Fragment>
                                                                                            <FontAwesomeIcon icon={faCircle} className="sit-cadastrado"></FontAwesomeIcon> <span> {item.status.at(-1).situacao} </span>
                                                                                        </Fragment>)
                                                                                case (item.status.at(-1).situacao === "Juntando Documentação"):
                                                                                        return(<Fragment>
                                                                                            <FontAwesomeIcon icon={faCircle} className="sit-juntdoc"></FontAwesomeIcon> <span> {item.status.at(-1).situacao} </span>
                                                                                        </Fragment>)
                                                                                case (item.status.at(-1).situacao === "Finalizado"):
                                                                                        return(<Fragment>
                                                                                            <FontAwesomeIcon icon={faCircle} className="sit-finalizado"></FontAwesomeIcon> <span> {item.status.at(-1).situacao} </span>
                                                                                        </Fragment>)
                                                                                case (item.status.at(-1).situacao === "Aguardando Análise da Seguradora"):
                                                                                        return(<Fragment>
                                                                                            <FontAwesomeIcon icon={faCircle} className="sit-aguardanalise"></FontAwesomeIcon> <span> {item.status.at(-1).situacao} </span>
                                                                                        </Fragment>)
                                                                                case (item.status.at(-1).situacao === "Pagamento Recusado"):
                                                                                        return(<Fragment>
                                                                                            <FontAwesomeIcon icon={faCircle} className="sit-pagrecu"></FontAwesomeIcon> <span> {item.status.at(-1).situacao} </span>
                                                                                        </Fragment>)
                                                                                case (item.status.at(-1).situacao === "Juntando Documentação Complementar"):
                                                                                        return(<Fragment>
                                                                                            <FontAwesomeIcon icon={faCircle} className="sit-juntdoccomp"></FontAwesomeIcon> <span> {item.status.at(-1).situacao} </span>
                                                                                        </Fragment>)
                                                                                case (item.status.at(-1).situacao === "Aguardando Pagamento"):
                                                                                        return(<Fragment>
                                                                                            <FontAwesomeIcon icon={faCircle} className="sit-agupag"></FontAwesomeIcon> <span> {item.status.at(-1).situacao} </span>
                                                                                        </Fragment>)
                                                                                default:
                                                                                break;
                                                                            }
                                                                        })()}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    case (abaAtiva === "acessos"):
                                        return(
                                            <div className="conteudo-acessos">
                                                <div className="opcoes-aba">
                                                    <button className="button-green" onClick={() => {setModalAdicionarAcesso(true)}}>Adicionar Usuário</button>
                                                </div>
                                                { !acessos || acessos.length === 0 ?
                                                    <p className='no-info'>Não foi cadastrado nenhum sinistro para essa empresa.</p>
                                                :
                                                    <div className="tabela-dados">
                                                        <div className="cabecalho-dados">
                                                            <div className="column-30">Nome</div>
                                                            <div className="column-30">E-mail</div>
                                                            <div className="column-15">Cadastro</div>
                                                            <div className="column-10">Situação</div>
                                                            <div className="column-10">Data Cadast.</div>
                                                        </div>
                                                        <div className="corpo-dados">
                                                            {acessos.map((item, index) => (
                                                                <div className="row-dados" key={index}>
                                                                    <div className="column-30">
                                                                        {item.usuario.name}
                                                                    </div>
                                                                    <div className="column-30">
                                                                        {item.usuario.email}
                                                                    </div>
                                                                    <div className="column-15">
                                                                        {item.usuario.primeiro_login ? "Não finalizado" : "Finalizado"}
                                                                    </div>
                                                                    <div className="column-10">
                                                                        {item.usuario.excluido ? "Excluído" : "Ativo"}
                                                                    </div>
                                                                    <div className="column-10">
                                                                        <Moment format='DD/MM/YYYY'>{item.usuario.created_at}</Moment>
                                                                    </div>
                                                                    <div className="column-icon">
                                                                        <FontAwesomeIcon icon={faPen} onClick={() => {setAcessoAtivo(item.usuario); setModalEditarAcesso(true)}}/>
                                                                    </div>
                                                                    <div className="column-icon">
                                                                        <FontAwesomeIcon icon={faUserXmark} onClick={() => {setAcessoAtivo(item.usuario); setModalRemoverAcesso(true)}}/>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        )
                                        // return(
                                        //     <div className="conteudo-agenda">
                                        //         <div className="opcoes-aba">
                                        //             <button className="button-green" onClick={() => {setModalCadastrarAgenda(true)}}>Criar Agenda</button>
                                        //         </div>
                                        //         <div className="tabela-dados">
                                        //             <div className="cabecalho-dados">
                                        //                 <div className="column-40">Usuário</div>
                                        //                 <div className="column-40">Assunto</div>
                                        //                 <div className="column-15">Data/Hora</div>
                                        //             </div>
                                        //             <div className="corpo-dados">
                                        //                 { agendas.length === 0 ? 
                                        //                     <div className="row-dados">
                                        //                         <div className="column-zerado">
                                        //                             Não foi cadastrada nenhuma agenda ainda.
                                        //                         </div>
                                        //                     </div>
                                        //                     : (
                                        //                         (agendas.sort(ordemData).map((item, index) => (
                                        //                             <div key={index} className="row-dados">
                                        //                                 <div className="column-40">
                                        //                                     {item.autor.name}
                                        //                                 </div>
                                        //                                 <div className="column-40">
                                        //                                     {item.assunto}
                                        //                                 </div>
                                        //                                 <div className="column-15">
                                        //                                     <Moment format="DD/MM/YYYY HH:mm">
                                        //                                         {item.data}
                                        //                                     </Moment>
                                        //                                 </div>
                                        //                                 <div className="column-icon">
                                        //                                     { item.autor._id === user._id ?
                                        //                                         <FontAwesomeIcon icon={faPen} onClick={() => console.log("editar")}/>
                                        //                                     : null }
                                        //                                 </div>
                                        //                                 { item.autor._id === user._id || user.permission === 1 ? 
                                        //                                     <div className="column-icon">
                                        //                                         <FontAwesomeIcon icon={faTrashCan} onClick={() => {setModalExcluirAgenda(true); setAgendaAtiva(item._id)}}/>
                                        //                                     </div>
                                        //                                 : null }
                                        //                             </div>
                                        //                         )))
                                        //                     )
                                        //                 }
                                        //             </div>
                                        //         </div>
                                        //     </div>
                                        // )
                                    case (abaAtiva === "beneficiarios"):
                                        // return(
                                        //     <div className='conteudo-beneficiarios'>
                                        //         <div className="opcoes-aba">
                                        //             <button className="button-green" onClick={() => {setModalCadastrarBeneficiario(true)}}>Adicionar Beneficiário</button>
                                        //         </div>
                                        //         <div className="tabela-dados">
                                        //             <div className="cabecalho-dados">
                                        //                 <div className="column-50">Nome</div>
                                        //                 <div className="column-30">Parentesco</div>
                                        //                 <div className="column-15">Valor</div>
                                        //             </div>
                                        //             <div className="corpo-dados">
                                        //                 { beneficiarios.length === 0 ? 
                                        //                     <div className="row-dados">
                                        //                         <div className="column-zerado">
                                        //                             Não foi cadastrado nenhum beneficiário ainda.
                                        //                         </div>
                                        //                     </div>
                                        //                     : (
                                        //                         (beneficiarios.map((item, index) => (
                                        //                             <Fragment key={index}>
                                        //                                 <div className="row-dados">
                                        //                                     <div className="column-50">
                                        //                                         {item.nome}
                                        //                                     </div>
                                        //                                     <div className="column-30">
                                        //                                         {item.parentesco}
                                        //                                     </div>
                                        //                                     <div className="column-15">
                                        //                                         R$ {item.valor}
                                        //                                     </div>
                                        //                                     <div className="column-icon">
                                        //                                         <FontAwesomeIcon icon={faTrashCan} onClick={() => {setModalExcluirBeneficiario(true); getBeneficiarioAtivo(item._id)}}/>
                                        //                                     </div>
                                        //                                     <div className="column-icon">
                                        //                                         <FontAwesomeIcon icon={faUserPen} onClick={() => {setModalEditarBeneficiario(true); getBeneficiarioAtivo(item._id)}}/>
                                        //                                     </div>
                                        //                                 </div>
                                        //                             </Fragment>
                                        //                         )))
                                        //                     )
                                        //                 }
                                        //             </div>
                                        //         </div>
                                        //     </div>
                                        // )
                                    default:
                                    break;
                                }
                            })()}
                        </div>
                    </div>
                </div>
            </main> 
            :
                <Loading/>
            }
            {modalAdicionarAcesso ? (
                <Modal titulo="Adicionar usuário" onClose={() => {setModalAdicionarAcesso(false); setUserAcesso("")}}>
                    <form onSubmit={adicionarAcesso}>
                        <div id="campos-form">
                            <div className="campo-form campo-50">
                                <label htmlFor="email">E-mail</label>
                                <input type="email" name="email" id="email" onBlur={(event) =>  (event.target.value.length !== 0 ? getUserAcesso(event.target.value) : setUserAcesso(""))} required/>
                            </div>
                            <div className="campo-form campo-50">
                                <label htmlFor="name">Nome</label>
                                <input type="text" name="name" id="name" defaultValue={userAcesso !== undefined && userAcesso !== "" && userAcesso !== null ? userAcesso.name : ""} disabled={userAcesso !== undefined && userAcesso !== "" && userAcesso !== null ? true : false} required/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalAdicionarAcesso(false); setUserAcesso("")}}>Cancelar</button>
                            <button type="submit" className="button-green">Adicionar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalEditarAcesso ? (
                <Modal titulo="Alterar Acesso" onClose={() => {setModalEditarAcesso(false)}}>
                    <form onSubmit={editarAcesso}>
                        <div id="campos-form">
                            <div className="campo-form campo-50">
                                <label htmlFor="name">Nome</label>
                                <input type="text" name="name" id="name" defaultValue={acessoAtivo.name} required/>
                            </div>
                            <div className="campo-form campo-50">
                                <label htmlFor="email">E-mail</label>
                                <input type="email" name="email" id="email" defaultValue={acessoAtivo.email} required/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalEditarAcesso(false)}}>Cancelar</button>
                            <button type="button" className="button-blue" onClick={() => {setModalResetarAcesso(true); setModalEditarAcesso(false)}}>Resetar Senha</button>
                            <button type="submit" className="button-green">Salvar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalResetarAcesso ? (
                <ModalDangerSmall mensagem={[`Deseja realmente resetar a senha do usuário ${acessoAtivo.name} para `, <span>123@mudar</span>,"?"]} onClose={() => {setModalResetarAcesso(false)}}>
                    <div className='buttons-form'>
                        <button type="reset" className="button-red" onClick={() => {setModalResetarAcesso(false); setModalEditarAcesso(true)}}>Cancelar</button>
                        <button type="submit" className="button-blue" onClick={() => {resetarAcesso()}}>Resetar</button>
                    </div>
                </ModalDangerSmall>
            ): null}
            {modalRemoverAcesso ? (
                <ModalDangerSmall mensagem={[`Deseja realmente remover o acesso de `, <br/>, <span>{acessoAtivo.name}</span>," dessa empresa?"]} onClose={() => {setModalRemoverAcesso(false)}}>
                    <div className='buttons-form'>
                        <button type="reset" className="button-red" onClick={() => {setModalRemoverAcesso(false)}}>Cancelar</button>
                        <button type="submit" className="button-blue" onClick={() => {removerAcesso(acessoAtivo._id)}}>Remover</button>
                    </div>
                </ModalDangerSmall>
            ): null}
            {modalEditarEmpresa ? (
                <Modal titulo="Editar Empresa" width="70%" onClose={() => {setModalEditarEmpresa(false);}}>
                    <form onSubmit={editarEmpresa}>
                        <div id="campos-nova-seguradora">
                            <div className="campo-form campo-35">
                                <label htmlFor="apolice-empresa">Apólice</label>
                                <select name='apolice' id='apolice-empresa'>
                                    <option value="default">Selecione</option>
                                </select>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="primeiro_faturamento">1° Faturamento</label>
                                <input type="text" name="primeiro_faturamento" id="primeiro_faturamento" defaultValue={empresa.primeiro_faturamento}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="primeiro_vencimento">1° Vencimento</label>
                                <input type="text" name="primeiro_vencimento" id="primeiro_vencimento" defaultValue={empresa.primeiro_vencimento}/>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="premio">Prêmio Individual</label>
                                <NumericFormat id='premio' name='premio' decimalScale={2} decimalSeparator="," thousandSeparator="." prefix='R$' fixedDecimalScale/>
                                {/* <input type="text" name="premio" id="premio" defaultValue={empresa.premio}/> */}
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="cnpj">CNPJ</label>
                                <input type="text" name="cnpj" id="cnpj" defaultValue={empresa.cnpj}/>
                            </div>
                            <div className="campo-form campo-40">
                                <label htmlFor="razao_social">Razão Social</label>
                                <input type="text" name="razao_social" id="razao_social" defaultValue={empresa.razao_social}/>
                            </div>
                            <div className="campo-form campo-35">
                                <label htmlFor="fantasia">Fantasia</label>
                                <input type="text" name="fantasia" id="fantasia" defaultValue={empresa.fantasia}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="telefone">Telefone</label>
                                <input type="text" name="telefone" id="telefone" defaultValue={empresa.telefone}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="telefone_secundario">Telefone <span>Secundário</span></label>
                                <input type="text" name="telefone_secundario" id="telefone_secundario" defaultValue={empresa.telefone_secundario}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="celular">Celular</label>
                                <input type="text" name="celular" id="celular" defaultValue={empresa.celular}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="email">Email</label>
                                <input type="text" name="email" id="email" defaultValue={empresa.email}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="email_secundario">Email <span>Secundário</span></label>
                                <input type="text" name="email_secundario" id="email_secundario" defaultValue={empresa.email_secundario}/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="cnae">CNAE</label>
                                <input type="text" name="cnae" id="cnae" defaultValue={empresa.cnae}/>
                            </div>
                            <div className="campo-form campo-70">
                                <label htmlFor="descricao_cnae">Descrição CNAE</label>
                                <input type="text" name="descricao_cnae" id="descricao_cnae" defaultValue={empresa.descricao_cnae}/>
                            </div>
                            <div className="campo-form campo-60">
                                <label htmlFor="representante_legal">Responsável Legal</label>
                                <input type="text" name="representante_legal" id="representante_legal" defaultValue={empresa.representante_legal} required/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="representante_legal_telefone">Telefone</label>
                                <input type="text" name="representante_legal_telefone" id="representante_legal_telefone" defaultValue={empresa.representante_legal_telefone} required/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="representante_legal_email">Email</label>
                                <input type="text" name="representante_legal_email" id="representante_legal_email" defaultValue={empresa.representante_legal_email} required/>
                            </div>
                            <div className="campo-form campo-60">
                                <label htmlFor="representante_financeiro">Responsável Financeiro</label>
                                <input type="text" name="representante_financeiro" id="representante_financeiro" defaultValue={empresa.representante_financeiro} required/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="representante_financeiro_telefone">Telefone</label>
                                <input type="text" name="representante_financeiro_telefone" id="representante_financeiro_telefone" defaultValue={empresa.representante_financeiro_telefone} required/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="representante_financeiro_email">Email</label>
                                <input type="text" name="representante_financeiro_email" id="representante_financeiro_email" defaultValue={empresa.representante_financeiro_email} required/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="cep">CEP</label>
                                <input type="text" name="cep" id="cep" defaultValue={empresa.cep}/>
                            </div>
                            <div className="campo-form campo-60">
                                <label htmlFor="logradouro">Logradouro</label>
                                <input type="text" name="logradouro" id="logradouro" defaultValue={empresa.logradouro}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="numero">Número</label>
                                <input type="text" name="numero" id="numero" defaultValue={empresa.numero}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="complemento">Complemento</label>
                                <input type="text" name="complemento" id="complemento" defaultValue={empresa.complemento}/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="bairro">Bairro</label>
                                <input type="text" name="bairro" id="bairro" defaultValue={empresa.bairro}/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="cidade">Cidade</label>
                                <input type="text" name="cidade" id="cidade" defaultValue={empresa.cidade}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="estado">Estado</label>
                                <select name="estado" id="estado" defaultValue={empresa.estado}>
                                    <option value="">Selecione</option>
                                    <option value="AC">Acre</option>
                                    <option value="AL">Alagoas</option>
                                    <option value="AM">Amazonas</option>
                                    <option value="AP">Amapá</option>
                                    <option value="BA">Bahia</option>
                                    <option value="CE">Ceará</option>
                                    <option value="DF">Distrito Federal</option>
                                    <option value="ES">Espirito Santo</option>
                                    <option value="GO">Goiás</option>
                                    <option value="MA">Maranhão</option>
                                    <option value="MT">Mato Grosso</option>
                                    <option value="MS">Mato Grosso do Sul</option>
                                    <option value="MG">Minas Gerais</option>
                                    <option value="PA">Pará</option>
                                    <option value="PB">Paraíba</option>
                                    <option value="PR">Paraná</option>
                                    <option value="PE">Pernambuco</option>
                                    <option value="PI">Piauí</option>
                                    <option value="RJ">Rio de Janeiro</option>
                                    <option value="RN">Rio Grande do Norte</option>
                                    <option value="RS">Rio Grande do Sul</option>
                                    <option value="SC">Santa Catarina</option>
                                    <option value="SP">São Paulo</option>
                                    <option value="SE">Sergipe</option>
                                    <option value="RO">Rondônia</option>
                                    <option value="RR">Roraima</option>
                                    <option value="TO">Tocantins</option>
                                </select>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalEditarEmpresa(false);}}>Cancelar</button>
                            <button type="submit" className="button-green">Salvar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalExcluirEmpresa ? (
                <ModalDangerSmall mensagem="Deseja realmente excluir essa empresa?" onClose={() => {setModalExcluirEmpresa(false)}}>
                    <div className='buttons-form'>
                        <button type="reset" className="button-red" onClick={() => {setModalExcluirEmpresa(false)}}>Cancelar</button>
                        <button type="submit" className="button-blue" onClick={() => {desabilitarEmpresa(id)}}>Excluir</button>
                    </div>
                </ModalDangerSmall>
            ): null} {/*
            {modalCadastrarHistorico ? (
                <Modal titulo="Novo Histórico" onClose={() => {setModalCadastrarHistorico(false)}}>
                    <form onSubmit={createHistorico}>
                        <div id="campos-nova-agenda">
                            <div className="campo-form campo-55">
                                <label htmlFor="assunto">Assunto</label>
                                <input type="text" name="assunto" id="assunto" required/>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="data">Data</label>
                                <input type="date" name="data" id="data" required/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="horario">Horário</label>
                                <input type="time" name="horario" id="horario" required/>
                            </div>
                            <div className={ naoSucesso ? "campo-form campo-40" : "campo-form campo-100"}>
                                <label htmlFor="sucesso">Obteve sucesso?</label>
                                <select name='sucesso' onChange={(e) => { e.target.value === "false" ? setNaoSucesso(true) : setNaoSucesso(false)}}>
                                    <option value="default">Selecione</option>
                                    <option value={true}>Sim</option>
                                    <option value={false}>Não</option>
                                </select>
                            </div>
                            { naoSucesso ? (<div className="campo-form campo-60">
                                <label htmlFor="motivo_nao_sucesso">Motivo</label>
                                <input type="text" name="motivo_nao_sucesso" id="motivo-nao-sucesso" required/>
                            </div>) : null}
                            <div className="campo-form campo-100">
                                <label htmlFor="descricao">Descrição</label>
                                <textarea type="text" name="descricao" id="descricao" rows="5" required/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalCadastrarHistorico(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Cadastrar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalExcluirHistorico ? (
                <ModalDangerSmall mensagem={"Deseja excluir esse histórico definitivamente?"} onClose={() => {setModalExcluirHistorico(false)}}>
                    <div className='buttons-form'>
                        <button type="reset" className="button-red" onClick={() => {setModalExcluirHistorico(false)}}>Cancelar</button>
                        <button type="submit" className="button-blue" onClick={() => {deleteHistorico()}}>Excluir</button>
                    </div>
                </ModalDangerSmall>
            ): null}
            {modalCadastrarAgenda ? (
                <Modal titulo="Nova Agenda" onClose={() => {setModalCadastrarAgenda(false)}}>
                    <form onSubmit={createAgenda}>
                        <div id="campos-nova-agenda">
                            <div className="campo-form campo-55">
                                <label htmlFor="assunto">Assunto</label>
                                <input type="text" name="assunto" id="assunto" required/>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="data">Data</label>
                                <input type="date" name="data" id="data" required/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="horario">Horário</label>
                                <input type="time" name="horario" id="horario" required/>
                            </div>
                            <div className="campo-form campo-100">
                                <label htmlFor="descricao">Descrição</label>
                                <textarea type="text" name="descricao" id="descricao" rows="5" required=""/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalCadastrarAgenda(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Cadastrar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalExcluirAgenda ? (
                <ModalDangerSmall mensagem={"Deseja excluir essa agenda definitivamente?"} onClose={() => {setModalExcluirAgenda(false)}}>
                    <div className='buttons-form'>
                        <button type="reset" className="button-red" onClick={() => {setModalExcluirAgenda(false)}}>Cancelar</button>
                        <button type="submit" className="button-blue" onClick={() => {deleteAgenda()}}>Excluir</button>
                    </div>
                </ModalDangerSmall>
            ): null}
            {modalCadastrarBeneficiario ? (
                <Modal titulo="Novo Beneficiário" onClose={() => {setModalCadastrarBeneficiario(false)}}>
                    <form onSubmit={createBeneficiario}>
                        <div id="campos-novo-beneficiario">
                            <div className="campo-form campo-50">
                                <label htmlFor="nome">Nome</label>
                                <input type="text" name="nome" id="nome" required/>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="parentesco">Parentesco</label>
                                <select name="parentesco" id="parentesco" defaultValue={"default"}>
                                    <option value="default" disabled>Selecione</option>
                                    <option value="Cônjuge">Cônjuge</option>
                                    <option value="Filho(a)">Filho(a)</option>
                                    <option value="Irmão(ã)">Irmão(ã)</option>
                                    <option value="Mãe">Mãe</option>
                                    <option value="Pai">Pai</option>
                                    <option value="Outros">Outros</option>
                                </select>
                                {/* <input type="text" name="titulo" id="titulo" required/> */}{/* 
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="valor">Valor a receber</label>
                                <input type="text" name="valor" id="valor" required/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalCadastrarBeneficiario(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Cadastrar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalEditarBeneficiario ? (
                <Modal titulo="Editar Beneficiário" onClose={() => {setModalEditarBeneficiario(false)}}>
                    <form onSubmit={editBeneficiario}>
                        <div id="campos-novo-beneficiario">
                            <div className="campo-form campo-50">
                                <label htmlFor="nome">Nome</label>
                                <input type="text" name="nome" id="nome" defaultValue={beneficiarioAtivo.nome} required/>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="parentesco">Parentesco</label>
                                <select name="parentesco" id="parentesco" defaultValue={beneficiarioAtivo.parentesco}>
                                    <option value="Cônjuge">Cônjuge</option>
                                    <option value="Filho(a)">Filho(a)</option>
                                    <option value="Irmão(ã)">Irmão(ã)</option>
                                    <option value="Mãe">Mãe</option>
                                    <option value="Pai">Pai</option>
                                    <option value="Outros">Outros</option>
                                </select>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="valor">Valor a receber</label>
                                <input type="text" name="valor" id="valor" defaultValue={beneficiarioAtivo.valor} required/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalEditarBeneficiario(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Salvar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalExcluirBeneficiario ? (
                <ModalDangerSmall mensagem={["Deseja excluir o beneficiário", <br/>, <span>{beneficiarioAtivo.nome.toUpperCase()}</span>,"?"]} onClose={() => {setModalExcluirBeneficiario(false)}}>
                    <div className='buttons-form'>
                        <button type="reset" className="button-red" onClick={() => {setModalExcluirBeneficiario(false)}}>Cancelar</button>
                        <button type="submit" className="button-blue" onClick={() => {deleteBeneficiario(beneficiarioAtivo._id)}}>Excluir</button>
                    </div>
                </ModalDangerSmall>
            ): null} */}
            { notifications.length !== 0 ?
                <div className='area-notification'>
                    {notifications.map((item, index) =>(
                        <Notification tipo={item.tipo} titulo={item.titulo} mensagem={item.mensagem} onClose={() => removeNotification(item.id)} key={index}/>
                    ))}
                </div>
            : null }
        </Fragment>
    )
};