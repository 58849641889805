import React, { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "../../../styles/testes.scss";
import "../../../styles/reset.scss";
import TestesService from '../../../services/testes';

export default function ContentAdministracaoSeguradoras() {
    const navigate = useNavigate();

    // function getSeguradoras() {
    //     Api.get('/seguradoras').then(({ data }) => {
    //         setSeguradoras(data?.filter(({ excluido }) => excluido === false));
    //     });
    // };

    useEffect(() => {
        // getSeguradoras();
    }, []);

    const createDoc = async event => {
        event.preventDefault();

        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData);
        
        try {
            // console.log(data);
            await TestesService.create(data);
            // getSeguradoras();
            // setModalNovaSeguradora(false);
        } catch (error) {
            console.log(error)
        }
    };

    // const editSeguradora = async event => {
    //     event.preventDefault();

    //     const formData = new FormData(event.target);
    //     const data = Object.fromEntries(formData);
        
    //     try {
    //         await SeguradorasService.edit(seguradoraAtiva._id, data);
    //         getSeguradoras();
    //         setModalEditarSeguradora(false);
    //     } catch (error) {
    //         console.log(error)
    //     }
    // };

    // async function desativarSeguradora() {
    //     await SeguradorasService.desativar(seguradoraAtiva._id);
    //     getSeguradoras();
    //     setModalDeleteSeguradora(false);
    // };

    // function getSeguradoraAtiva(id) {
    //     setSeguradoraAtiva(seguradoras.filter(({ _id }) => _id === id)[0])
    // };

    document.title = `Testes - JMX Corretora de Seguros`;

    return(
        <Fragment>
            <main id='main-testes'>
                <div className="conteudo-testes">
                    <div className="cabecalho-conteudo">
                        <h2>Testes</h2>
                        <div className="buttons-conteudo">
                            <button className="button-red" onClick={()=> navigate(`/administracao`)}>Voltar</button>
                            <button className="button-blue" onClick={() => {window.print()}}>Imprimir</button>
                        </div>
                    </div>
                    <div className='tabela-dados'>
                        <form onSubmit={createDoc}>
                            <div className="campos-form-visita">
                                <div className="campo-form campo-100">
                                    <label htmlFor="empresa">Texto:</label>
                                    <input type="text" name="empresa" id="empresa"/>
                                </div>
                                <div className="campo-form campo-100">
                                    <label htmlFor="doc">Arquivo:</label>
                                    <input type="file" name="doc" id="doc"/>
                                </div>
                            </div>
                                <div className="buttons-form">
                                    <button type="reset" className="button-red">Cancelar</button>
                                    <button type="submit" className="button-green">Cadastrar</button>
                                </div>
                        </form>
                    </div>
                    {/* <div className="tabela-dados">
                        <div className="cabecalho-dados">
                            <div className="column-45">Razão Social</div>
                            <div className="column-15">CNPJ</div>
                            <div className="column-15">Telefone</div>
                            <div className="column-25">E-mail</div>
                        </div>
                        <div className="corpo-dados corpo-seguradoras">
                            { seguradoras.length === 0 ? 
                                <div className="row-dados">
                                    <div className="column-zerado">
                                        Não foi encontrado nenhum resultado.
                                    </div>
                                </div>
                                : (
                                    (seguradoras.slice(0).reverse().map((item, index) => (
                                        <Fragment>
                                            <div key={index} className="row-dados" onClick={() => {setModalSeguradora(true); getSeguradoraAtiva(item._id)}}>
                                                <div className="column-45">
                                                    {item.razao}
                                                </div>
                                                <div className="column-15">
                                                    {item.cnpj}
                                                </div>
                                                <div className="column-15">
                                                    {item.telefone}
                                                </div>
                                                <div className="column-25">
                                                    {item.email}
                                                </div>
                                            </div>
                                        </Fragment>
                                    )))
                                )
                            }
                            <h5>Exibindo {seguradoras.length} {seguradoras.length === 1 ? "resultado" : "resultados"}</h5>
                        </div>
                    </div> */}
                </div>
            </main>
        </Fragment>
    )
};