import { Fragment } from 'react';
import Header from '../../components/header/index';
import ContentAdministracaoSenhas from '../../components/administracao/senhas';

const SenhasScreen = (props) => (
  <Fragment>
    <Header ativo={props.ativo}/>
    <ContentAdministracaoSenhas/>
  </Fragment>
)

export default SenhasScreen;