import Api from './api';

const EmpresasService = {
    index: () => Api.get('/empresas'),
    create: (params) => Api.post('/empresas', (params)),
    empresa: (id) => Api.get(`/empresas/${id}`),
    desabilitar_empresa: (id) => Api.put(`/empresas/${id}/desabilitar`),
    editar_empresa: (id, params) => Api.put(`/empresas/${id}`, (params)),
    excluir_empresa: (id) => Api.delete(`/empresas/${id}`),
    sinistros: (id) => Api.get(`/empresas/${id}/sinistros`),
    acessos: (id) => Api.get(`/empresas/${id}/acessos`),
    criar_acesso: (id, params) => Api.post(`/empresas/${id}/acessos`, (params)),
    add_acesso: (id, userId) => Api.post(`/empresas/${id}/acessos/${userId}`),
    remover_acesso: (id, userId) => Api.delete(`/empresas/${id}/acessos/${userId}`),
    search_acesso: async (params) => await Api.get(`/empresa/search?email=${params}`),
}

export default EmpresasService;