import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faUserPen } from '@fortawesome/free-solid-svg-icons';
import "../../../styles/usuarios.scss";
import "../../../styles/reset.scss";
import Modal from '../../layouts/modal';
import ModalDangerSmall from '../../layouts/modalDangerSmall';
import UsersService from '../../../services/users';
import Loading from '../../layouts/loading';
import Notification from '../../layouts/notification';

function ContentAdministracao() {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [userAtivo, setUserAtivo] = useState();
    const [user, setUser] = useState();
    const [modalCreateUser, setModalCreateUser] = useState(false);
    const [modalEditUser, setModalEditUser] = useState(false);
    const [modalResetarSenha, setModalResetarSenha] = useState(false);
    const [modalDeleteUser, setModalDeleteUser] = useState(false);
    const [notifications, setNotifications] = useState([]);

    function createNotification(tipo, titulo, mensagem, tempo) {
        // TIPOS: Sucesso / Erro / Alerta / Info
        let notificacao = []
        notificacao.tipo = tipo;
        notificacao.titulo = titulo;
        notificacao.mensagem = mensagem;
        notificacao.id = notifications.length === 0 ? 1 : notifications.at(-1).id + 1 
        
        setNotifications((prevNotifications) => [...prevNotifications, notificacao]);
        
        setTimeout(() => {
            removeNotification(notificacao.id)
        }, tempo * 1000 || 5000);
    }

    function removeNotification(notificationRemove) {
        setNotifications((prevNotifications) =>
            prevNotifications.filter((notification) => notification.id !== notificationRemove)
        );
    };

    useEffect(() => {
        (async function() {
            const response = await UsersService.index();
            const response_user = await UsersService.logado();
            setUser(response_user.data.user)
            setUsers(response.data)
        })();
    }, []);

    async function createUsuario(event) {
        event.preventDefault();
        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData);

        data.email = data.email.toLocaleLowerCase();
        
        try {
            await UsersService.registrar(data);
            window.location.reload();
        } catch (error) {
            console.log(error)
        }
    };

    async function editUsuario(event) {
        event.preventDefault();
        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData);
        
        try {
            await UsersService.editar_usuario(userAtivo._id, data);
            window.location.reload();
        } catch (error) {
            console.log(error)
        }
    };

    async function resetarSenha() {
        try {
            if (userAtivo.password) {
                const res = await UsersService.reset_senha(userAtivo._id);
                createNotification("Sucesso", "Sucesso", res.data.message, 5)
                setModalResetarSenha(false);
            } else {
                createNotification("Erro", "Senha não cadastrada", "Usuário com senha não cadastrada ainda, solicite para que seja criada a primeira senha.", 10)
                setModalResetarSenha(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    async function desativarUser() {
        await UsersService.desativar(userAtivo._id);
        window.location.reload();
    };

// ORDENAR PERMISSÃO
    function ordemPermissao(a, b){
        return a.permission < b.permission ? -1 : a.permission > b.permission ? 1 : 0;
    }

    document.title = 'Usuários - JMX Corretora de Seguros';

    return(
        <Fragment>
            { users.lenght !== 0 ?
                <main id='main-usuarios'>
                    <div className="cabecalho-conteudo">
                        <h2>Usuários</h2>
                        <div className="buttons-conteudo">
                            <button className="button-red" onClick={()=> navigate(`/administracao`)}>Voltar</button>
                            <button className="button-blue">Imprimir</button>
                            <button className="button-green" onClick={() => setModalCreateUser(true)}>Novo Usuário</button>
                        </div>
                    </div>
                    <div className="content-usuarios">
                        {users.user?.filter(({ excluido }) => excluido === false).sort(ordemPermissao).map((item, index) => (
                            <div key={index} className="list-itens">
                                <div className="content-item">
                                    <div className='img-user'>
                                        <img src={users.url_images + item.imagem} alt={`Foto de ${item.name}`}/>
                                    </div>
                                    <div className="titulo-usuario">
                                        {(() => {
                                                switch (true) {
                                                    case (item.permission === 1):
                                                        return(<Fragment><h3 style={{color: "#007bff"}}>{item.name}</h3><span>Desenvolvedor</span></Fragment>)
                                                    case (item.permission === 2):
                                                        return(<Fragment><h3 style={{color: "#298A08"}}>{item.name}</h3><span>Sócio Administrador</span></Fragment>)
                                                    case (item.permission === 3):
                                                        return(<Fragment><h3 style={{color: "#4e5e70"}}>{item.name}</h3><span>Corretor</span></Fragment>)
                                                    case (item.permission === 4):
                                                        return(<Fragment><h3 style={{color: "#DC143C"}}>{item.name}</h3><span>Auxiliar Administrativo</span></Fragment>)
                                                    case (item.permission === 5):
                                                        return(<Fragment><h3 style={{color: "#FFFF00"}}>{item.name}</h3><span>Cliente</span></Fragment>)
                                                    case (item.permission === 6):
                                                        return(<Fragment><h3 style={{color: "#8B008B"}}>{item.name}</h3><span>Beta Tester</span></Fragment>)
                                                    case (item.permission === 7):
                                                        return(<Fragment><h3 style={{color: "#FFA500"}}>{item.name}</h3><span>Visualizador</span></Fragment>)
                                                    default:
                                                    break;
                                                }
                                        })()}
                                    </div>
                                    <div className="campo-info-user">
                                        <h4>E-mail:</h4>
                                        <h5>
                                            {item.email}
                                        </h5>
                                    </div>
                                </div>
                                {user.permission > 4 || user.permission > item.permission ?
                                    (<button className='button-desativado'>
                                        <FontAwesomeIcon icon={faUserPen}/>
                                        <p>Editar</p>
                                    </button>)
                                :
                                    (<button onClick={() => {setUserAtivo(item); setModalEditUser(true)}}>
                                        <FontAwesomeIcon icon={faUserPen}/>
                                        <p>Editar</p>
                                    </button>)
                                }
                                {user.permission > 4 || user.permission > item.permission  ?
                                    (<button className='button-desativado'>
                                        <FontAwesomeIcon icon={faTrashCan}/>
                                        <p>Excluir</p>
                                    </button>)
                                :
                                    (<button onClick={() => {setUserAtivo(item); setModalDeleteUser(true);} }>
                                        <FontAwesomeIcon icon={faTrashCan}/>
                                        <p>Excluir</p>
                                    </button>)
                                }
                            </div>
                        ))}
                    </div>
                </main>
            : 
                <Loading/>
            }
            {modalCreateUser ? (
                <Modal titulo="Novo Usuário" width="40%" onClose={() => {setModalCreateUser(false)}}>
                    <form onSubmit={createUsuario}>
                        <div id="campos-novo-usuario">
                            <div className="campo-form campo-100">
                                <label htmlFor="email-usuario">E-mail</label>
                                <input type="email" name="email" id="email-usuario"/>
                            </div>
                            <div className="campo-form campo-65">
                                <label htmlFor="name-usuario">Nome</label>
                                <input type="text" name="name" id="name-usuario"/>
                            </div>
                            <div className="campo-form campo-35">
                                <label htmlFor="permissao-usuario">Permissão</label>
                                <select name="permission" id="permissao-usuario" defaultValue="" required>
                                    <option value="" disabled>Selecione</option>
                                    {user.permission <= 1 ? <option value="1">Desenvolvedor</option> : null}
                                    {user.permission <= 2 ? <option value="2">Sócio Proprietário</option> : null}
                                    {user.permission <= 3 ? <option value="3">Corretor</option> : null}
                                    {user.permission <= 4 ? <option value="4">Auxiliar Administrativo</option> : null}
                                    {user.permission <= 5 ? <option value="5">Cliente</option> : null}
                                    {user.permission <= 1 ? <option value="6">Beta Tester</option> : null}
                                    <option value="7">Visualizador</option>
                                </select>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalCreateUser(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Cadastrar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalEditUser ? (
                <Modal titulo="Editar Usuário" width="40%" onClose={() => {setModalEditUser(false)}}>
                    <form onSubmit={editUsuario}>
                        <div id="campos-novo-usuario">
                            <div className="campo-form campo-100">
                                <label htmlFor="email-usuario">E-mail</label>
                                <input type="email" name="email" id="email-usuario" defaultValue={userAtivo.email}/>
                            </div>
                            <div className="campo-form campo-65">
                                <label htmlFor="name-usuario">Nome</label>
                                <input type="text" name="name" id="name-usuario" defaultValue={userAtivo.name}/>
                            </div>
                            <div className="campo-form campo-35">
                                <label htmlFor="permissao-usuario">Permissão</label>
                                <select name="permission" id="permissao-usuario" defaultValue={userAtivo.permission}>
                                    {user.permission <= 1 ? <option value="1">Desenvolvedor</option> : null}
                                    {user.permission <= 2 ? <option value="2">Sócio Proprietário</option> : null}
                                    {user.permission <= 3 ? <option value="3">Corretor</option> : null}
                                    {user.permission <= 4 ? <option value="4">Auxiliar Administrativo</option> : null}
                                    {user.permission <= 5 ? <option value="5">Cliente</option> : null}
                                    {user.permission <= 1 ? <option value="6">Beta Tester</option> : null}
                                    <option value="7">Visualizador</option>
                                </select>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalEditUser(false)}}>Cancelar</button>
                            <button type="button" className="button-blue" onClick={() => {setModalEditUser(false); setModalResetarSenha(true)}}>Resetar Senha</button>
                            <button type="submit" className="button-green">Salvar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalResetarSenha ? (
                <ModalDangerSmall mensagem={[`Deseja realmente resetar a senha do usuário ${userAtivo.name} para `, <span>123@mudar</span>,"?"]} onClose={() => {setModalResetarSenha(false)}}>
                    <div className='buttons-form'>
                        <button type="reset" className="button-red" onClick={() => {setModalResetarSenha(false); setModalEditUser(true)}}>Cancelar</button>
                        <button type="submit" className="button-blue" onClick={() => {resetarSenha()}}>Resetar</button>
                    </div>
                </ModalDangerSmall>
            ): null}
            {modalDeleteUser ? (
                <ModalDangerSmall mensagem={['Deseja excluir o usuário', <br/>,<span>{userAtivo.name}</span>,"?"]} onClose={() => {setModalDeleteUser(false)}}>
                    <Fragment>
                        <div className='buttons-form'>
                            <button type="button" className="button-red" onClick={() => {setModalDeleteUser(false)}}>Cancelar</button>
                            <button type="submit" className="button-blue" onClick={() => {desativarUser()}}>Excluir</button>
                        </div>
                    </Fragment>
                </ModalDangerSmall>
            ): null}
            { notifications.length !== 0 ?
                <div className='area-notification'>
                    {notifications.map((item, index) =>(
                        <Notification tipo={item.tipo} titulo={item.titulo} mensagem={item.mensagem} onClose={() => removeNotification(item.id)} key={index}/>
                    ))}
                </div>
            : null }
        </Fragment>
    )
};
   
export default ContentAdministracao;