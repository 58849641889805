import React, { Fragment, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faXmark, faMinus, faCaretDown, faTrashCan, faUserPen, faPen, faCloudArrowUp, faSpinner, faSearch, faFileImport, faEye, faArrowsRotate } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';
import 'moment-timezone';
import "../../styles/sinistro.scss";
import "../../styles/reset.scss";
import Api from '../../services/api';
import UsersService from '../../services/users';
import Modal from '../layouts/modal';
import ModalDangerSmall from '../layouts/modalDangerSmall';
import SinistrosService from '../../services/sinistros';
import HistoricosService from '../../services/historicos';
import AgendasService from '../../services/agendas';
import MatteusImage from "../../assets/users/Matteus.png";
import GeovanaImage from "../../assets/users/Geovana.jpeg";
import ClienteImage from "../../assets/users/Geovana.jpg";
import PadraoImage from "../../assets/users/padrão.jpg";
import RaphaelImage from "../../assets/users/Raphael.jpg";
import Notification from '../layouts/notification';
import Loading from '../layouts/loading';
import moment from 'moment-timezone';
import EmpresasService from '../../services/empresas';
import SeguradorasService from '../../services/seguradoras';
import { NumericFormat } from 'react-number-format';

export default function ContentSinistro() {
    const [modalEditarSinistro, setModalEditarSinistro] = useState(false);
    const [modalExcluirSinistro, setModalExcluirSinistro] = useState(false);
    const [modalCadastrarHistorico, setModalCadastrarHistorico] = useState(false);
    const [modalExcluirHistorico, setModalExcluirHistorico] = useState(false);
    const [modalAdicionarDocumento, setModalAdicionarDocumento] = useState(false);
    const [modalSolicitarDocumento, setModalSolicitarDocumento] = useState(false);
    const [modalAlterarDocumento, setModalAlterarDocumento] = useState(false);
    const [modalAnexarDocumento, setModalAnexarDocumento] = useState(false);
    const [modalAlterarStatusDocumento, setModalAlterarStatusDocumento] = useState(false);
    const [modalExcluirDocumento, setModalExcluirDocumento] = useState(false);
    const [modalCadastrarAgenda, setModalCadastrarAgenda] = useState(false);
    const [modalExcluirAgenda, setModalExcluirAgenda] = useState(false);
    const [modalCadastrarBeneficiario, setModalCadastrarBeneficiario] = useState(false);
    const [modalEditarBeneficiario, setModalEditarBeneficiario] = useState(false);
    const [modalExcluirBeneficiario, setModalExcluirBeneficiario] = useState(false);
    const [modalAlterarStatus, setModalAlterarStatus] = useState(false);
    const [aguardandoRetorno, setAguardandoRetorno] = useState(false);
    const [seguradoras, setSeguradoras] = useState([]);
    const [empresas, setEmpresas] = useState([]);
    const [sinistro, setSinistro] = useState([]);
    const [corretor, setCorretor] = useState([]);
    const [tipo, setTipo] = useState([]);
    const [motivo, setMotivo] = useState();
    const [permission, setPermission] = useState();
    const [user, setUser] = useState();
    const [agendas, setAgendas] = useState([]);
    const [beneficiarios, setBeneficiarios] = useState([]);
    const [historicos, setHistoricos] = useState([]);
    const [documentos, setDocumentos] = useState([]);
    const [documentoAtivo, setDocumentoAtivo] = useState([]);
    const [abaAtiva, setAbaAtiva] = useState("historicos");
    const [historicoAtivo, setHistoricoAtivo] = useState();
    const [agendaAtiva, setAgendaAtiva] = useState();
    const [beneficiarioAtivo, setBeneficiarioAtivo] = useState();
    const [naoSucesso, setNaoSucesso] = useState(false);
    const [fileName, setFileName] = useState('');
    const [notifications, setNotifications] = useState([]);
    const [statusIncorreto, setStatusIncorreto] = useState(false);
    const [filtroSelecionado, setFiltroSelecionado] = useState(localStorage.getItem('filtro-ativo-documentos') || 'solicitados');
    const [finds, setFinds] = useState("");
    const navigate = useNavigate();
    const { id } = useParams();

    function createNotification(tipo, titulo, mensagem, tempo) {
        // TIPOS: Sucesso / Erro / Alerta / Info
        let notificacao = []
        notificacao.tipo = tipo;
        notificacao.titulo = titulo;
        notificacao.mensagem = mensagem;
        notificacao.id = notifications.length === 0 ? 1 : notifications.at(-1).id + 1 
        
        setNotifications((prevNotifications) => [...prevNotifications, notificacao]);
        
        setTimeout(() => {
            removeNotification(notificacao.id)
        }, tempo * 1000 || 5000);
    };

    function removeNotification(notificationRemove) {
        setNotifications((prevNotifications) =>
            prevNotifications.filter((notification) => notification.id !== notificationRemove)
        );
    };

    async function getHistoricos() {
        try {
            const res = await SinistrosService.historicos(id);
            setHistoricos(res.data);
        } catch (error) {
            console.log(error)
        }
    };

    async function getDocumentos() {
        try {
            const res = await SinistrosService.documentos(id);
            setDocumentos(res.data);
        } catch (error) {
            console.log(error);
        }
    };

    async function getAgendas() {
        try {
            const res = await SinistrosService.agendas(id);
            setAgendas(res.data);
        } catch (error) {
            console.log(error);
        }
    };

    async function getBeneficiarios() {
        Api.get(`/sinistros/${id}/beneficiarios`).then(({ data }) => {
            setBeneficiarios(data.beneficiarios);
        });
    };

    async function getEmpresas() {
        const res = await EmpresasService.index();
        setEmpresas(res.data);
    }

    useEffect(() => {
        (async function() {
            const response = await SinistrosService.sinistro(id);
            setTipo(response.data.tipo)
            const response_user = await UsersService.logado();
            setUser(response_user.data.user)
            setPermission(response_user.data.user.permission)
            setSinistro(response.data);
            alterarTipos();
            const response_seguradoras = await SeguradorasService.index();
            setSeguradoras(response_seguradoras.data);
            const response_corretor = await UsersService.index();
            setCorretor(response_corretor.data.user.filter(({ corretor }) => corretor === true).filter(({ excluido }) => excluido === false));
        })();

        getBeneficiarios();
        getHistoricos();
        getDocumentos();
        getAgendas();
        getEmpresas();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function alterarTipos() {
        if (sinistro.ramo === "Vida") {
            if (tipo === "Sinistro") {
                setMotivo(["Invalidez por Acidente", "Invalidez por Doença", "Morte Conjuge", "Morte Filho", "Morte Titular", "Outros"]);
            } else if (tipo === "Assistência"){
                setMotivo(["Alimentação", "Funeral", "Natalidade", "Outros"]);
            }
        } else if (sinistro.ramo === "Auto"){
            if (tipo === "Sinistro") {
                setMotivo(["Colisão", "Furto e Roubo", "Outros"]);
            } else if (tipo === "Assistência"){
                setMotivo(["Guincho", "Pane Mecânica/Elétrica", "Veículo Reserva", "Vidros", "Outros"]);
            }
        } else if (sinistro.ramo === "Residêncial"){
            if (tipo === "Sinistro") {
                setMotivo(["Incêndio", "Furto e Roubo", "Outros"]);
            } else if (tipo === "Assistência"){
                setMotivo(["Chaveiro", "Danos Elétricos", "Pagamento de Aluguel", "Vidros", "Outros"]);
            }
        }
    }

    useEffect(() => {
        alterarTipos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tipo]);

    function getNameFile(e) {
        const file = e.target.files[0];

        if (file) {
            setFileName(file.name);
        } else {
            setFileName("")
        }
    };

    async function alterarStatus(e) {
        e.preventDefault();

        let params = {};
        const formData = new FormData(e.target);
        params = Object.fromEntries(formData);
        params.dataAlteracao = moment(`${params.data} ${params.horario}`);
        delete params.data;
        delete params.horario;

        setAguardandoRetorno(true);
        await SinistrosService.alterar_status(id, params);
        window.location.reload();
    };

// HISTÓRICOS
    async function createHistorico(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let form = Object.fromEntries(formData);
        form.data = new Date(`${form.data} ${form.horario}`);
        
        if (form.sucesso === "true") {
            form.sucesso = true;
        } else {
            form.sucesso = false;
        }
        
        if (form.privado === "true") {
            form.privado = true;
        } else {
            form.privado = false;
        }

        delete form.horario;

        try {
            await SinistrosService.criar_historico(id, form);
            await getHistoricos();
            setModalCadastrarHistorico(false);
        } catch (error) {
            console.log(error)
        }
    };

    async function deleteHistorico() {
        await HistoricosService.delete(historicoAtivo);
        setModalExcluirHistorico(false);
        getHistoricos();
    };

// DOCUMENTOS
    async function addDocumento(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let form = Object.fromEntries(formData);

        if (form.privado === 'true') {
            form.privado = true;
        } else {
            form.privado = false;
        }

        if (form.observacao === "") {
            delete form.observacao;
        }

        try {
            if (form.documento.size !== 0) {
                await SinistrosService.add_documento(id, form);
                await getDocumentos();
                setModalAdicionarDocumento(false);
                setFileName("");
            } else {
                createNotification('Erro', 'Preenchimento', "É necessário anexar algum documento antes de prosseguir.", 7)
            }
        } catch (error) {
            console.log(error)
        }
    };

    async function solicitarDocumento(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let form = Object.fromEntries(formData);

        if (form.observacao === "") {
            delete form.observacao;
        }

        try {
            await SinistrosService.solicitar_documento(id, form);
            await getDocumentos();
            setModalSolicitarDocumento(false);
        } catch (error) {
            console.log(error)
        }
    };

    // async function alterarDocumento() {
    //     if (filtroSelecionado === "solicitados") {
    //         // await SinistrosService.delete_documento_solicitado(documentoAtivo._id);
    //         console.log('solicitados');
    //     } else if (filtroSelecionado === "anexados") {
    //         // await SinistrosService.delete_documento_complementar(documentoAtivo._id);
    //         console.log('anexados');
    //     }

    //     // setModalExcluirHistorico(false);
    //     // getHistoricos();
    // };

    async function anexarDocumento(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let form = Object.fromEntries(formData);

        try {
            if (form.documento.size !== 0) {
                await SinistrosService.anexar_documento(id, documentoAtivo._id, form);
                await getDocumentos();
                setModalAnexarDocumento(false);
                setFileName("");
            } else {
                createNotification('Erro', 'Preenchimento', "É necessário anexar algum documento antes de prosseguir.", 7)
            }
        } catch (error) {
            console.log(error)
        }
    };

    async function alterarStatusDocumento(e) {
        e.preventDefault();

        const formData = new FormData(e.target);
        const form = Object.fromEntries(formData);

        try {
            setAguardandoRetorno(true);
            await SinistrosService.alterar_status_documento(id, documentoAtivo._id, form);
            getDocumentos();
            setModalAlterarStatusDocumento(false);
            setAguardandoRetorno(false);
        } catch (error) {
            console.log(error);
        }
    };

    async function alterarDocumento(e) {
        e.preventDefault();

        const formData = new FormData(e.target);
        const form = Object.fromEntries(formData);

        if (form.observacao === "") {
            delete form.observacao;
        }

        if (form.documento.size === 0) {
            delete form.documento;
        }

        try {
            setAguardandoRetorno(true);
            await SinistrosService.alterar_documento(id, documentoAtivo._id, form, filtroSelecionado);
            getDocumentos();
            setModalAlterarDocumento(false);
            setAguardandoRetorno(false);
            setFileName("");
        } catch (error) {
            console.log(error);
        }
    };

    async function deleteDocumento() {
        try {
            await SinistrosService.delete_documento(id, documentoAtivo._id, filtroSelecionado);
            getDocumentos();
            setModalExcluirDocumento(false);
        } catch (error) {
            console.log(error);
        };
    };

// AGENDA
    async function createAgenda(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let form = Object.fromEntries(formData);
        form.data = new Date(`${form.data} ${form.horario}`);

        delete form.horario;

        try {
            console.log(form);
            await SinistrosService.criar_agenda(id, form);
            await getAgendas();
            setModalCadastrarAgenda(false);
        } catch (error) {
            console.log(error)
        }
    };

    async function deleteAgenda() {
        await AgendasService.delete(agendaAtiva);
        setModalExcluirAgenda(false);
        getAgendas();
    };

// BENEFICIÁRIOS
    async function createBeneficiario(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        const form = Object.fromEntries(formData);

        const regex = /[\d,]+/g;

        form.valor = form.valor.match(regex).join('').replace(',', '.');
        form.valor = Number(form.valor);
        
        try {
            await SinistrosService.add_beneficiario(id, form);
            // await Api.post(`/sinistros/${id}/beneficiarios`, data);
            setModalCadastrarBeneficiario(false);
            getBeneficiarios();
        } catch (error) {
            console.log(error)
        }
    };

    async function editBeneficiario(event) {
        const otherId = beneficiarioAtivo._id;
        event.preventDefault();
        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData);

        const regex = /[\d,]+/g;

        data.valor = data.valor.match(regex).join('').replace(',', '.');
        data.valor = Number(data.valor);
        
        try {
            await SinistrosService.edit_beneficiario(id, otherId, data);
            setModalEditarBeneficiario(false);
            getBeneficiarios();
        } catch (error) {
            console.log(error)
        }
    };

    async function deleteBeneficiario(otherId) {
        try {
            await SinistrosService.delete_beneficiario(id, otherId);
            setModalExcluirBeneficiario(false);
            getBeneficiarios();
        } catch (error) {
            console.log(error)
        }
    };

    function getBeneficiarioAtivo(id) {
        setBeneficiarioAtivo(beneficiarios?.filter(({ _id }) => _id === id)[0]);
    };

// SINISTRO
    async function desativarSinistro(id) {
        window.location.href = '/corretora/sinistros';
        await SinistrosService.excluir_sinistro(id);
    };

    async function editSinistro(event) {
        const otherId = beneficiarioAtivo._id;
        event.preventDefault();
        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData);
        
        try {
            await SinistrosService.edit_beneficiario(id, otherId, data);
            setModalEditarBeneficiario(false);
            getBeneficiarios();
        } catch (error) {
            console.log(error)
        }
    };

    function busca(a) {
        if (finds === "") {
            return a;
        } else {
            return a.descricao.toLowerCase().includes(finds.toLowerCase()) 
            // || (itemAtivo === "pagar" ? a.parceiro.razao_social.toLowerCase().includes(finds.toLowerCase()) : a.cliente.toLowerCase().includes(finds.toLowerCase())) 
            || (a.observacao && a.observacao.toLowerCase().includes(finds.toLowerCase())) 
            || (a.enviado_corretor && a.enviado_corretor.name.toLowerCase().includes(finds.toLowerCase())) 
            || (a.enviado_empresa && a.enviado_empresa.name.toLowerCase().includes(finds.toLowerCase())) 
            // || a.valor.toLocaleString('pt-br', {minimumFractionDigits: 2}).includes(finds)
            || (a.sent_at && a.sent_at.replace(/^(\d{4})-(\d{2})-(\d{2}).*$/, "$3/$2/$1").includes(finds))
            || a.created_at.replace(/^(\d{4})-(\d{2})-(\d{2}).*$/, "$3/$2/$1").includes(finds)
            || (a.created_by && a.created_by.name.toLowerCase().includes(finds.toLowerCase()))
            // || (a.pagamento && a.pagamento.replace(/^(\d{4})-(\d{2})-(\d{2}).*$/, "$3/$2/$1").includes(finds))
        }
    };

// ORDENAR DATA
    function ordemData(a, b){
        return a.data > b.data ? -1 : a.data < b.data ? 1 : 0;
    };

    document.title = `Sinistro ${sinistro.ramo} - JMX Corretora de Seguros`;

    return(
        <Fragment>
            { sinistro.length !== 0 ? 
            <main id='main-sinistro'>
                <div className="conteudo-sinistros">
                    <div className="cabecalho-conteudo">
                        {(() => {
                            switch (true) {
                                case (sinistro.ramo === "Vida"):
                                    return(
                                        <Fragment>
                                            <h2>Sinistro - {sinistro.sinistradoNome}</h2>
                                        </Fragment>
                                    );
                                case (sinistro.ramo === "Auto"):
                                    return(
                                        <Fragment>
                                            <h2>Sinistro - {sinistro.sinistradoPlacas}</h2>
                                        </Fragment>
                                    );
                                case (sinistro.ramo === "Residêncial"):
                                    return(
                                        <Fragment>
                                            <h2>Sinistro - {sinistro.sinistradoEndereco}, {sinistro.sinistradoNumero}, {sinistro.sinistradoCidade}/{sinistro.sinistradoEstado.toUpperCase()}</h2>
                                        </Fragment>
                                    );
                                default:
                                break;
                            }
                        })()}
                        <div className="buttons-conteudo">
                            <button className="button-red" onClick={()=> navigate(`/corretora/sinistros`)}>Voltar</button>
                            {(sinistro.status.at(-1).situacao === "Finalizado" || sinistro.status.at(-1).situacao === "Pagamento Recusado") && permission !== 1 ? null : <button className="button-blue" onClick={() => {setModalAlterarStatus(true)}}>Alterar Status</button>}
                            {/* <button className="button-cyan edit-sinistro" onClick={() => {setModalEditarSinistro(true)}}>Editar sinistro</button> */}
                            {permission <= 2 || permission === 6 ? <button className="button-darkred" onClick={() => {setModalExcluirSinistro(true)}}>Excluir</button> : null }
                        </div>
                    </div>
                    <div className="timeline-sinistro">
                        {sinistro.status?.map((item, index) => (
                            <div key={index} className="box-status">
                                <h4>
                                    <Moment format="DD/MM/YY">
                                        {item.dataAlteracao}
                                    </Moment>
                                </h4>
                                {(() => {
                                    if (index === sinistro.status.length - 1) {
                                        if (item.situacao === "Finalizado") {
                                            return(
                                                <div className="rounded-div rounded-green">
                                                    <div className="circulo circulo-green">
                                                        <FontAwesomeIcon icon={faCheck}/>
                                                    </div>
                                                </div>
                                            )
                                        } else if (item.situacao === "Pagamento Recusado"){
                                            return(
                                                <div className="rounded-div rounded-red">
                                                    <div className="circulo circulo-red">
                                                        <FontAwesomeIcon icon={faXmark}/>
                                                    </div>
                                                </div>
                                            )
                                        } else{
                                            return(
                                                <div className="rounded-div">
                                                    <div className="circulo">
                                                        <FontAwesomeIcon icon={faMinus}/>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    } else {
                                        return(
                                            <div className="rounded-div">
                                                <div className="circulo">
                                                    <FontAwesomeIcon icon={faCheck}/>
                                                </div>
                                            </div>
                                        )
                                    }
                                })()}
                                <h5>{item.situacao}</h5>
                            </div>
                        ))}
                    </div>
                    <div className="box-content informacoes-sinistro">
                        <div className="campo-info campo-25">
                            <h5>Tipo de Sinistro</h5>
                            <p>{sinistro.tipo} {sinistro.ramo}</p>
                                {/* {(() => {
                                    switch (true) {
                                        case (sinistro.tipo === "sinistro"):
                                            switch (true) {
                                                case (sinistro.ramo === "vida"):
                                                    return("Sinistro Vida");
                                                case (sinistro.ramo === "auto"):
                                                    return("Sinistro Auto");
                                                case (sinistro.ramo === "residencial"):
                                                    return("Sinistro Residêncial");
                                                default:
                                                break;
                                            }
                                            break;
                                        case (sinistro.tipo === "Assistência"):
                                            switch (true) {
                                                case (sinistro.ramo === "vida"):
                                                    return("Assistência Vida");
                                                case (sinistro.ramo === "auto"):
                                                    return("Assistência Auto");
                                                case (sinistro.ramo === "residencial"):
                                                    return("Assistência Residêncial");
                                                default:
                                                break;
                                            }
                                            break;
                                        default:
                                        break;
                                    }
                                })()} */}
                        </div>
                        <div className="campo-info campo-25">
                            <h5>Motivo</h5>
                            <p>{sinistro.motivo}</p>
                        </div>
                        <div className="campo-info campo-25">
                            <h5>Aviso de Sinistro</h5>
                            <p>{sinistro.nSinistro}</p>
                        </div>
                        <div className="campo-info campo-25">
                            <h5>Protocolo de Atendimento</h5>
                            <p>{sinistro.protocolo}</p>
                        </div>
                        <h3>Informações Titular do Seguro</h3>
                        <div className="campo-info campo-40">
                            <h5>Nome Completo</h5>
                            <p>{sinistro.titularNome}</p>
                        </div>
                        <div className="campo-info campo-25">
                            <h5>Documento</h5>
                            <p>
                                {sinistro.titularDoc === "" || sinistro.titularDoc === undefined || sinistro.titularDoc === null ?
                                    "--"
                                :
                                    sinistro.titularDoc
                                }
                            </p>
                        </div>
                        <div className="campo-info campo-35">
                            <h5>Empresa</h5>
                            <p>{sinistro.empresa ? sinistro.empresa.razao_social : "Não Vinculado"}</p>
                        </div>
                        {(() => {
                            switch (true) {
                                case (sinistro.ramo === "Vida"):
                                    return(
                                        <Fragment>
                                            <h3>Informações do Sinistrado</h3>
                                            <div className="campo-info campo-60">
                                                <h5>Nome Completo</h5>
                                                <p>{sinistro.sinistradoNome}</p>
                                            </div>
                                            <div className="campo-info campo-20">
                                                <h5>CPF</h5>
                                                <p>{sinistro.sinistradoCpf}</p>
                                            </div>
                                            <div className="campo-info campo-20">
                                                <h5>Nascimento</h5>
                                                <Moment format="DD/MM/YYYY">
                                                    {sinistro.sinistradoNasc}
                                                </Moment>
                                            </div>
                                        </Fragment>)
                                case (sinistro.ramo === "Residêncial"):
                                    return(
                                        <Fragment>
                                            <h3>Informações da Residência</h3>
                                            <div className="campo-info campo-15">
                                                <h5>CEP</h5>
                                                <p>{sinistro.sinistradoCep}</p>
                                            </div>
                                            <div className="campo-info campo-60">
                                                <h5>Endereço</h5>
                                                <p>{sinistro.sinistradoEndereco}, {sinistro.sinistradoNumero}, {sinistro.sinistradoBairro} - {sinistro.sinistradoComplemento}</p>
                                            </div>
                                            <div className="campo-info campo-25">
                                                <h5>Cidade</h5>
                                                <p>{sinistro.sinistradoCidade} - {sinistro.sinistradoEstado.toUpperCase()}</p>
                                            </div>
                                        </Fragment>)
                                case (sinistro.ramo === "Auto"):
                                    return(
                                        <Fragment>
                                            <h3>Informações do Veículo</h3>
                                            <div className="campo-info campo-25">
                                                <h5>Placa</h5>
                                                <p>
                                                    {sinistro.sinistradoPlacas === "" || sinistro.sinistradoPlacas === undefined || sinistro.sinistradoPlacas === null ?
                                                        "--"
                                                    :
                                                        sinistro.sinistradoPlacas
                                                    }
                                                </p>
                                            </div>
                                            <div className="campo-info campo-25">
                                                <h5>Renavam</h5>
                                                <p>
                                                    {sinistro.sinistradoRenavam === "" || sinistro.sinistradoRenavam === undefined || sinistro.sinistradoRenavam === null ?
                                                        "--"
                                                    :
                                                        sinistro.sinistradoRenavam
                                                    }
                                                </p>
                                            </div>
                                            <div className="campo-info campo-25">
                                                <h5>Ano/Modelo</h5>
                                                <p>
                                                    {sinistro.sinistradoAnoFab === "" || sinistro.sinistradoAnoFab === undefined || sinistro.sinistradoAnoFab === null ?
                                                        "--"
                                                    :
                                                        sinistro.sinistradoAnoFab
                                                    }
                                                </p>
                                            </div>
                                            <div className="campo-info campo-25">
                                                <h5>Veículo</h5>
                                                <p>{sinistro.sinistradoModelo}/{sinistro.sinistradoMarca}</p>
                                            </div>
                                        </Fragment>)
                                default:
                                break;
                            }
                        })()}
                        <h3>Dados do Seguro</h3>
                        <div className="campo-info campo-30">
                            <h5>Seguradora</h5>
                            <p>{sinistro.seguradora.fantasia}</p>
                        </div>
                        <div className="campo-info campo-30">
                            <h5>Corretor</h5>
                            <p>{sinistro.corretor.name}</p>
                        </div>
                        <div className="campo-info campo-20">
                            <h5>Apólice</h5>
                            <p>{sinistro.apolice}</p>
                        </div>
                        <div className="campo-info campo-20">
                            <h5>Data de Ocorrência</h5>
                            { sinistro.dataSinistro === "" || sinistro.dataSinistro === null || sinistro.dataSinistro === undefined ?
                                <p>--</p>
                            :
                                <Moment format="DD/MM/YYYY">
                                    {sinistro.dataSinistro}
                                </Moment>
                            }
                        </div>
                    </div>
                    <div className="abas-sinistro">
                        <div className='navegacao-abas'>
                            <div id="aba-historicos" onClick={() => setAbaAtiva("historicos")} className={abaAtiva === "historicos" ? 'aba-active' : null}>
                                <h2>Históricos</h2>
                                <FontAwesomeIcon icon={faCaretDown}/>
                            </div>
                            <div id="aba-documentos" onClick={() => setAbaAtiva("documentos")} className={abaAtiva === "documentos" ? 'aba-active' : null}>
                                <h2>Documentos</h2>
                                <FontAwesomeIcon icon={faCaretDown}/>
                            </div>
                            <div id="aba-agenda" onClick={() => setAbaAtiva("agenda")} className={abaAtiva === "agenda" ? 'aba-active' : null}>
                                <h2>Agenda</h2>
                                <FontAwesomeIcon icon={faCaretDown}/>
                            </div>
                            <div id="aba-beneficiarios" onClick={() => setAbaAtiva("beneficiarios")} className={abaAtiva === "beneficiarios" ? 'aba-active' : null}>
                                <h2>Beneficiários</h2>
                                <FontAwesomeIcon icon={faCaretDown}/>
                            </div>
                        </div>
                        <div className='conteudo-aba'>
                            {(() => {
                                switch (true) {
                                    case (abaAtiva === "historicos"):
                                        return(
                                            <div className="conteudo-historicos">
                                                <div className="opcoes-aba">
                                                    <button className="button-green" onClick={() => {setModalCadastrarHistorico(true);}}>Adicionar Histórico</button>
                                                </div>
                                                { historicos.length === 0 ? 
                                                    <p className='no-info'>Não foi cadastrado nenhum histórico ainda.</p>
                                                :
                                                ( historicos?.sort(ordemData).map((item, index) => (
                                                    <div key={index} className={item.autor._id === user._id ? 'card-historico is-owner' : 'card-historico is-not-owner'}>
                                                        <div className='foto-autor'>
                                                        {(() => {
                                                            switch (true) {
                                                                case (item.autor.permission === 1):
                                                                    return(<Fragment><img src={RaphaelImage} alt=""/></Fragment>)
                                                                case (item.autor.permission === 2):
                                                                    return(<Fragment><img src={MatteusImage} alt=""/></Fragment>)
                                                                case (item.autor.permission === 3 || item.autor.permission === 6):
                                                                    return(<Fragment><img src={PadraoImage} alt=""/></Fragment>)
                                                                case (item.autor.permission === 4):
                                                                    return(<Fragment><img src={GeovanaImage} alt=""/></Fragment>)
                                                                case (item.autor.permission === 5):
                                                                    return(<Fragment><img src={ClienteImage} alt=""/></Fragment>)
                                                                default:
                                                                break;
                                                            }
                                                    })()}
                                                        </div>
                                                        <div className='informacoes-historico'>
                                                            <div className="cabecalho-historico">
                                                                <h3>{item.assunto}</h3>
                                                                { item.autor.name === user.name || permission === 1?
                                                                    <div className='options-cabecalho-historico'>
                                                                        { item.autor.name === user.name ?
                                                                            <FontAwesomeIcon icon={faPen}/>
                                                                        : null}
                                                                        <FontAwesomeIcon icon={faTrashCan} onClick={() => {setModalExcluirHistorico(true); setHistoricoAtivo(item._id)}}/>
                                                                    </div>
                                                                : null}
                                                            </div>
                                                            <div className="dados-historico">
                                                                <div className="campo-20">
                                                                    <h5>Data/Hora:</h5>
                                                                    <p>
                                                                        <Moment format='DD/MM/YYYY HH:mm'>
                                                                            {item.data}
                                                                        </Moment>
                                                                    </p>
                                                                </div>
                                                                <div className="campo-15">
                                                                    <h5>Privado:</h5>
                                                                    <p>{item.privado ? "Sim" : "Não"}</p>
                                                                </div>
                                                                <div className="campo-20">
                                                                    <h5>Usuário:</h5>
                                                                    <p>{item.autor.name}</p>
                                                                </div>
                                                                {item.sucesso === false ?
                                                                    <div className="campo-45">
                                                                        <h5>Motivo de não ter sucesso:</h5>
                                                                        <p>{item.motivo_nao_sucesso}</p>
                                                                    </div>
                                                                : null}
                                                                <div className="campo-100">
                                                                    <h5>Descrição:</h5>
                                                                    <p>{item.descricao}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )))}
                                            </div>
                                        )
                                    case (abaAtiva === "documentos"):
                                        return(
                                            <div className="conteudo-documentos">
                                                <div className="opcoes-aba">
                                                    <button className="button-blue" onClick={() => setModalAdicionarDocumento(true)}>Adicionar Documento</button>
                                                    <button className="button-green" onClick={() => setModalSolicitarDocumento(true)}>Solicitar Documento</button>
                                                </div>
                                                <div className='container-search'>
                                                    <select defaultValue={filtroSelecionado} onChange={e => {setFiltroSelecionado(e.target.value); localStorage.setItem('filtro-ativo-documentos', e.target.value)}}>
                                                        <option value="solicitados">Solicitados</option>
                                                        <option value="anexados">Anexados</option>
                                                    </select>
                                                    <input type='text' placeholder='Buscar' onChange={e => {setFinds(e.target.value)}}/>
                                                    <FontAwesomeIcon icon={faSearch}/>
                                                </div>
                                                { filtroSelecionado === "solicitados" ?
                                                    <div className="tabela-dados tabela-documentos-solicitados">
                                                        <div className="cabecalho-dados">
                                                            <div className="column-20">Descrição</div>
                                                            <div className="column-15">Solicitado em</div>
                                                            <div className="column-15">Recebido em</div>
                                                            <div className="column-20">Anexado por</div>
                                                            <div className="column-20">Situação</div>
                                                        </div>
                                                        <div className="corpo-dados">
                                                            { documentos.documentos.filter(busca).length === 0 ? 
                                                                <p className="no-info">
                                                                    Não foi solicitado nenhum documento ainda.
                                                                </p>
                                                            : 
                                                                documentos.documentos.filter(busca).map((item, index) => (
                                                                    <div className="row-dados" key={index}>
                                                                        <div className="column-20">
                                                                            {item.descricao}
                                                                        </div>
                                                                        <div className="column-15">
                                                                            <Moment format='DD/MM/YYYY'>{item.created_at}</Moment>
                                                                        </div>
                                                                        <div className="column-15">
                                                                            {item.status.at(-1).situacao === "Em análise pela corretora" || item.status.at(-1).situacao === "Documento Verificado" || item.status.at(-1).situacao === "Enviado para seguradora" ? <Moment format='DD/MM/YYYY'>{item.sent_at}</Moment> : "--"}
                                                                        </div>
                                                                        <div className="column-20">
                                                                            {item.status.at(-1).situacao === "Em análise pela corretora" || item.status.at(-1).situacao === "Documento Verificado" || item.status.at(-1).situacao === "Enviado para seguradora" ? item.enviado_corretor ? item.enviado_corretor.name : item.enviado_empresa.name : "--" }
                                                                        </div>
                                                                        <div className="column-20">
                                                                            {item.status.at(-1).situacao === "Pendente" || item.status.at(-1).situacao === "Documento Incorreto" ? <span className='sit-solicitado'>Solicitado</span> : item.status.at(-1).situacao === "Em análise pela corretora" ? <span className='sit-verificacao'>Aguardando verificação</span> : item.status.at(-1).situacao === "Documento Verificado" ? <span className='sit-aguardando-envio'>Enviar a seguradora</span> : <span className='sit-enviado'>Enviado para seguradora</span> }
                                                                        </div>
                                                                        <div className="column-icon">
                                                                            {item.status.at(-1).situacao !== "Pendente" && item.status.at(-1).situacao !== "Documento Incorreto" ?
                                                                                <FontAwesomeIcon icon={faArrowsRotate} onClick={() => {setModalAlterarStatusDocumento(true); setDocumentoAtivo(item)}}/>
                                                                            :
                                                                                null
                                                                            }
                                                                        </div>
                                                                        <div className="column-icon">
                                                                            {item.status.at(-1).situacao === "Pendente" || item.status.at(-1).situacao === "Documento Incorreto" ? 
                                                                                <FontAwesomeIcon icon={faFileImport} onClick={() => {setModalAnexarDocumento(true); setDocumentoAtivo(item)}}/>
                                                                            :
                                                                                <FontAwesomeIcon icon={faEye} onClick={() => window.open(`https://assets.jmxcorretora.com.br/${item.documento}`, '_blank')}/>
                                                                            }
                                                                        </div>
                                                                        <div className="column-icon">
                                                                            <FontAwesomeIcon icon={faPen} onClick={() => {setModalAlterarDocumento(true); setDocumentoAtivo(item)}}/>
                                                                        </div>
                                                                        <div className="column-icon">
                                                                            <FontAwesomeIcon icon={faTrashCan} onClick={() => {setModalExcluirDocumento(true); setDocumentoAtivo(item)}}/>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                :
                                                    <div className="tabela-dados">
                                                        <div className="cabecalho-dados">
                                                            <div className="column-30">Descrição</div>
                                                            <div className="column-25">Observação</div>
                                                            <div className="column-15">Anexado por</div>
                                                            <div className="column-10">Privado</div>
                                                            <div className="column-15">Envio</div>
                                                        </div>
                                                        <div className="corpo-dados">
                                                            { documentos.documentos_complementares.filter(busca).length === 0 ? 
                                                                <p className='no-info'>Não foi anexado nenhum documento ainda.</p>
                                                            : 
                                                                documentos.documentos_complementares.filter(busca).map((item, index) => (
                                                                    <div className="row-dados" key={index} onClick={() => window.open(`https://assets.jmxcorretora.com.br/${item.documento}`, '_blank')}>
                                                                        <div className="column-30">
                                                                            {item.descricao}
                                                                        </div>
                                                                        <div className="column-25">
                                                                            {item.observacao  ? item.observacao : "--"}
                                                                        </div>
                                                                        <div className="column-15">
                                                                            {item.created_by.name}
                                                                        </div>
                                                                        <div className="column-10">
                                                                            {item.privado ? "Sim" : "Não"}
                                                                        </div>
                                                                        <div className="column-15">
                                                                            <Moment format='DD/MM/YYYY'>{item.created_at}</Moment>
                                                                        </div>
                                                                        <div className="column-icon">
                                                                            <FontAwesomeIcon icon={faPen} onClick={(e) => {e.stopPropagation(); setModalAlterarDocumento(true); setDocumentoAtivo(item)}}/>
                                                                        </div>
                                                                        <div className="column-icon">
                                                                            <FontAwesomeIcon icon={faTrashCan} onClick={(e) => {e.stopPropagation(); setModalExcluirDocumento(true); setDocumentoAtivo(item)}}/>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    case (abaAtiva === "agenda"):
                                        return(
                                            <div className="conteudo-agenda">
                                                <div className="opcoes-aba">
                                                    <button className="button-green" onClick={() => {setModalCadastrarAgenda(true)}}>Criar Agenda</button>
                                                </div>
                                                <div className="tabela-dados">
                                                    <div className="cabecalho-dados">
                                                        <div className="column-40">Usuário</div>
                                                        <div className="column-40">Assunto</div>
                                                        <div className="column-15">Data/Hora</div>
                                                    </div>
                                                    <div className="corpo-dados">
                                                        { agendas.length === 0 ? 
                                                            <div className="row-dados">
                                                                <div className="column-zerado">
                                                                    Não foi cadastrada nenhuma agenda ainda.
                                                                </div>
                                                            </div>
                                                            : (
                                                                (agendas.sort(ordemData).map((item, index) => (
                                                                    <div key={index} className="row-dados">
                                                                        <div className="column-40">
                                                                            {item.autor.name}
                                                                        </div>
                                                                        <div className="column-40">
                                                                            {item.assunto}
                                                                        </div>
                                                                        <div className="column-15">
                                                                            <Moment format="DD/MM/YYYY HH:mm">
                                                                                {item.data}
                                                                            </Moment>
                                                                        </div>
                                                                        <div className="column-icon">
                                                                            { item.autor._id === user._id ?
                                                                                <FontAwesomeIcon icon={faPen} onClick={() => console.log("editar")}/>
                                                                            : null }
                                                                        </div>
                                                                        { item.autor._id === user._id || user.permission === 1 ? 
                                                                            <div className="column-icon">
                                                                                <FontAwesomeIcon icon={faTrashCan} onClick={() => {setModalExcluirAgenda(true); setAgendaAtiva(item._id)}}/>
                                                                            </div>
                                                                        : null }
                                                                    </div>
                                                                )))
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    case (abaAtiva === "beneficiarios"):
                                        return(
                                            <div className='conteudo-beneficiarios'>
                                                <div className="opcoes-aba">
                                                    <button className="button-green" onClick={() => {setModalCadastrarBeneficiario(true)}}>Adicionar Beneficiário</button>
                                                </div>
                                                <div className="tabela-dados">
                                                    <div className="cabecalho-dados">
                                                        <div className="column-50">Nome</div>
                                                        <div className="column-30">Parentesco</div>
                                                        <div className="column-15">Valor</div>
                                                    </div>
                                                    <div className="corpo-dados">
                                                        { beneficiarios.length === 0 ? 
                                                            <div className="row-dados">
                                                                <div className="column-zerado">
                                                                    Não foi cadastrado nenhum beneficiário ainda.
                                                                </div>
                                                            </div>
                                                            : (
                                                                (beneficiarios.map((item, index) => (
                                                                    <Fragment key={index}>
                                                                        <div className="row-dados">
                                                                            <div className="column-50">
                                                                                {item.nome}
                                                                            </div>
                                                                            <div className="column-30">
                                                                                {item.parentesco}
                                                                            </div>
                                                                            <div className="column-15">
                                                                                <NumericFormat value={item.valor} displayType="text" decimalScale={2} decimalSeparator="," thousandSeparator="." prefix='R$' fixedDecimalScale renderText={(value) => <p>{value}</p>}/>
                                                                            </div>
                                                                            <div className="column-icon">
                                                                                <FontAwesomeIcon icon={faUserPen} onClick={() => {setModalEditarBeneficiario(true); getBeneficiarioAtivo(item._id)}}/>
                                                                            </div>
                                                                            <div className="column-icon">
                                                                                <FontAwesomeIcon icon={faTrashCan} onClick={() => {setModalExcluirBeneficiario(true); getBeneficiarioAtivo(item._id)}}/>
                                                                            </div>
                                                                        </div>
                                                                    </Fragment>
                                                                )))
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    default:
                                    break;
                                }
                            })()}
                        </div>
                    </div>
                </div>
            </main>
            :
                <Loading/>
            }
            {modalEditarSinistro ? (
                <Modal titulo="Editar Beneficiário" onClose={() => {setModalEditarSinistro(false)}}>
                    <form onSubmit={editSinistro}>
                        <div id="campos-novo-sinistro">
                            <div className="campo-form campo-30">
                                <label htmlFor="ramo-sinistro">Ramo</label>
                                <select name="ramo" id="ramo-sinistro" defaultValue={sinistro.ramo} disabled>
                                    <option value="Vida">Vida</option>
                                    <option value="Auto">Auto</option>
                                    <option value="Residêncial">Residêncial</option>
                                </select>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="tipo-sinistro">Tipo</label>
                                <select name="tipo" id="tipo-sinistro" value={tipo} onChange={(event) => setTipo(event.target.value)}>
                                    <option value="Sinistro">Sinistro</option>
                                    <option value="Assistência">Assistência</option>
                                </select>
                            </div>
                            <div className="campo-form campo-40">
                                <label htmlFor="motivo-sinistro">Motivo</label>
                                <select name="motivo" id="motivo-sinistro" defaultValue={sinistro.motivo}>
                                    {/* <option value="">Selecione uma opção</option> */}
                                    {(() => {
                                        switch (true) {
                                            case (sinistro.ramo === "Vida"):
                                                if (tipo === "Sinistro") {
                                                    return(
                                                        <Fragment>
                                                            <option value='Invalidez por Acidente'>Invalidez por Acidente</option>
                                                            <option value='Invalidez por Doença'>Invalidez por Doença</option>
                                                            <option value='Morte Conjuge'>Morte Conjuge</option>
                                                            <option value='Morte Filho'>Morte Filho</option>
                                                            <option value='Morte Titular'>Morte Titular</option>
                                                            <option value='Outros'>Outros</option>
                                                        </Fragment>
                                                    );
                                                } else if (tipo === "Assistência"){
                                                    return(
                                                        <Fragment>
                                                            <option value='Alimentação'>Alimentação</option>
                                                            <option value='Funeral'>Funeral</option>
                                                            <option value='Natalidade'>Natalidade</option>
                                                            <option value='Outros'>Outros</option>
                                                        </Fragment>
                                                    );
                                                };
                                                break;
                                            case (sinistro.ramo === "Auto"):
                                                if (tipo === "Sinistro") {
                                                    return(
                                                        <Fragment>
                                                            <option value='Colisão'>Colisão</option>
                                                            <option value='Furto e Roubo'>Furto e Roubo</option>
                                                            <option value='Outros'>Outros</option>
                                                        </Fragment>
                                                    );
                                                } else if (tipo === "Assistência"){
                                                    return(
                                                        <Fragment>
                                                            <option value='Guincho'>Guincho</option>
                                                            <option value='Pane Mecânica/Elétrica'>Pane Mecânica/Elétrica</option>
                                                            <option value='Veículo Reserva'>Veículo Reserva</option>
                                                            <option value='Vidros'>Vidros</option>
                                                            <option value='Outros'>Outros</option>
                                                        </Fragment>
                                                    );
                                                };
                                                break;
                                            case (sinistro.ramo === "Residêncial"):
                                                if (tipo === "Sinistro") {
                                                    return(
                                                        <Fragment>
                                                            <option value='Incêndio'>Incêndio</option>
                                                            <option value='Furto e Roubo'>Furto e Roubo</option>
                                                            <option value='Outros'>Outros</option>
                                                        </Fragment>
                                                    );
                                                } else if (tipo === "Assistência"){
                                                    return(
                                                        <Fragment>
                                                            <option value='Chaveiro'>Chaveiro</option>
                                                            <option value='Danos Elétricos'>Danos Elétricos</option>
                                                            <option value='Pagamento de Aluguel'>Pagamento de Aluguel</option>
                                                            <option value='Vidros'>Vidros</option>
                                                            <option value='Outros'>Outros</option>
                                                        </Fragment>
                                                    );
                                                };
                                                break;
                                            default:
                                            break;
                                        };
                                    })()}
                                    {/* {motivo?.map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                        // (() => {
                                        // })
                                        // <option key={index} value={item === "Selecione um ramo e um tipo" || item === "Selecione um ramo" || item === "Selecione um tipo" ? "default" : item }>{item}</option>
                                    ))} */}
                                </select>
                            </div>
                            <h4>Dados do Titular do Seguro</h4>
                            <div className="campo-form campo-25">
                                <label htmlFor='doc-titular'>Documento</label>
                                {/* <div className="radio-box">
                                    <input type="radio" name="doctitular" id="cpf-titular" defaultChecked={sinistro.titularDoc.length > 13} 
                                    // onChange={() => {setTitular('cpf')}}
                                    />
                                    <label htmlFor="cpf-titular">CPF</label>
                                    <input type="radio" name="doctitular" id="cnpj-titular" defaultChecked={sinistro.titularDoc.length === 13}
                                    // onChange={() => {setTitular('cnpj')}}
                                    />
                                    <label htmlFor="cnpj-titular">CNPJ</label>
                                </div> */}
                                { sinistro.titularDoc.length === 18 ? <input type="text" name="titularDoc" id="doc-titular" className="mask-cnpj" defaultValue={sinistro.titularDoc}/> : <input type="text" name="titularDoc" id="doc-titular" className="mask-cpf" defaultValue={sinistro.titularDoc}/> }
                                
                            </div>
                            <div className={ sinistro.titularDoc.length === 18 ? "campo-form campo-70" : "campo-form campo-40"}>
                                {/* { sinistro.titularDoc.length === 18 ? 
                                    <Fragment>
                                        <label htmlFor="nome-titular">Razão Social</label>
                                    </Fragment>
                                :
                                    <Fragment>
                                        <label htmlFor="nome-titular">Nome do titular</label>
                                    </Fragment>
                                } */}
                                <label htmlFor='nome-titular'>Titular do Seguro</label>
                                <input type="text" name="titularNome" id="nome-titular" defaultValue={sinistro.titularNome}/>
                            </div>
                            {sinistro.titularDoc.length === 18 ?
                                null
                            :
                                <div className="campo-form campo-35">
                                    <label htmlFor="empresa">Empresa</label>
                                    <select name='empresa' id='empresa' defaultValue={sinistro.empresa._id} required>
                                        {empresas.filter(({excluido}) => excluido === false).map((item, index) => (
                                            <option key={index} value={item._id}>{item.razao_social}</option>
                                        ))}
                                    </select>
                                </div>
                            }
                            { sinistro.ramo === "Vida" ? 
                                <Fragment>
                                    <h4>Dados do Sinistrado</h4>
                                    <div className="campo-form campo-25">
                                        <label htmlFor="cpf-sinistrado">CPF</label>
                                        <input type="text" name="sinistradoCpf" id="cpf-sinistrado" className='mask-cpf' defaultValue={sinistro.sinistradoCpf}/>
                                    </div>
                                    <div className="campo-form campo-50">
                                        <label htmlFor="nome-sinistrado">Nome do sinistrado</label>
                                        <input type="text" name="sinistradoNome" id="nome-sinistrado" defaultValue={sinistro.sinistradoNome}/>
                                    </div>
                                    <div className="campo-form campo-25">
                                        <label htmlFor="nasc-sinistrado">Data de Nasc.</label>
                                        <input type="date" name="sinistradoNasc" id="nasc-sinistrado" min='1910-01-01' max='2020-01-01' defaultValue={moment(sinistro.sinistradoNasc).format("YYYY-MM-DD")}/>
                                    </div>
                                </Fragment>
                            : null }
                            { sinistro.ramo === "Auto" ? 
                                <Fragment>
                                    <h4>Dados do Veículo</h4>
                                    <div className="campo-form campo-15">
                                        <label htmlFor="placas-sinistrado">Placas</label>
                                        <input type="text" name="sinistradoPlacas" id="placas-sinistrado" defaultValue={sinistro.sinistradoPlacas}/>
                                    </div>
                                    <div className="campo-form campo-25">
                                        <label htmlFor="renavam-sinistrado">Renavam</label>
                                        <input type="text" name="sinistradoRenavam" id="renavam-sinistrado" defaultValue={sinistro.sinistradoRenavam}/>
                                    </div>
                                    <div className="campo-form campo-20">
                                        <label htmlFor="marca-sinistrado">Marca</label>
                                        <input type="text" name="sinistradoMarca" id="marca-sinistrado" defaultValue={sinistro.sinistradoMarca}/>
                                    </div>
                                    <div className="campo-form campo-25">
                                        <label htmlFor="modelo-sinistrado">Modelo</label>
                                        <input type="text" name="sinistradoModelo" id="modelo-sinistrado" defaultValue={sinistro.sinistradoModelo}/>
                                    </div>
                                    <div className="campo-form campo-15">
                                        <label htmlFor="ano-sinistrado">Ano/Fab</label>
                                        <input type="text" name="sinistradoAnoFab" id="ano-sinistrado" defaultValue={sinistro.sinistradoAnoFab}/>
                                    </div>
                                </Fragment>
                            : null }
                            { sinistro.ramo === "Residêncial" ? 
                                <Fragment>
                                    <h4>Dados da Residência</h4>
                                    <div className="campo-form campo-20">
                                        <label htmlFor="cep-sinistro">CEP</label>
                                        <input type="text" name="sinistradoCep" id="cep-sinistro" className="mask-cep" defaultValue={sinistro.sinistradoCep}/>
                                    </div>
                                    <div className="campo-form campo-60">
                                        <label htmlFor="logradouro-sinistro">Logradouro</label>
                                        <input type="text" name="sinistradoEndereco" id="logradouro-sinistro" defaultValue={sinistro.sinistradoEndereco}/>
                                    </div>
                                    <div className="campo-form campo-20">
                                        <label htmlFor="numero-sinistro">Número</label>
                                        <input type="text" name="sinistradoNumero" id="numero-sinistro" defaultValue={sinistro.sinistradoNumero}/>
                                    </div>
                                    <div className="campo-form campo-25">
                                        <label htmlFor="complemento-sinistro">Complemento</label>
                                        <input type="text" name="sinistradoComplemento" id="complemento-sinistro" defaultValue={sinistro.sinistradoComplemento}/>
                                    </div>
                                    <div className="campo-form campo-25">
                                        <label htmlFor="bairro-sinistro">Bairro</label>
                                        <input type="text" name="sinistradoBairro" id="bairro-sinistro" defaultValue={sinistro.sinistradoBairro}/>
                                    </div>
                                    <div className="campo-form campo-25">
                                        <label htmlFor="cidade-sinistro">Cidade</label>
                                        <input type="text" name="sinistradoCidade" id="cidade-sinistro" defaultValue={sinistro.sinistradoCidade}/>
                                    </div>
                                    <div className="campo-form campo-25">
                                        <label htmlFor="estado-sinistro">Estado</label>
                                        <select name="sinistradoEstado" id="estado-sinistro" defaultValue={sinistro.sinistradoEstado}>
                                            <option value="default">Selecione</option>
                                            <option value="AC">Acre</option>
                                            <option value="AL">Alagoas</option>
                                            <option value="AM">Amazonas</option>
                                            <option value="AP">Amapá</option>
                                            <option value="BA">Bahia</option>
                                            <option value="CE">Ceará</option>
                                            <option value="DF">Distrito Federal</option>
                                            <option value="ES">Espirito Santo</option>
                                            <option value="GO">Goiás</option>
                                            <option value="MA">Maranhão</option>
                                            <option value="MT">Mato Grosso</option>
                                            <option value="MS">Mato Grosso do Sul</option>
                                            <option value="MG">Minas Gerais</option>
                                            <option value="PA">Pará</option>
                                            <option value="PB">Paraíba</option>
                                            <option value="PR">Paraná</option>
                                            <option value="PE">Pernambuco</option>
                                            <option value="PI">Piauí</option>
                                            <option value="RJ">Rio de Janeiro</option>
                                            <option value="RN">Rio Grande do Norte</option>
                                            <option value="RS">Rio Grande do Sul</option>
                                            <option value="SC">Santa Catarina</option>
                                            <option value="SP">São Paulo</option>
                                            <option value="SE">Sergipe</option>
                                            <option value="RO">Rondônia</option>
                                            <option value="RR">Roraima</option>
                                            <option value="TO">Tocantins</option>
                                        </select>
                                    </div>
                                </Fragment>
                            : null }
                            <h4>Dados do Seguro</h4>
                            <div className="campo-form campo-40">
                                <label htmlFor="seguradora-sinistro">Seguradora</label>
                                <select name="seguradora" id="seguradora-sinistro" defaultValue={sinistro.seguradora._id} required>
                                    {seguradoras?.map((item, index) => (
                                        <option key={index} value={item._id}>{item.fantasia}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="campo-form campo-35">
                                <label htmlFor="apolice-sinistro">Apólice</label>
                                <input type="text" name="apolice" id="apolice" defaultValue={sinistro.apolice}/>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="data-ocorrencia">Data de Ocor.</label>
                                <input type="date" name="dataSinistro" id="data-ocorrencia" defaultValue={moment(sinistro.dataSinistro).format("YYYY-MM-DD")}/>
                            </div>
                            <div className="campo-form campo-40">
                                <label htmlFor="corretor-sinistro">Corretor</label>
                                <select name="corretor" id="corretor-sinistro" defaultValue={sinistro.corretor._id} required>
                                    {corretor?.map((item, index) => (
                                        <option key={index} value={item._id}>{item.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="protocolo-sinistro">Prot. de Abertura</label>
                                <input type="text" name="protocolo" id="protocolo-sinistro" defaultValue={sinistro.protocolo}/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="n-sinistro">N° de sinistro</label>
                                <input type="text" name="nSinistro" id="n-sinistro" defaultValue={sinistro.nSinistro}/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={(() => {setModalEditarSinistro(false)})}>Cancelar</button>
                            <button type="submit" className="button-green">Salvar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalAlterarStatus ? (
                <Modal titulo="Alterar Status" width="35%" onClose={() => {setModalAlterarStatus(false)}}>
                    <form onSubmit={alterarStatus}>
                        <div id="campos-alterar-status">
                            <div className="campo-form campo-100">
                                <label htmlFor="situacao">Situação</label>
                                <select name="situacao" id="situacao" required defaultValue="">
                                    <option value="">Selecione</option>
                                    {sinistro.status.at(-1).situacao !== "Juntando Documentação" ? <option>Juntando Documentação</option> : null}
                                    {sinistro.status.at(-1).situacao !== "Aguardando Análise da Seguradora" ? <option>Aguardando Análise da Seguradora</option> : null}
                                    {sinistro.status.at(-1).situacao !== "Juntando Documentação Complementar" ? <option>Juntando Documentação Complementar</option> : null}
                                    {sinistro.status.at(-1).situacao !== "Aguardando Pagamento" ? <option>Aguardando Pagamento</option> : null}
                                    {sinistro.status.at(-1).situacao !== "Pagamento Recusado" ? <option>Pagamento Recusado</option> : null}
                                    {sinistro.status.at(-1).situacao !== "Finalizado" ? <option>Finalizado</option> : null}
                                </select>
                            </div>
                            <div className='campo-form campo-50'>
                                <label htmlFor='data'>Data</label>
                                <input type='date' name='data' id='data' required/>
                            </div>
                            <div className='campo-form campo-50'>
                                <label htmlFor='horario'>Horário</label>
                                <input type='time' name='horario' id='horario' required/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalAlterarStatus(false)}}>Cancelar</button>
                            {aguardandoRetorno ? <button type="button" className="button-green">Aguarde<FontAwesomeIcon icon={faSpinner} spinPulse style={{marginLeft: ".5em"}}/></button> : <button type="submit" className="button-green">Alterar</button>}
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalExcluirSinistro ? (
                <ModalDangerSmall mensagem="Deseja realmente excluir esse sinistro?" onClose={() => {setModalExcluirSinistro(false)}}>
                    <div className='buttons-form'>
                        <button type="reset" className="button-red" onClick={() => {setModalExcluirSinistro(false)}}>Cancelar</button>
                        <button type="submit" className="button-blue" onClick={() => {desativarSinistro(sinistro._id)}}>Excluir</button>
                    </div>
                </ModalDangerSmall>
            ): null}
            {modalCadastrarHistorico ? (
                <Modal titulo="Novo Histórico" onClose={() => {setModalCadastrarHistorico(false)}}>
                    <form onSubmit={createHistorico}>
                        <div id="campos-nova-agenda">
                            <div className="campo-form campo-55">
                                <label htmlFor="assunto">Assunto</label>
                                <input type="text" name="assunto" id="assunto" required/>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="data">Data</label>
                                <input type="date" name="data" id="data" required/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="horario">Horário</label>
                                <input type="time" name="horario" id="horario" required/>
                            </div>
                            <div className={ naoSucesso ? "campo-form campo-30" : "campo-form campo-80"}>
                                <label htmlFor="sucesso">Obteve sucesso?</label>
                                <select name='sucesso' defaultValue="" required onChange={(e) => { e.target.value === "false" ? setNaoSucesso(true) : setNaoSucesso(false)}}>
                                    <option value="" disabled>Selecione</option>
                                    <option value={true}>Sim</option>
                                    <option value={false}>Não</option>
                                </select>
                            </div>
                            { naoSucesso ? (<div className="campo-form campo-50">
                                <label htmlFor="motivo_nao_sucesso">Motivo</label>
                                <input type="text" name="motivo_nao_sucesso" id="motivo-nao-sucesso" required/>
                            </div>) : null}
                            <div className="campo-form campo-20">
                                <label htmlFor="privado">Privado?</label>
                                <select name='privado' defaultValue="" required>
                                    <option value="" disabled>Selecione</option>
                                    <option value={true}>Sim</option>
                                    <option value={false}>Não</option>
                                </select>
                            </div>
                            <div className="campo-form campo-100">
                                <label htmlFor="descricao">Descrição</label>
                                <textarea type="text" name="descricao" id="descricao" rows="5" required/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalCadastrarHistorico(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Cadastrar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalExcluirHistorico ? (
                <ModalDangerSmall mensagem={"Deseja excluir esse histórico definitivamente?"} onClose={() => {setModalExcluirHistorico(false)}}>
                    <div className='buttons-form'>
                        <button type="reset" className="button-red" onClick={() => {setModalExcluirHistorico(false)}}>Cancelar</button>
                        <button type="submit" className="button-blue" onClick={() => {deleteHistorico()}}>Excluir</button>
                    </div>
                </ModalDangerSmall>
            ): null}
            {modalAdicionarDocumento ? (
                <Modal titulo="Adicionar Documento" onClose={() => {setModalAdicionarDocumento(false); setFileName('')}}>
                    <form onSubmit={addDocumento}>
                        <div id="campos-novo-documento">
                            <div className="campo-form campo-75">
                                <label htmlFor="descricao">Descrição</label>
                                <input type="text" name="descricao" id="descricao" required/>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="privado">Privado</label>
                                <select name="privado" id="privado" defaultValue="" required>
                                    <option value="" disabled>Selecione</option>
                                    <option value="false">Não</option>
                                    <option value="true">Sim</option>
                                </select>
                            </div>
                            <div className="campo-form campo-100">
                                <label htmlFor="observacao">Observação</label>
                                <textarea type="text" name="observacao" id="observacao" rows="2"/>
                            </div>
                            <div className="campo-form campo-100">
                                <input type="file" name="documento" id="documento" onChange={getNameFile} style={{display: 'none'}}/>
                                <label className='file-upload-label' htmlFor="documento">
                                    <FontAwesomeIcon icon={faCloudArrowUp}/>
                                    {fileName === "" ? "Selecione um documento" : fileName}
                                </label>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalAdicionarDocumento(false); setFileName('')}}>Cancelar</button>
                            <button type="submit" className="button-green">Adicionar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalSolicitarDocumento ? (
                <Modal titulo="Solicitar Documento" onClose={() => {setModalSolicitarDocumento(false);}}>
                    <form onSubmit={solicitarDocumento}>
                        <div id="campos-solicitar-documento">
                            <div className="campo-form campo-100">
                                <label htmlFor="descricao">Descrição</label>
                                <input type="text" name="descricao" id="descricao" required/>
                            </div>
                            <div className="campo-form campo-100">
                                <label htmlFor="observacao">Observação</label>
                                <textarea type="text" name="observacao" id="observacao" rows="2"/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalSolicitarDocumento(false);}}>Cancelar</button>
                            <button type="submit" className="button-green">Solicitar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalAlterarDocumento ? (
                <Modal titulo="Alterar Documento" onClose={() => {setModalAlterarDocumento(false); setFileName("")}}>
                    <form onSubmit={alterarDocumento}>
                        <div id="campos-solicitar-documento">
                            <div className={`campo-form ${filtroSelecionado === "anexados" ? "campo-75" : "campo-100"}`}>
                                <label htmlFor="descricao">Descrição</label>
                                <input type="text" name="descricao" id="descricao" required defaultValue={documentoAtivo.descricao}/>
                            </div>
                            {filtroSelecionado === "anexados" ?
                                <div className="campo-form campo-25">
                                    <label htmlFor="privado">Privado</label>
                                    <select name="privado" id="privado" defaultValue={documentoAtivo.privado} required>
                                        <option value="false">Não</option>
                                        <option value="true">Sim</option>
                                    </select>
                                </div>
                            :
                                null
                            }
                            <div className="campo-form campo-100">
                                <label htmlFor="observacao">Observação</label>
                                <textarea type="text" name="observacao" id="observacao" rows="2" defaultValue={documentoAtivo.observacao}/>
                            </div>
                            { filtroSelecionado === "anexados" ?
                                <div className="campo-form campo-100">
                                    <input type="file" name="documento" id="documento" onChange={getNameFile} style={{display: 'none'}}/>
                                    <label className='file-upload-label' htmlFor="documento">
                                        <FontAwesomeIcon icon={faCloudArrowUp}/>
                                        {fileName === "" ? "Alterar o documento atual" : fileName}
                                    </label>
                                </div>
                            :
                                null
                            }
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalAlterarDocumento(false); setFileName("")}}>Cancelar</button>
                            <button type="submit" className="button-green">Alterar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalAnexarDocumento ? (
                <Modal titulo="Anexar Documento" onClose={() => {setModalAnexarDocumento(false); setFileName("")}}>
                    <form onSubmit={anexarDocumento}>
                        <div id="campos-solicitar-documento">
                            <div className="campo-form campo-100">
                                <input type="file" name="documento" id="documento" onChange={getNameFile} style={{display: 'none'}}/>
                                <label className='file-upload-label' htmlFor="documento">
                                    <FontAwesomeIcon icon={faCloudArrowUp}/>
                                    {fileName === "" ? "Selecione um documento" : fileName}
                                </label>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalAnexarDocumento(false); setFileName("")}}>Cancelar</button>
                            <button type="submit" className="button-green">Anexar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalAlterarStatusDocumento ? (
                <Modal titulo="Alterar status do documento" width={"30%"} onClose={() => {setModalAlterarStatusDocumento(false);}}>
                    <form onSubmit={alterarStatusDocumento}>
                        <div id="campos-solicitar-documento">
                            <div className="campo-form campo-100">
                                <label htmlFor="descricao">Descrição</label>
                                <select id='situacao' name='situacao' defaultValue="" onChange={(e) => e.target.value === "Documento Incorreto" ? setStatusIncorreto(true) : setStatusIncorreto(false)} required>
                                    <option value="" disabled>Selecione</option>
                                    <option value="Documento Incorreto">Documento Incorreto</option>
                                    {documentoAtivo.status.at(-1).situacao === "Documento Verificado" ? null : <option value="Documento Verificado">Documento Verificado</option>}
                                    {documentoAtivo.status.at(-1).situacao === "Enviado para seguradora" ? null : <option value="Enviado para seguradora">Enviado para seguradora</option>}
                                </select>
                            </div>
                            { statusIncorreto ? 
                                <div className="campo-form campo-100">
                                    <label htmlFor="justificativa">Justificativa</label>
                                    <input type='text' name='justificativa' id='justificativa' required/>
                                </div>
                            :
                                null
                            }
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalAlterarStatusDocumento(false);}}>Cancelar</button>
                            {aguardandoRetorno ? <button type="button" className="button-green">Aguarde<FontAwesomeIcon icon={faSpinner} spinPulse style={{marginLeft: ".5em"}}/></button> : <button type="submit" className="button-green">Alterar</button>}
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalExcluirDocumento ? (
                <ModalDangerSmall mensagem={`Deseja excluir ${filtroSelecionado === "solicitados" ? "a solicitação desse documento" : "esse documento definitivamente"}?`} onClose={() => {setModalExcluirDocumento(false)}}>
                    <div className='buttons-form'>
                        <button type="reset" className="button-red" onClick={() => {setModalExcluirDocumento(false)}}>Cancelar</button>
                        <button type="submit" className="button-blue" onClick={() => {deleteDocumento()}}>Excluir</button>
                    </div>
                </ModalDangerSmall>
            ): null}
            {modalCadastrarAgenda ? (
                <Modal titulo="Nova Agenda" onClose={() => {setModalCadastrarAgenda(false)}}>
                    <form onSubmit={createAgenda}>
                        <div id="campos-nova-agenda">
                            <div className="campo-form campo-55">
                                <label htmlFor="assunto">Assunto</label>
                                <input type="text" name="assunto" id="assunto" required/>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="data">Data</label>
                                <input type="date" name="data" id="data" required/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="horario">Horário</label>
                                <input type="time" name="horario" id="horario" required/>
                            </div>
                            <div className="campo-form campo-100">
                                <label htmlFor="descricao">Descrição</label>
                                <textarea type="text" name="descricao" id="descricao" rows="5" required=""/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalCadastrarAgenda(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Cadastrar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalExcluirAgenda ? (
                <ModalDangerSmall mensagem={"Deseja excluir essa agenda definitivamente?"} onClose={() => {setModalExcluirAgenda(false)}}>
                    <div className='buttons-form'>
                        <button type="reset" className="button-red" onClick={() => {setModalExcluirAgenda(false)}}>Cancelar</button>
                        <button type="submit" className="button-blue" onClick={() => {deleteAgenda()}}>Excluir</button>
                    </div>
                </ModalDangerSmall>
            ): null}
            {modalCadastrarBeneficiario ? (
                <Modal titulo="Novo Beneficiário" onClose={() => {setModalCadastrarBeneficiario(false)}}>
                    <form onSubmit={createBeneficiario}>
                        <div id="campos-novo-beneficiario">
                            <div className="campo-form campo-50">
                                <label htmlFor="nome">Nome</label>
                                <input type="text" name="nome" id="nome" required/>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="parentesco">Parentesco</label>
                                <select name="parentesco" id="parentesco" defaultValue="" required>
                                    <option value="" disabled>Selecione</option>
                                    <option value="Cônjuge">Cônjuge</option>
                                    <option value="Filho(a)">Filho(a)</option>
                                    <option value="Irmão(ã)">Irmão(ã)</option>
                                    <option value="Mãe">Mãe</option>
                                    <option value="Pai">Pai</option>
                                    <option value="Outros">Outros</option>
                                </select>
                                {/* <input type="text" name="titulo" id="titulo" required/> */}
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="valor">Valor a receber</label>
                                <NumericFormat id='valor' name='valor' decimalScale={2} decimalSeparator="," thousandSeparator="." prefix='R$' fixedDecimalScale required/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalCadastrarBeneficiario(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Cadastrar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalEditarBeneficiario ? (
                <Modal titulo="Editar Beneficiário" onClose={() => {setModalEditarBeneficiario(false)}}>
                    <form onSubmit={editBeneficiario}>
                        <div id="campos-novo-beneficiario">
                            <div className="campo-form campo-50">
                                <label htmlFor="nome">Nome</label>
                                <input type="text" name="nome" id="nome" defaultValue={beneficiarioAtivo.nome} required/>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="parentesco">Parentesco</label>
                                <select name="parentesco" id="parentesco" defaultValue={beneficiarioAtivo.parentesco}>
                                    <option value="Cônjuge">Cônjuge</option>
                                    <option value="Filho(a)">Filho(a)</option>
                                    <option value="Irmão(ã)">Irmão(ã)</option>
                                    <option value="Mãe">Mãe</option>
                                    <option value="Pai">Pai</option>
                                    <option value="Outros">Outros</option>
                                </select>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="valor">Valor a receber</label>
                                <NumericFormat id='valor' name='valor' decimalScale={2} decimalSeparator="," thousandSeparator="." prefix='R$' fixedDecimalScale value={beneficiarioAtivo.valor} required/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalEditarBeneficiario(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Salvar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalExcluirBeneficiario ? (
                <ModalDangerSmall mensagem={["Deseja excluir o beneficiário", <br/>, <span>{beneficiarioAtivo.nome.toUpperCase()}</span>,"?"]} onClose={() => {setModalExcluirBeneficiario(false)}}>
                    <div className='buttons-form'>
                        <button type="reset" className="button-red" onClick={() => {setModalExcluirBeneficiario(false)}}>Cancelar</button>
                        <button type="submit" className="button-blue" onClick={() => {deleteBeneficiario(beneficiarioAtivo._id)}}>Excluir</button>
                    </div>
                </ModalDangerSmall>
            ): null}
            { notifications.length !== 0 ?
                <div className='area-notification'>
                    {notifications.map((item, index) =>(
                        <Notification tipo={item.tipo} titulo={item.titulo} mensagem={item.mensagem} onClose={() => removeNotification(item.id)} key={index}/>
                    ))}
                </div>
            : null }
        </Fragment>
    )
};