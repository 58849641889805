import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandHoldingHeart, faBriefcase, faHourglassHalf } from '@fortawesome/free-solid-svg-icons'
import "../../styles/corretora.scss";
import "../../styles/reset.scss";
import UsersService from '../../services/users';
import SinistrosServices from '../../services/sinistros';
import HistoricosService from '../../services/historicos';

export default function ContentCorretora() {
    const [userLogado, setUserLogado] = useState([]);
    const [sinistrosAbertos, setSinistrosAbertos] = useState(0);
    const [beneficiariosContemplados, setBeneficiariosContemplados] = useState(0);
    const [sinistrosPagos, setSinistrosPagos] = useState(0);
    const [valoresPagos, setValoresPagos] = useState(0);
    const [historicosFeitos, setHistoricosFeitos] = useState(0);
    const dataAtual = new Date();

    async function getResponses() {
        const response_sinistros = await SinistrosServices.index();
        getSinistrosAbertos(response_sinistros.data);
        getBeneficiarios(response_sinistros.data);
        getSinistrosPagos(response_sinistros.data);
        getValoresPagos(response_sinistros.data);
        const response_historicos = await HistoricosService.historicos_sinistros();
        getHistoricos(response_historicos.data);
    }

    async function getUserLogado() {
        const res = await UsersService.logado();
        setUserLogado(res.data);
    }

    async function getSinistrosAbertos(sinistrosCarregados) {
        var countSinistrosAbertos = 0;
        // eslint-disable-next-line
        await sinistrosCarregados.map((item) => {
            const cadastroMonth = new Date(item.cadastro).getMonth();
            const mesAtual = dataAtual.getMonth();

            if (cadastroMonth === mesAtual) {
                countSinistrosAbertos++;
                setSinistrosAbertos(countSinistrosAbertos)
            }
        })
    }

    async function getBeneficiarios(sinistrosCarregados) {
        var countBeneficiariosContemplados = 0;
        // eslint-disable-next-line
        await sinistrosCarregados.filter(({ status }) => status[status.length - 1].situacao === "Finalizado").map((item) => {
            // eslint-disable-next-line
            item.beneficiarios.map(() => {
                countBeneficiariosContemplados++;
                setBeneficiariosContemplados(countBeneficiariosContemplados);
            })
        })
    }

    async function getSinistrosPagos(sinistrosCarregados) {
        var countSinistrosPagos = 0;
        // eslint-disable-next-line
        await sinistrosCarregados.filter(({ status }) => status[status.length - 1].situacao === "Finalizado").map((item) => {
            // eslint-disable-next-line
            item.status.map((itemStatus) => {
                if (itemStatus.situacao === "Finalizado") {
                    const pagoMonth = new Date(itemStatus.dataAlteracao).getMonth();
                    const mesAtual = dataAtual.getMonth();
                    
                    if (pagoMonth === mesAtual) {
                        countSinistrosPagos++;
                        setSinistrosPagos(countSinistrosPagos)
                    }
                }
            })
        })
    }

    async function getValoresPagos(sinistrosCarregados) {
        var countValoresPagos = 0;
        // eslint-disable-next-line
        await sinistrosCarregados.filter(({ status }) => status[status.length - 1].situacao === "Finalizado").map((item) => {
            // eslint-disable-next-line
            item.beneficiarios.map((beneficiarios) => {
                
                const val = parseFloat(beneficiarios.valor.replace(',','.'));
                countValoresPagos = countValoresPagos + val;
                setValoresPagos(countValoresPagos);
            })
        })
    }

    async function getHistoricos(historicosCarregados) {
        var countHistoricos = 0;
        
        // eslint-disable-next-line
        await historicosCarregados.map(() => {
            countHistoricos++;
            setHistoricosFeitos(countHistoricos);
        })
    }

    useEffect(() => {
        getResponses();
        getUserLogado();
        // eslint-disable-next-line
    }, []);

    document.title = 'Corretora - JMX Corretora de Seguros';

    return(
        <Fragment>
            <main id='main-corretora'>
                <div className='areas-corretora'>
                    <Link to="/corretora/sinistros">
                        <div className="list-itens">
                            <FontAwesomeIcon icon={faHandHoldingHeart} />
                            <h3>Sinistros</h3>
                        </div>
                    </Link>
                    <Link to={ userLogado.permission !== 7 ? "/corretora/clientes" : null}>
                        <div className="list-itens">
                            <FontAwesomeIcon icon={faBriefcase} />
                            <h3>Clientes</h3>
                        </div>
                    </Link>
                    <Link to="/corretora/historicos">
                        <div className="list-itens">
                            <FontAwesomeIcon icon={faHourglassHalf} />
                            <h3>Históricos</h3>
                        </div>
                    </Link>
                </div>
                <div className='estatisticas-corretora'>
                    <h3>Estatísticas</h3>
                    <div className='area-estatisticas'>
                        <div className='box-estatistica'>
                            <h4>{sinistrosAbertos}</h4>
                            <p>Sinistros abertos esse mês</p>
                        </div>
                        <div className='box-estatistica'>
                            <h4>{beneficiariosContemplados}</h4>
                            <p>Beneficiários contemplados</p>
                        </div>
                        <div className='box-estatistica'>
                            <h4>{sinistrosPagos}</h4>
                            <p>Sinistros pagos esse mês</p>
                        </div>
                        <div className='box-estatistica'>
                            <h4>R${valoresPagos.toLocaleString('pt-br', {minimumFractionDigits: 2})}</h4>
                            <p>Em indenização paga</p>
                        </div>
                        <div className='box-estatistica'>
                            <h4>--</h4>
                            <p>Clientes novos</p>
                        </div>
                        <div className='box-estatistica'>
                            <h4>{historicosFeitos}</h4>
                            <p>Históricos feitos</p>
                        </div>
                    </div>
                </div>
                {/* <div className="areas-gestao aba-sinistros">
                    <h3>Sinistros</h3>
                    <FontAwesomeIcon icon={faHandHoldingHeart}/>
                </div>
                <div className="areas-gestao aba-vidas">
                    {/* <div className='imagem-area-gestao'>
                        <img src={imagemClientes}/>
                    </div>
                    <h3>Clientes</h3>
                    <FontAwesomeIcon icon={faBriefcase}/>
                </div>
                <div className="areas-gestao aba-historicos">
                    <h3>Históricos</h3>
                    <FontAwesomeIcon icon={faHourglassHalf}/>
                </div> */}
            </main>
        </Fragment>
    )
};