import Api from './api';

const TestesService = {
    index: () => Api.get('/apolices'),
    create: (params) => Api.post('/testes', params, {headers: { 'Content-Type': `multipart/form-data; boundary=${FormData._boundary}`}}),
    // edit: (id, params) => Api.put(`/apolices/${id}`, params),
    // desativar: (id) => Api.put(`/apolices/${id}/desabilitar`),
    // ativar: (id) => Api.put(`/apolices/${id}/habilitar`),
    // delete: (id) => Api.delete(`/apolices/${id}`)
}

export default TestesService;