import { Fragment } from 'react';
import Header from '../../components/header/index';
import ContentAdministracaoSeguradoras from '../../components/administracao/seguradoras';

const SeguradorasScreen = (props) => (
  <Fragment>
    <Header ativo={props.ativo}/>
    <ContentAdministracaoSeguradoras/>
  </Fragment>
)

export default SeguradorasScreen;