import React from "react";
import { Navigate, Outlet } from 'react-router-dom';
import { useJwt } from "react-jwt";
const token = localStorage.getItem('token');

const PrivateRoute = () => {
    const { isExpired } = useJwt(token);

    return(
        isExpired
        ? <Navigate to={{ pathname: '/login' }} />
        : <Outlet />
    )
}

export default PrivateRoute;