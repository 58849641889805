import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
// import Moment from 'react-moment';
// import 'moment-timezone';
import "../../../styles/emails.scss";
import "../../../styles/reset.scss";
// import Modal from "../../layouts/modal";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import EmailsService from '../../../services/email';
import UsersService from '../../../services/users';

export default function ContentAdministracaoNotificacoes() {
    const navigate = useNavigate();
    const [textoEmail, setTextoEmail] = useState('');
    const [grupo, setGrupo] = useState("");
    const [destinatarios, setDestinatarios] = useState("");
    const [usuarios, setUsuarios] = useState();
    const [complementarAtivo, setComplementarAtivo] = useState(false);

    const regexTags = /(<([^>]+)>)/gi;
    // const [apolices, setApolices] = useState([]);
    // const [modalCreateApolice, setModalCreateApolice] = useState(false);

    var modules = { toolbar: [
        [{ 'font': [] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'align': [] }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'super' }],
        ['blockquote'],
        ['clean']                                         
    ]};

    useEffect(() => {
        getUsuarios();
    },[])

    async function getUsuarios() {
        const data = await UsersService.index();
        setUsuarios(data.data.filter(({ excluido }) => excluido === false))
    }

    function definirGrupo(event) {
        switch (true) {
            case (event.target.value === "todos" || event.target.value === "default"):
                setComplementarAtivo(false);
                setGrupo(event.target.value);
            break;
            case (event.target.value === "empresa" || event.target.value === "todos-exceto" || event.target.value === "categoria" || event.target.value === "usuario"):
                setComplementarAtivo(true);
                setGrupo(event.target.value);
            break;
            default:
                setComplementarAtivo(false);
            break;
        }
    }
    
    async function enviarEmail(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData);
        var dadosEmail = {};
        dadosEmail.assunto = data.assunto;
        // data.push(Object.fromEntries(formData));
        
        try {
            const substituirBr = textoEmail.replaceAll("<br>", "\n");
            const textoSemHTML = substituirBr.replaceAll(regexTags, "");

                switch (true) {
                    case (grupo === "todos"):
                        const todos = []
                        usuarios.map((item) => (
                            todos.push(item.email)
                        ));
                        setDestinatarios(todos)
                    break;
                    case (grupo === "empresa"):
                        usuarios.map((item) => (
                            console.log(item.email)
                        ))
                    break;
                    case (grupo === "categoria"):
                        const categoria = [];
                        const categoriaSelect = parseInt(document.querySelector("#categoria").value);
                        usuarios.filter(({ permission }) => permission === categoriaSelect).map((item) => (
                            categoria.push(item.email)
                        ));
                        setDestinatarios(categoria);
                    break;
                    case (grupo === "usuario"):
                        const usuarioEnvio = []
                        const filtro = usuarios.filter(({ _id }) => _id === document.querySelector("#usuario").value)[0].email;
                        usuarioEnvio.push(filtro);
                        setDestinatarios(usuarioEnvio);
                    break;
                    case (grupo === "todos-exceto"):
                        let todosExceto = [];
        
                        usuarios.map((item) => (
                            todosExceto.push(item.email)
                        ));
        
                        const filtroSelected = usuarios.filter(({ _id }) => _id === document.querySelector("#todos-exceto").value)[0].email
                        const itemLocal = todosExceto.indexOf(filtroSelected)
                        todosExceto.splice(itemLocal, 1)
        
                        setDestinatarios(todosExceto);
                    break;
                    default:
                    break;
                }

            dadosEmail.destinatarios = destinatarios.join(", ");
            dadosEmail.conteudo = textoEmail;
            console.log(dadosEmail);

            // function inserirVirgula(item) {
            //     return item.join("");
            // }

            // console.log(destinatarios.join(", "))

            await EmailsService.enviar(dadosEmail);
            // setModalCadastrarBeneficiarioIsOpen(false);
            // getBeneficiarios();
        } catch (error) {
            console.log(error)
        }
    }

    document.title = `Emails - JMX Corretora de Seguros`;

    return(
        <Fragment>
            <main id='main-emails'>
                <div className="conteudo-emails">
                    <div className="cabecalho-conteudo">
                        <h2>Notificações</h2>
                        <div className="buttons-conteudo">
                            <button className="button-red" onClick={()=> navigate(`/administracao`)}>Voltar</button>
                            <button className="button-blue" onClick={() => {window.print()}}>Imprimir</button>
                            {/* <button className="button-green" onClick={() => setModalCreateApolice(true)}>Nova apólice</button> */}
                        </div>
                    </div>
                    <form onSubmit={enviarEmail}>
                        <div id="campos-novo-email">
                            <div className={ complementarAtivo ? "campo-form campo-40" : "campo-form campo-100"}>
                                <label htmlFor='grupos'>Enviar para:</label>
                                <select id='grupos' name='grupos' onChange={(e) => definirGrupo(e)}>
                                    <option value="default">Selecione</option>
                                    <option value="todos">Todos os Usuários</option>
                                    <option value="empresa">Por Empresa</option>
                                    <option value="categoria">Usuários por Função</option>
                                    <option value="usuario">Usuário</option>
                                    <option value="todos-exceto">Todos, exceto...</option>
                                </select>
                            </div>
                            {complementarAtivo ? 
                            <div className='campo-form campo-60'>
                                <label>Opções complementares:</label>
                                { grupo === "default" ?
                                    null
                                :
                                    (() => {
                                        switch (true) {
                                            case (grupo === "todos"):
                                                return(
                                                    <input type='text' name=''/>
                                                );
                                            case (grupo === "empresa"):
                                                return(
                                                    <select>
                                                        <option>Empresa</option>
                                                    </select>
                                                );
                                            case (grupo === "categoria"):
                                                return(
                                                    <select name='categoria' id='categoria'>
                                                        <option value="default">Selecione</option>
                                                        <option value="1">Desenvolvedor</option>
                                                        <option value="2">Sócio Proprietário</option>
                                                        <option value="3">Corretor</option>
                                                        <option value="4">Auxiliar Administrativo</option>
                                                        <option value="5">Cliente</option>
                                                        <option value="6">Beta Tester</option>
                                                        <option value="7">Visualizador</option>
                                                    </select>
                                                );
                                            case (grupo === "usuario"):
                                                return(
                                                    <select name='usuario' id="usuario">
                                                        <option value="default">Selecione</option>
                                                        {usuarios?.map((item, index) => (
                                                            <option key={index} value={item._id}>{item.name}</option>
                                                        ))}
                                                    </select>
                                                );
                                            case (grupo === "todos-exceto"):
                                                return(
                                                    <select name='todos-exceto' id='todos-exceto'>
                                                        <option value="default">Selecione</option>
                                                        {usuarios?.map((item, index) => (
                                                            <option key={index} value={item._id}>{item.name}</option>
                                                        ))}
                                                    </select>
                                                );
                                            default:
                                            break;
                                        }
                                    })()
                                }
                            </div> 
                            : null
                            }
                            <div className='campo-form campo-70'>
                                <label htmlFor='assunto'>Assunto:</label>
                                <input type="text" id='assunto' name='assunto' autoComplete='off'/>
                            </div>
                            <div className='campo-form campo-30'>
                                <label htmlFor='tipo'>Tipo:</label>
                                <select id='tipo' name='tipo'>
                                    <option value="default">Selecione</option>
                                    <option value="alerta">Alerta</option>
                                    <option value="erro">Erro</option>
                                    <option value="info">Informativo</option>
                                </select>
                            </div>
                            <div className="campo-form campo-100">
                                <label htmlFor='descricao'>Descrição:</label>
                                <textarea type="text" name="descricao" id="descricao" rows="3"/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" onClick={() => {setTextoEmail(""); setComplementarAtivo(false)}} className="button-red">Cancelar</button>
                            <button type="submit" className="button-green">Enviar</button>
                        </div>
                    </form>
                    {/* <div className="filtro-conteudo">
                        <input type="text" name="src-geral-sinistros" id="src-geral-sinistro" placeholder="Faça sua busca aqui"/>
                        <button className="filtro-avancado">Filtro Avançado</button>
                        <button className="filtro-buscar button-green">
                        <FontAwesomeIcon icon={faMagnifyingGlass}/>
                        </button>
                    </div> */}
                    {/* <div className="tabela-dados">
                        <div className="cabecalho-dados">
                            <div className="column-35">Titulo/Seguradora</div>
                            <div className="column-20">Apólice</div>
                            <div className="column-10">Prêmio</div>
                            <div className="column-10">Venda</div>
                            <div className="column-10">Inicio</div>
                            <div className="column-10">Término</div>
                            <div className="column-5">Vidas</div>
                        </div>
                        <div className="corpo-dados corpo-apolices">
                            { apolicesAtivas.length === 0 ? 
                                <div className="row-dados">
                                    <div className="column-zerado">
                                        Não foi encontrado nenhum resultado.
                                    </div>
                                </div>
                                : (
                                    (apolicesAtivas.slice(0).reverse().map((apolices) => (
                                        <Fragment>
                                            <div key={apolices._id} className="row-dados">
                                                <div className="column-35">
                                                    {apolices.titulo}/{apolices.seguradora}
                                                </div>
                                                <div className="column-20">
                                                    {apolices.apolice_vg !== "" ? apolices.apolice_vg : apolices.apolice_ap}
                                                </div>
                                                <div className="column-10">
                                                    {apolices.premio}
                                                </div>
                                                <div className="column-10">
                                                    {apolices.venda}
                                                </div>
                                                <div className="column-10">
                                                    <Moment format="DD/MM/YYYY">
                                                        {apolices.vigencia_inicio}
                                                    </Moment>
                                                </div>
                                                <div className="column-10">
                                                    <Moment format="DD/MM/YYYY">
                                                        {apolices.vigencia_final}
                                                    </Moment>
                                                </div>
                                                <div className="column-5">
                                                    {apolices.vidas}
                                                </div>
                                            </div>
                                        </Fragment>
                                    )))
                                )
                            }
                            <h5>Exibindo {apolicesAtivas.length} {apolicesAtivas.length === 1 ? "resultado" : "resultados"}</h5>
                        </div>
                    </div> */}
                </div>
            </main>
        </Fragment>
    )
};