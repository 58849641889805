import Api from './api';

const SinistrosService = {
    index: () => Api.get('/sinistros'),
    sinistro: (id) => Api.get(`/sinistros/${id}`),
    criar_sinistro: async (params) => await Api.post(`/sinistros`, (params)),
    alterar_status: async (id, params) => await Api.put(`/sinistros/${id}/status`, (params)),
    excluir_sinistro: async (id) => await Api.put(`/sinistros/${id}/delete`),
    restaurar_sinistro: async (id) => await Api.put(`/sinistros/${id}/restore`),
    delete_sinistro: async (id) => await Api.delete(`/sinistros/${id}`),
    criar_historico: async (id, params) => await Api.post(`/sinistros/${id}/historicos`, (params)),
    historicos: (id) => Api.get(`/sinistros/${id}/historicos`),
    documentos: (id) => Api.get(`/sinistros/${id}/documentos`),
    add_documento: (id, params) => Api.post(`/sinistros/${id}/documentos/complementares`, (params), {headers: { 'Content-Type': `multipart/form-data; boundary=${FormData._boundary}`}}),
    solicitar_documento: (id, params) => Api.post(`/sinistros/${id}/documentos/solicitar`, (params)),
    delete_documento: (id, otherId, params) => Api.delete(`/sinistros/${id}/documentos/${otherId}?tipo=${params}`),
    anexar_documento: (id, otherId, params) => Api.put(`/sinistros/${id}/documentos/${otherId}/anexar`, (params), {headers: { 'Content-Type': `multipart/form-data; boundary=${FormData._boundary}`}}),
    alterar_documento: (id, otherId, params, tipo) => Api.put(`/sinistros/${id}/documentos/${otherId}/${tipo === "anexados" ? "complementares" : "solicitados"}`, (params), {headers: { 'Content-Type': `multipart/form-data; boundary=${FormData._boundary}`}}),
    alterar_status_documento: (id, otherId, params) => Api.put(`/sinistros/${id}/documentos/${otherId}/status`, (params)),
    criar_agenda: async (id, params) => await Api.post(`/sinistros/${id}/agendas`, (params)),
    agendas: (id) => Api.get(`/sinistros/${id}/agendas`),
    add_beneficiario: async (id, params) => await Api.post(`/sinistros/${id}/beneficiarios`, (params)),
    edit_beneficiario: async (id, otherId, params) => await Api.put(`/sinistros/${id}/beneficiarios/${otherId}`, (params)),
    delete_beneficiario: async (id, otherId) => await Api.delete(`/sinistros/${id}/beneficiarios/${otherId}`)
}

export default SinistrosService;