import { Fragment } from 'react';
import Header from '../../components/header/index';
import ContentEmpresa from '../../components/administracao/empresas/empresa';

const EmpresaScreen = (props) => (
  <Fragment>
    <Header ativo={props.ativo}/>
    <ContentEmpresa/>
  </Fragment>
)

export default EmpresaScreen;