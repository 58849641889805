import { Fragment } from 'react';
import Header from '../../components/header/index';
// import ContentCorretora from '../../components/corretora/index';

const VidasScreen = (props) => (
  <Fragment>
    <Header ativo={props.ativo}/>
    {/* <ContentCorretora/> */}
  </Fragment>
)

export default VidasScreen;