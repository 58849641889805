import { Fragment } from 'react';
import Header from '../../components/header/index';
import ContentAdministracaoNotificacoes from '../../components/administracao/notificacoes';

const NotificacoesScreen = (props) => (
  <Fragment>
    <Header ativo={props.ativo}/>
    <ContentAdministracaoNotificacoes/>
  </Fragment>
)

export default NotificacoesScreen;