import { Fragment } from 'react';
import Header from '../../components/header/index';
import ContentHistoricos from '../../components/corretora/historicos';

const HistoricosScreen = (props) => (
  <Fragment>
    <Header ativo={props.ativo}/>
    <ContentHistoricos/>
  </Fragment>
)

export default HistoricosScreen;