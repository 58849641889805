import { Fragment } from 'react';
import Header from '../../components/header/index';
import ContentLogs from '../../components/administracao/log';

const LogScreen = (props) => (
  <Fragment>
    <Header ativo={props.ativo}/>
    <ContentLogs/>
  </Fragment>
)

export default LogScreen;