import { Fragment } from 'react';
import Header from '../../components/header/index';
import ContentBugChamados from '../../components/chamados/bugs';

const ChamadosBugScreen = (props) => (
  <Fragment>
    <Header ativo={props.ativo}/>
    <ContentBugChamados/>
  </Fragment>
)

export default ChamadosBugScreen;