import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';
import 'moment-timezone';
import "../../../styles/apolices.scss";
import "../../../styles/reset.scss";
import Modal from "../../layouts/modal";
import ModalDangerSmall from '../../layouts/modalDangerSmall';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ApolicesService from '../../../services/apolices';
import SeguradorasService from '../../../services/seguradoras'

export default function ContentAdministracaoApolices() {
    const navigate = useNavigate();
    const [textoAdicional, setTextoAdicional] = useState('');
    const [apolices, setApolices] = useState([]);
    const [seguradoras, setSeguradoras] = useState([]);
    const [apoliceAtiva, setApoliceAtiva] = useState();
    const [modalCreateApolice, setModalCreateApolice] = useState(false);
    const [modalApolice, setModalApolice] = useState(false);
    const [modalEditarApolice, setModalEditarApolice] = useState(false);
    const [modalDeleteApolice, setModalDeleteApolice] = useState(false);

    var modules = { toolbar: [
        [{ 'font': [] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'align': [] }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'super' }],
        ['blockquote'],
        ['clean']                                         
    ]};

    async function getApolices() {
        const res = await ApolicesService.index();
        setApolices(res.data?.filter(({ excluido }) => excluido === false));
    }

    async function getSeguradoras() {
        const res = await SeguradorasService.index();
        setSeguradoras(res.data?.filter(({ excluido }) => excluido === false));
    }

    useEffect(() => {
        getApolices();
        getSeguradoras();
    }, []);

    async function createApolice(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let data = [];
        data.push(Object.fromEntries(formData));

        try {
            const substituirBr = textoAdicional.replaceAll("<br>", "\n");
            data[0].info_adicionais = textoAdicional;
            console.log(data[0])

            await ApolicesService.create(data[0]);
            getApolices();
            setModalCreateApolice(false);
            
        } catch (error) {
            console.log(error)
        }
    }

    async function editApolice(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let data = [];
        data.push(Object.fromEntries(formData));

        try {
            const substituirBr = textoAdicional.replaceAll("<br>", "\n");
            data[0].info_adicionais = textoAdicional;
            console.log(data[0])

            await ApolicesService.edit(apoliceAtiva._id, data[0]);
            getApolices();
            setModalEditarApolice(false);
            
        } catch (error) {
            console.log(error)
        }
    }

    async function desativarApolice() {
        await ApolicesService.desativar(apoliceAtiva._id);
        getApolices();
        setModalDeleteApolice(false);
    };

    function getApoliceAtiva(id) {
        setApoliceAtiva(apolices.filter(({ _id }) => _id === id)[0])
    };

    const apolicesAtivas = apolices?.filter(({ excluido }) => excluido === false);
    const seguradorasAtivas = seguradoras?.filter(({ excluido }) => excluido === false);

    document.title = `Apólices - JMX Corretora de Seguros`;

    return(
        <Fragment>
            <main id='main-apolices'>
                <div className="conteudo-sinistros">
                    <div className="cabecalho-conteudo">
                        <h2>Apólices</h2>
                        <div className="buttons-conteudo">
                            <button className="button-red" onClick={()=> navigate(`/administracao`)}>Voltar</button>
                            <button className="button-blue" onClick={() => {window.print()}}>Imprimir</button>
                            <button className="button-green" onClick={() => setModalCreateApolice(true)}>Nova apólice</button>
                        </div>
                    </div>
                    <div className="filtro-conteudo">
                        <input type="text" name="src-geral-sinistros" id="src-geral-sinistro" placeholder="Faça sua busca aqui"/>
                        <button className="filtro-avancado">Filtro Avançado</button>
                        <button className="filtro-buscar button-green">
                        <FontAwesomeIcon icon={faMagnifyingGlass}/>
                        </button>
                    </div>
                    <div className="tabela-dados">
                        <div className="cabecalho-dados">
                            <div className="column-35">Titulo/Seguradora</div>
                            <div className="column-20">N° de Apólice</div>
                            <div className="column-10">Prêmio</div>
                            <div className="column-10">Venda</div>
                            <div className="column-10">Inicio</div>
                            <div className="column-10">Término</div>
                            <div className="column-5">Vidas</div>
                        </div>
                        <div className="corpo-dados corpo-apolices">
                            { apolicesAtivas.length === 0 ? 
                                <div className="row-dados">
                                    <div className="column-zerado">
                                        Não foi encontrado nenhum resultado.
                                    </div>
                                </div>
                                : (
                                    (apolicesAtivas.slice(0).reverse().map((item) => (
                                        <Fragment>
                                            <div key={item._id} className="row-dados" onClick={() => {setModalApolice(true); getApoliceAtiva(item._id)}}>
                                                <div className="column-35">
                                                    {item.titulo} / {item.seguradora.fantasia}
                                                </div>
                                                <div className="column-20">
                                                    {item.apolice_vg !== "" ? item.apolice_vg : item.apolice_ap}
                                                </div>
                                                <div className="column-10">
                                                    {item.premio}
                                                </div>
                                                <div className="column-10">
                                                    {item.venda}
                                                </div>
                                                <div className="column-10">
                                                    <Moment format="DD/MM/YYYY">
                                                        {item.vigencia_inicio}
                                                    </Moment>
                                                </div>
                                                <div className="column-10">
                                                    <Moment format="DD/MM/YYYY">
                                                        {item.vigencia_final}
                                                    </Moment>
                                                </div>
                                                <div className="column-5">
                                                    {item.vidas}
                                                </div>
                                            </div>
                                        </Fragment>
                                    )))
                                )
                            }
                            <h5>Exibindo {apolicesAtivas.length} {apolicesAtivas.length === 1 ? "resultado" : "resultados"}</h5>
                        </div>
                    </div>
                </div>
            </main>
            {modalCreateApolice ? (
                <Modal titulo="Nova Apólice" width="60%" onClose={() => {setModalCreateApolice(false)}}>
                    <form onSubmit={createApolice}>
                        <div id="campos-nova-apolice">
                            <div className="campo-form campo-25">
                                <label htmlFor="seguradora-apolice">Seguradora</label>
                                <select name="seguradora" id="seguradora-apolice">
                                    <option value="default">Selecione</option>
                                    {seguradorasAtivas?.map((item) => (
                                        <option key={item._id} value={item._id}>{item.fantasia}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="campo-form campo-55">
                                <label htmlFor="titulo-apolice">Titulo</label>
                                <input type="text" name="titulo" id="titulo-apolice"/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="capital-apolice">Capital</label>
                                <input type="text" name="capital" id="capital-apolice"/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="apolice-vg">Apólice VG</label>
                                <input type="text" name="apolice_vg" id="apolice-vg"/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="apolice-ap">Apólice AP</label>
                                <input type="text" name="apolice_ap" id="apolice-ap"/>
                            </div>
                            <div className="campo-form campo-15">
                                <label htmlFor="premio-apolice">Prêmio</label>
                                <input type="text" name="premio" id="premio-apolice"/>
                            </div>
                            <div className="campo-form campo-15">
                                <label htmlFor="comissao-apolice">Comissão</label>
                                <input type="text" name="comissao" id="comissao-apolice"/>
                            </div>
                            <div className="campo-form campo-15">
                                <label htmlFor="idade-min-apolice">Idade Min.</label>
                                <input type="number" name="idade_min" id="idade-min-apolice"/>
                            </div>
                            <div className="campo-form campo-15">
                                <label htmlFor="idade-max-apolice">Idade Máx.</label>
                                <input type="number" name="idade_max" id="idade-max-apolice"/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="vigencia-inicial-apolice">Inicio Vigência</label>
                                <input type="number" name="vigencia_inicio" id="vigencia-inicio-apolice"/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="vigencia-final-apolice">Final Vigência</label>
                                <input type="number" name="vigencia_final" id="vigencia-final-apolice"/>
                            </div>
                            <div className="campo-form campo-15">
                                <label htmlFor="sucursal-apolice">Sucursal</label>
                                <input type="text" name="sucursal" id="sucursal-apolice"/>
                            </div>
                            <div className="campo-form campo-45">
                                <label htmlFor="info-susep-apolice">Informações Processo SUSEP</label>
                                <input type="text" name="info_processo_susep" id="info-susep-apolice"/>
                            </div>
                            <div className="campo-form campo-100">
                                <label>Informações Adicionais</label>
                                <ReactQuill theme="snow" value={textoAdicional} modules={modules} onChange={setTextoAdicional} />
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalCreateApolice(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Cadastrar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalApolice ? (
                <Modal titulo="Informações Apólice" onClose={() => {setModalApolice(false)}}>
                    <div className="info-modal">
                        <div className="campo-info campo-25">
                            <h5>Seguradora</h5>
                            <h4>{apoliceAtiva.seguradora.fantasia}</h4>
                        </div>
                        <div className="campo-info campo-55">
                            <h5>Titulo</h5>
                            <h4>{apoliceAtiva.titulo}</h4>
                        </div>
                        <div className="campo-info campo-20">
                            <h5>Capital</h5>
                            <h4>R${apoliceAtiva.capital}</h4>
                        </div>
                        <div className="campo-info campo-20">
                            <h5>Apólice VG</h5>
                            <h4>{apoliceAtiva.apolice_vg}</h4>
                        </div>
                        <div className="campo-info campo-20">
                            <h5>Apólice AP</h5>
                            <h4>{apoliceAtiva.apolice_ap}</h4>
                        </div>
                        <div className="campo-info campo-15">
                            <h5>Prêmio</h5>
                            <h4>R${apoliceAtiva.premio}</h4>
                        </div>
                        <div className="campo-info campo-15">
                            <h5>Comissão</h5>
                            <h4>{apoliceAtiva.comissao}%</h4>
                        </div>
                        <div className="campo-info campo-15">
                            <h5>Idade Miníma</h5>
                            <h4>{apoliceAtiva.idade_min}</h4>
                        </div>
                        <div className="campo-info campo-15">
                            <h5>Idade Máxima</h5>
                            <h4>{apoliceAtiva.idade_max}</h4>
                        </div>
                        <div className="campo-info campo-20">
                            <h5>Inicio Vigência</h5>
                            <h4>{apoliceAtiva.vigencia_inicio}</h4>
                        </div>
                        <div className="campo-info campo-20">
                            <h5>Final Vigência</h5>
                            <h4>{apoliceAtiva.vigencia_final}</h4>
                        </div>
                    </div>
                    <div className='buttons-info'>
                        <button type="reset" className="button-red" onClick={() => {setModalApolice(false)}}>Cancelar</button>
                        <button type="button" className="button-darkred" onClick={() => {setModalApolice(false); setModalDeleteApolice(true)}}>Excluir</button>
                        <button type="button" className="button-cyan" onClick={() => {setModalApolice(false); setModalEditarApolice(true)}}>Editar</button>
                    </div>
                </Modal>
            ): null}
            {modalEditarApolice ? (
                <Modal titulo="Editar Seguradora" width="60%" onClose={() => {setModalEditarApolice(false)}}>
                    <form onSubmit={editApolice}>
                        <div id="campos-nova-apolice">
                            <div className="campo-form campo-25">
                                <label htmlFor="seguradora-apolice">Seguradora</label>
                                <select name="seguradora" id="seguradora-apolice" defaultValue={apoliceAtiva.seguradora._id}>
                                    <option value="default">Selecione</option>
                                    {seguradorasAtivas?.map((item) => (
                                        <option key={item._id} value={item._id}>{item.fantasia}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="campo-form campo-55">
                                <label htmlFor="titulo-apolice">Titulo</label>
                                <input type="text" name="titulo" id="titulo-apolice" defaultValue={apoliceAtiva.titulo}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="capital-apolice">Capital</label>
                                <input type="text" name="capital" id="capital-apolice" defaultValue={apoliceAtiva.capital}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="apolice-vg">Apólice VG</label>
                                <input type="text" name="apolice_vg" id="apolice-vg" defaultValue={apoliceAtiva.apolice_vg}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="apolice-ap">Apólice AP</label>
                                <input type="text" name="apolice_ap" id="apolice-ap" defaultValue={apoliceAtiva.apolice_ap}/>
                            </div>
                            <div className="campo-form campo-15">
                                <label htmlFor="premio-apolice">Prêmio</label>
                                <input type="text" name="premio" id="premio-apolice" defaultValue={apoliceAtiva.premio}/>
                            </div>
                            <div className="campo-form campo-15">
                                <label htmlFor="comissao-apolice">Comissão</label>
                                <input type="text" name="comissao" id="comissao-apolice" defaultValue={apoliceAtiva.comissao}/>
                            </div>
                            <div className="campo-form campo-15">
                                <label htmlFor="idade-min-apolice">Idade Min.</label>
                                <input type="number" name="idade_min" id="idade-min-apolice" defaultValue={apoliceAtiva.idade_min}/>
                            </div>
                            <div className="campo-form campo-15">
                                <label htmlFor="idade-max-apolice">Idade Máx.</label>
                                <input type="number" name="idade_max" id="idade-max-apolice" defaultValue={apoliceAtiva.idade_max}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="vigencia-inicial-apolice">Inicio Vigência</label>
                                <input type="date" name="vigencia_inicio" id="vigencia-inicio-apolice" defaultValue={apoliceAtiva.vigencia_inicio}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="vigencia-final-apolice">Final Vigência</label>
                                <input type="date" name="vigencia_final" id="vigencia-final-apolice" defaultValue={apoliceAtiva.vigencia_final}/>
                            </div>
                            <div className="campo-form campo-15">
                                <label htmlFor="sucursal-apolice">Sucursal</label>
                                <input type="text" name="sucursal" id="sucursal-apolice" defaultValue={apoliceAtiva.sucursal}/>
                            </div>
                            <div className="campo-form campo-45">
                                <label htmlFor="info-susep-apolice">Informações Processo SUSEP</label>
                                <input type="text" name="info_processo_susep" id="info-susep-apolice" defaultValue={apoliceAtiva.info_processo_susep}/>
                            </div>
                            <div className="campo-form campo-100">
                                <label>Informações Adicionais</label>
                                <ReactQuill theme="snow" value={textoAdicional} modules={modules} onChange={setTextoAdicional} />
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalEditarApolice(false); setModalApolice(true)}}>Cancelar</button>
                            <button type="submit" className="button-green">Salvar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalDeleteApolice ? (
                <ModalDangerSmall mensagem={['Deseja excluir a apólice', <br/>,<span>{apoliceAtiva.titulo}</span>,"?"]} onClose={() => {setModalDeleteApolice(false)}}>
                    <Fragment>
                        <div className='buttons-form'>
                            <button type="button" className="button-red" onClick={() => {setModalDeleteApolice(false); setModalApolice(true)}}>Cancelar</button>
                            <button type="submit" className="button-blue" onClick={() => {desativarApolice()}}>Excluir</button>
                        </div>
                    </Fragment>
                </ModalDangerSmall>
            ): null}
        </Fragment>
    )
};