import Api from './api';

const SeguradorasService = {
    index: () => Api.get('/seguradoras'),
    create: (params) => Api.post('/seguradoras', params),
    edit: (id, params) => Api.put(`/seguradoras/${id}`, params),
    desativar: (id) => Api.put(`/seguradoras/${id}/desabilitar`),
    ativar: (id) => Api.put(`/seguradoras/${id}/habilitar`),
    delete: (id) => Api.delete(`/seguradoras/${id}`)
}

export default SeguradorasService;