import { Fragment } from 'react';
import Header from '../../components/header/index';
import ContentHome from '../../components/home/index';

const HomeScreen = (props) => (
  <Fragment>
    <Header ativo={props.ativo}/>
    <ContentHome/>
  </Fragment>
)

export default HomeScreen;