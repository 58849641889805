import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faUserPen } from '@fortawesome/free-solid-svg-icons';
import "../../../styles/usuarios.scss";
import "../../../styles/reset.scss";
import Api from '../../../services/api';
import Modal from '../../layouts/modal';
import ModalDangerSmall from '../../layouts/modalDangerSmall';
import UsersService from '../../../services/users';

function ContentAdministracao() {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [empresas, setEmpresas] = useState([]);
    const [userAtivo, setUserAtivo] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalEditUser, setModalEditUser] = useState(false);
    const [modalDeleteUser, setModalDeleteUser] = useState(false);

    useEffect(() => {
        Api.get('/usuarios').then(({ data }) => {
            setUsers(data);
        })

        Api.get('/empresas').then(({ data }) => {
            setEmpresas(data);
        });
    }, []);

    const createUsuario = async event => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData);
        
        try {
            await UsersService.registrar(data);
            window.location.reload();
        } catch (error) {
            console.log(error)
        }
    }

    const editUsuario = async event => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData);
        
        try {
            await UsersService.editar_usuario(userAtivo._id, data);
            window.location.reload();
        } catch (error) {
            console.log(error)
        }
    }

    function getUserAtivo(id) {
        setUserAtivo(users?.filter(({ _id }) => _id === id)[0])
    }

    async function desativarUser(id) {
        await UsersService.excluir_usuario({id});
        window.location.reload();
    };

    document.title = 'Usuários - JMX Corretora de Seguros';

    return(
        <Fragment>
            <main id='main-senhas'>
                <div className="cabecalho-conteudo">
                    <h2>Banco de Senhas</h2>
                    <div className="buttons-conteudo">
                        <button className="button-red" onClick={()=> navigate(`/administracao`)}>Voltar</button>
                        <button className="button-blue">Imprimir</button>
                        <button className="button-green" onClick={() => setModalIsOpen(true)}>Novo Usuário</button>
                    </div>
                </div>
                <div className="content-usuarios">
                    {/* {users?.filter(({ excluido }) => excluido === false).map((users) => (
                        <div key={users._id} className="list-itens">
                            <div className="content-item">
                                {(() => {
                                        switch (true) {
                                            case (users.permission === 1):
                                                return(<Fragment><img src={RaphaelImage} alt=""/></Fragment>)
                                            case (users.permission === 2):
                                                return(<Fragment><img src={MatteusImage} alt=""/></Fragment>)
                                            case (users.permission === 3 || users.permission === 6):
                                                return(<Fragment><img src={PadraoImage} alt=""/></Fragment>)
                                            case (users.permission === 4):
                                                return(<Fragment><img src={GeovanaImage} alt=""/></Fragment>)
                                            case (users.permission === 5):
                                                return(<Fragment><img src={ClienteImage} alt=""/></Fragment>)
                                            default:
                                            break;
                                        }
                                })()}
                                <div className="titulo-usuario">
                                    {(() => {
                                            switch (true) {
                                                case (users.permission === 1):
                                                    return(<Fragment><h3 style={{color: "#007bff"}}>{users.name}</h3><span>Desenvolvedor</span></Fragment>)
                                                case (users.permission === 2):
                                                    return(<Fragment><h3 style={{color: "#298A08"}}>{users.name}</h3><span>Sócio Administrador</span></Fragment>)
                                                case (users.permission === 3):
                                                    return(<Fragment><h3 style={{color: "#4e5e70"}}>{users.name}</h3><span>Corretor</span></Fragment>)
                                                case (users.permission === 4):
                                                    return(<Fragment><h3 style={{color: "#DC143C"}}>{users.name}</h3><span>Auxiliar Administrativo</span></Fragment>)
                                                case (users.permission === 5):
                                                    return(<Fragment><h3 style={{color: "#FFFF00"}}>{users.name}</h3><span>Cliente</span></Fragment>)
                                                case (users.permission === 6):
                                                    return(<Fragment><h3 style={{color: "#8B008B"}}>{users.name}</h3><span>Beta Tester</span></Fragment>)
                                                default:
                                                break;
                                            }
                                    })()}
                                </div>
                                <div className="campo-info-user">
                                    <h4>E-mail:</h4>
                                    <h5>
                                        {users.email}
                                    </h5>
                                </div>
                                <div className="campo-info-user">
                                    <h4>Empresa:</h4>
                                    <h5>
                                        {users.empresa}
                                    </h5>
                                </div>
                            </div>
                            <a onClick={() => {getUserAtivo(users._id); setModalEditUser(true)}}>
                                <FontAwesomeIcon icon={faUserPen}/>
                                <p>Editar</p>
                            </a>
                            {/* {users.permission <= 2 ? 
                                (<a className='a-desativado'>)
                            : 
                                (<a onClick={() => {desativarUser(users._id)}}>)
                            } */}
                            {/* <a {users.permission <= 2 ? className='a-desativado' : onClick={desativarUser(users._id)}>
                                <FontAwesomeIcon icon={faTrashCan}/>
                                <p>Excluir</p>
                            </a>
                            {users.permission <= 2 ?
                                (<a className='a-desativado'>
                                    <FontAwesomeIcon icon={faTrashCan}/>
                                    <p>Excluir</p>
                                </a>)
                            :
                                (<a onClick={() => {getUserAtivo(users._id); setModalDeleteUser(true);} }>
                                    <FontAwesomeIcon icon={faTrashCan}/>
                                    <p>Excluir</p>
                                </a>)
                            }
                        </div>
                    ))} */}
                </div>
            </main>
            {modalIsOpen ? (
                <Modal titulo="Novo Usuário" onClose={() => {setModalIsOpen(false)}}>
                    <form onSubmit={createUsuario}>
                        <div id="campos-novo-usuario">
                            <div className="campo-form campo-50">
                                <label htmlFor="email-usuario">E-mail</label>
                                <input type="email" name="email" id="email-usuario"/>
                            </div>
                            <div className="campo-form campo-50">
                                <label htmlFor="password-usuario">Senha</label>
                                <input type="password" name="password" id="password-usuario"/>
                            </div>
                            <div className="campo-form campo-40">
                                <label htmlFor="name-usuario">Nome</label>
                                <input type="text" name="name" id="name-usuario"/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="empresa-usuario">Empresa</label>
                                <select name="empresa" id="empresa-usuario">
                                    <option value="default">Selecione</option>
                                    {empresas?.map((empresas) => (
                                        <option key={empresas._id} value={empresas.fantasia}>{empresas.fantasia}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="permissao-usuario">Permissão do Usuário</label>
                                <select name="permission" id="permissao-usuario">
                                    <option value="default">Selecione</option>
                                    <option value="1">Desenvolvedor</option>
                                    <option value="2">Sócio Proprietário</option>
                                    <option value="3">Corretor</option>
                                    <option value="4">Auxiliar Administrativo</option>
                                    <option value="5">Cliente</option>
                                    <option value="6">Beta Tester</option>
                                    <option value="7">Visualizador</option>
                                </select>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalIsOpen(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Cadastrar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalDeleteUser ? (
                <ModalDangerSmall mensagem={['Deseja excluir o usuário', <br/>,<span>{userAtivo.name}</span>,"?"]} onClose={() => {setModalDeleteUser(false)}}>
                    <Fragment>
                        <div className='buttons-form'>
                            <button type="button" className="button-red" onClick={() => {setModalDeleteUser(false)}}>Cancelar</button>
                            <button type="submit" className="button-blue" onClick={() => {desativarUser(userAtivo._id)}}>Excluir</button>
                        </div>
                    </Fragment>
                </ModalDangerSmall>
            ): null}
            {modalEditUser ? (
                <Modal titulo="Editar Usuário" onClose={() => {setModalEditUser(false)}}>
                    <form onSubmit={editUsuario}>
                        <div id="campos-novo-usuario">
                            <div className="campo-form campo-50">
                                <label htmlFor="email-usuario">E-mail</label>
                                <input type="email" name="email" id="email-usuario" defaultValue={userAtivo.email}/>
                            </div>
                            {/* <div className="campo-form campo-50">
                                <label htmlFor="password-usuario">Senha</label>
                                <input type="password" name="password" id="password-usuario"/>
                            </div> */}
                            <div className="campo-form campo-50">
                                <label htmlFor="name-usuario">Nome</label>
                                <input type="text" name="name" id="name-usuario" defaultValue={userAtivo.name}/>
                            </div>
                            <div className="campo-form campo-50">
                                <label htmlFor="empresa-usuario">Empresa</label>
                                <select name="empresa" id="empresa-usuario">
                                    <option value="default">Selecione</option>
                                    {empresas?.map((empresas) => (
                                        <option key={empresas._id} value={empresas.fantasia}>{empresas.fantasia}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="campo-form campo-50">
                                <label htmlFor="permissao-usuario">Permissão do Usuário</label>
                                <select name="permission" id="permissao-usuario" defaultValue={userAtivo.permission}>
                                    <option value="default">Selecione</option>
                                    <option value="1">Desenvolvedor</option>
                                    <option value="2">Sócio Proprietário</option>
                                    <option value="3">Corretor</option>
                                    <option value="4">Auxiliar Administrativo</option>
                                    <option value="5">Cliente</option>
                                    <option value="6">Beta Tester</option>
                                    <option value="7">Visualizador</option>
                                </select>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="button" className="button-red" onClick={() => {setModalEditUser(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Salvar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
        </Fragment>
    )
};
   
export default ContentAdministracao;