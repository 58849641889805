import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBriefcase, faHeart, faRightFromBracket, faScrewdriverWrench, faHouse, faCircleQuestion, faGear, faSquare, faHeadset } from '@fortawesome/free-solid-svg-icons'
import "../../styles/header.scss";
import "../../styles/reset.scss";
import UsersService from '../../services/users';
import LogoJMX from '../../assets/logos/LogoJMX_Registrado (Branco).png'

function Header(props) {
    const navigate = useNavigate();
    const [dados, setDados] = useState();
    const [redirectToHome, setRedirectToHome] = useState(false);
    const [carregado, setCarregado] = useState(false);

    useEffect(() => {
        (async function() {
            const response = await UsersService.logado();
            setDados(response.data);
            setCarregado(true);
        })();
    }, []);

    async function logOut() {
        await UsersService.logout();
        setRedirectToHome(true);
    }
   
    if (redirectToHome === true) {
        return navigate("/login")
    }

    return(
        <Fragment>
            { carregado ?
                <header>
                    <div className="logo-empresa-menu">
                        <img src={LogoJMX} alt='Logo JMX' onClick={() => navigate('/')}/>
                    </div>
                    <nav>
                        <div className="itens-lista-menu">
                            <div className={`menu-item item-inicio ${props.ativo === "inicio"? 'item-ativo': ''}`}>
                                <Link to={'/'}>
                                    <FontAwesomeIcon icon={faHouse} />
                                    <p>Inicio</p>
                                </Link>
                            </div>
                            <div className={`menu-item item-desenvolvedor ${props.ativo === "administracao"? 'item-ativo': ''}`}>
                                { dados.user.permission <= 4 || dados.user.permission === 6 ? (
                                    <Link to={'/administracao'}>
                                        <FontAwesomeIcon icon={faScrewdriverWrench} />
                                        <p>Administração</p>
                                    </Link>
                                ) : null}
                            </div>
                            <div className={`menu-item item-vidas ${props.ativo === "vidas"? 'item-ativo': ''}`}>
                                { dados.user.permission !== 7 ? (
                                    <Link to={'/vidas'}>
                                        <FontAwesomeIcon icon={faHeart} />
                                        <p>Gestão de Vidas</p>
                                    </Link>
                                ) : null}
                            </div>
                            <div className={`menu-item item-corretora ${props.ativo === "corretora"? 'item-ativo': ''}`}>
                                { dados.user.permission <= 4 || dados.user.permission === 6 || dados.user.permission === 7 ? (
                                    <Link to={'/corretora'}>
                                        <FontAwesomeIcon icon={faBriefcase} />
                                        <p>Gestão Corretora</p>
                                    </Link>
                                ) : null}
                            </div>
                        </div>
                        <hr/>
                        <div className="itens-lista-menu">
                            <div className={`menu-item item-info ${props.ativo === "chamados"? 'item-ativo': ''}`}>
                                <Link to={'/chamados'}>
                                    <FontAwesomeIcon icon={faHeadset} />
                                    <p>Chamados</p>
                                </Link>
                            </div>
                            <div className={`menu-item item-config ${props.ativo === "config"? 'item-ativo': ''}`}>
                                <Link to={'/configuracoes'}>
                                    <FontAwesomeIcon icon={faGear} />
                                    <p>Configurações</p>
                                </Link>
                            </div>
                        </div>
                    </nav>
                    <div className='logout' onClick={e => logOut()}>
                        <FontAwesomeIcon icon={faRightFromBracket}/>
                        <span>Desconectar</span>
                    </div>
                </header>
            : 
                <header>
                    <div className="empresa-conectada">
                        <p className="animated-background" style={{color: "transparent"}}>Você está conectado na empresa:<br/><span>Carregando.....</span></p>
                    </div>
                    <nav>
                        <div className="itens-lista-menu">
                            <div className="menu-item item-vidas">
                                <Link to={'/'} reloadDocument>
                                    <FontAwesomeIcon icon={faSquare} className="animated-background" style={{color: "transparent"}}/>
                                    <p className="animated-background" style={{color: "transparent"}}>Carregando........</p>
                                </Link>
                            </div>
                            <div className="menu-item item-vidas">
                                <Link to={'/'} reloadDocument>
                                    <FontAwesomeIcon icon={faSquare} className="animated-background" style={{color: "transparent"}}/>
                                    <p className="animated-background" style={{color: "transparent"}}>Carregando........</p>
                                </Link>
                            </div>
                            <div className="menu-item item-vidas">
                                <Link to={'/'} reloadDocument>
                                    <FontAwesomeIcon icon={faSquare} className="animated-background" style={{color: "transparent"}}/>
                                    <p className="animated-background" style={{color: "transparent"}}>Carregando........</p>
                                </Link>
                            </div>
                            <div className="menu-item item-vidas">
                                <Link to={'/'} reloadDocument>
                                    <FontAwesomeIcon icon={faSquare} className="animated-background" style={{color: "transparent"}}/>
                                    <p className="animated-background" style={{color: "transparent"}}>Carregando........</p>
                                </Link>
                            </div>
                        </div>
                        <hr/>
                        <div className="itens-lista-menu">
                            <div className="menu-item item-vidas">
                                <Link to={'/'} reloadDocument>
                                    <FontAwesomeIcon icon={faSquare} className="animated-background" style={{color: "transparent"}}/>
                                    <p className="animated-background" style={{color: "transparent"}}>Carregando........</p>
                                </Link>
                            </div>
                            <div className="menu-item item-vidas">
                                <Link to={'/'} reloadDocument>
                                    <FontAwesomeIcon icon={faSquare} className="animated-background" style={{color: "transparent"}}/>
                                    <p className="animated-background" style={{color: "transparent"}}>Carregando........</p>
                                </Link>
                            </div>
                            <div className="menu-item item-vidas">
                                <Link to={'/'} reloadDocument>
                                    <FontAwesomeIcon icon={faSquare} className="animated-background" style={{color: "transparent"}}/>
                                    <p className="animated-background" style={{color: "transparent"}}>Carregando........</p>
                                </Link>
                            </div>
                            <div className="menu-item item-vidas">
                                <Link to={'/'} reloadDocument>
                                    <FontAwesomeIcon icon={faSquare} className="animated-background" style={{color: "transparent"}}/>
                                    <p className="animated-background" style={{color: "transparent"}}>Carregando........</p>
                                </Link>
                            </div>
                        </div>
                    </nav>
                    <div className='logout' onClick={e => logOut()}>
                        <FontAwesomeIcon icon={faRightFromBracket} className="animated-background" style={{color: "transparent"}}/>
                        <span className="animated-background" style={{color: "transparent"}}>Carregando...</span>
                    </div>
                </header>
            }
        </Fragment>
    )
};
   
export default Header;