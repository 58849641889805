import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faCheck, faRotateRight } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/pt-br';
import "../../../styles/visitas.scss";
import "../../../styles/reset.scss";
import UsersService from '../../../services/users';
import Modal from "../../layouts/modal";
import ModalDangerSmall from '../../layouts/modalDangerSmall';
import VisitasService from '../../../services/visitas';

export default function ContentAdministracaoVisitas() {
    const navigate = useNavigate();
    const [btnFiltroAtivo, setBtnFiltroAtivo] = useState('n-concluidos');
    const [users, setUsers] = useState([]);
    const [days, setDays] = useState([]);
    const [visitas, setVisitas] = useState([]);
    const [visitaAtiva, setVisitaAtiva] = useState([]);
    const [modalNovaVisita, setModalNovaVisita] = useState(false);
    const [modalVisita, setModalVisita] = useState(false);
    const [modalEditarVisita, setModalEditarVisita] = useState(false);
    const [modalAlterarStatus, setModalAlterarStatus] = useState(false);
    const [modalReagendarVisita, setModalReagendarVisita] = useState(false);
    const [modalDeleteVisita, setModalDeleteVisita] = useState(false);
    Moment.globalLocale = 'pt-BR';

    async function getUsers() {
        const user = await UsersService.index();
        setUsers(user.data.filter(({ excluido }) => excluido === false).filter(({ permission }) => permission <= 4))
    }

    async function getVisitas() {
        const visita = await VisitasService.index();
        setVisitas(visita.data.filter(({ excluido }) => excluido === false));
        getDays(visita.data.filter(({ excluido }) => excluido === false));
    }

    async function getDays(array) {
        const todos = await array.filter(filtrarSituacao).map((item) => {return(item.dia)});
        setDays([...new Set(todos)]);
    }

    useEffect(() => {
        getUsers();
        getVisitas();
        
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (visitas.length !== 0) {
            getDays(visitas);
        }
        // eslint-disable-next-line
    }, [btnFiltroAtivo]);

    const createVisita = async event => {
        event.preventDefault();

        const formData = new FormData(event.target);
        let data = Object.fromEntries(formData);
        data.inicio = new Date(`${data.dia} ${data.inicio}`);
        data.fim = new Date(`${data.dia} ${data.fim}`);
        data.status = [{ }];
        
        try {
            await VisitasService.create(data);
            getVisitas();
            setModalNovaVisita(false);
        } catch (error) {
            console.log(error)
        }
    };

    const editVisita = async event => {
        event.preventDefault();

        const formData = new FormData(event.target);
        let data = Object.fromEntries(formData);
        
        if (data.inicio === "") {
            delete data.inicio;
        } else {
            if (data.dia === "") {
                const dateDiaAtual = new Date(visitaAtiva.dia);
                data.inicio = new Date(`${dateDiaAtual.getUTCFullYear().toString()} ${dateDiaAtual.getUTCMonth().toString()+1} ${dateDiaAtual.getUTCDate().toString()} ${data.inicio}`);
            } else {
                data.inicio = new Date(`${data.dia} ${data.inicio}`);
            };
        };

        if (data.fim === "") {
            delete data.fim;
        } else {
            if (data.dia === "") {
                const dateDiaAtual = new Date(visitaAtiva.dia);
                data.fim = new Date(`${dateDiaAtual.getUTCFullYear().toString()} ${dateDiaAtual.getUTCMonth().toString()} ${dateDiaAtual.getUTCDate().toString()} ${data.fim}`);
            } else {
                data.fim = new Date(`${data.dia} ${data.fim}`);
            };
        };

        if (data.dia === "") {
            delete data.dia;
        };

        try {
            await VisitasService.edit(visitaAtiva._id, data);
            getVisitas();
            setModalEditarVisita(false);
        } catch (error) {
            console.log(error);
        };
    };

    async function editStatusVisita(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let data = Object.fromEntries(formData);
        data.dataAlteracao = new Date();
        const status = data;
        
        try {
            await VisitasService.alterar_status(visitaAtiva._id, {status});
            getVisitas();
            setModalVisita(false);
            setModalAlterarStatus(false);
        } catch (error) {
            console.log(error);
        }
    };

    async function reagendarVisita(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let data = Object.fromEntries(formData);
        data.inicio = new Date(`${data.dia} ${data.inicio}`);
        data.fim = new Date(`${data.dia} ${data.fim}`);
        let corrigido = [data]
        corrigido.push({status: {dataAlteracao: new Date(), situacao: "Não Concluído"}});
        
        try {
            await VisitasService.reagendar(visitaAtiva._id, corrigido);
            getVisitas();
            setModalVisita(false);
            setModalReagendarVisita(false);
        } catch (error) {
            console.log(error);
        }
    }

    async function desativarVisita() {
        await VisitasService.desativar(visitaAtiva._id);
        getVisitas();
        setModalDeleteVisita(false);
    };

    function filtrarSituacao(a) {
        switch (true) {
            case btnFiltroAtivo === 'n-concluidos':
                return a.status.at(-1).situacao === "Não Concluído" || a.status.at(-1).situacao === "Cadastrado";
            case btnFiltroAtivo === 'concluidos':
                return a.status.at(-1).situacao === "Concluído";
            case btnFiltroAtivo === 'cancelados':
                return a.status.at(-1).situacao === "Cancelado";
            case btnFiltroAtivo === 'reagendar':
                return a.status.at(-1).situacao === "Reagendar";
            case btnFiltroAtivo === 'todos':
                return a;
            default:
            break;
        }
    }

    function separarDias(a,b){
        return b > a ? -1 : b < a ? 1 : 0;
    }

    function separarHorario(a,b){
        return b.inicio > a.inicio ? -1 : b.inicio < a.inicio ? 1 : 0;
    }

    document.title = `Visitas - JMX Corretora de Seguros`;

    return(
        <Fragment>
            { visitas.length !== 0 ?
            <main id='main-visitas'>
                <div className="conteudo-visitas">
                    <div className="cabecalho-conteudo">
                        <h2>Visitas</h2>
                        <div className="buttons-conteudo">
                            <button className="button-red" onClick={()=> navigate(`/administracao`)}>Voltar</button>
                            <button className="button-blue" onClick={() => {window.print()}}>Imprimir</button>
                            <button className="button-green" onClick={() => setModalNovaVisita(true)}>Nova visita</button>
                        </div>
                    </div>
                    <div className="opcoes-agenda">
                    </div>
                    <div className='conteudo-agenda'>
                        <div className='categoria-ativa'>
                            <button onClick={() => {setBtnFiltroAtivo('todos')}} className={ btnFiltroAtivo === 'todos' ? 'btn-ativo' : null}>Todos</button>
                            <button onClick={() => {setBtnFiltroAtivo('n-concluidos')}} className={ btnFiltroAtivo === 'n-concluidos' ? 'btn-ativo' : null}>Não Concluidos</button>
                            <button onClick={() => {setBtnFiltroAtivo('concluidos')}} className={ btnFiltroAtivo === 'concluidos' ? 'btn-ativo' : null}>Concluidos</button>
                            <button onClick={() => {setBtnFiltroAtivo('cancelados')}} className={ btnFiltroAtivo === 'cancelados' ? 'btn-ativo' : null}>Cancelados</button>
                            <button onClick={() => {setBtnFiltroAtivo('reagendar')}} className={ btnFiltroAtivo === 'reagendar' ? 'btn-ativo' : null}>Reagendar</button>
                        </div>
                        <div className="lista-agenda">
                            {days.length === 0 ? (<div className='item-vazio'>Nenhuma visita disponível.</div>)
                            : days.sort(separarDias).map((day, index) => (
                                <div key={index} className='item-agenda'>
                                    <h3><Moment add={{ hours: 3 }} format="DD [de] MMMM">{day}</Moment></h3>
                                    {visitas.filter(filtrarSituacao).sort(separarHorario).filter(({ dia }) => dia === day).map((item, index) => (
                                        <div key={index} className='row-agenda' onClick={() => {setModalVisita(true); setVisitaAtiva(item)}}>
                                            <div className={item.status.at(-1).situacao === "Não Concluído" || item.status.at(-1).situacao === "Cadastrado" ? "horario-agenda visita-n-concluida" : (item.status.at(-1).situacao === "Concluído" ? "horario-agenda visita-concluida" : item.status.at(-1).situacao === "Cancelado" ? "horario-agenda visita-cancelado" : item.status.at(-1).situacao === "Reagendar" && "horario-agenda visita-reagendar")}>
                                                <h4><Moment format='HH:mm'>{item.inicio}</Moment></h4>
                                                <h5><Moment format='HH:mm'>{item.fim}</Moment></h5>
                                            </div>
                                            <div className='informacoes-agenda'>
                                                <h4>{item.status.at(-1).situacao === "Reagendar" ? <span className="span-reagendar"><FontAwesomeIcon icon={faRotateRight}/></span> : (item.status.at(-1).situacao === "Concluído" ? <span className="span-concluido"><FontAwesomeIcon icon={faCheck}/></span> : item.status.at(-1).situacao === "Cancelado" && <span className="span-cancelado"><FontAwesomeIcon icon={faBan}/></span>)}{item.empresa}</h4>
                                                <h5>{item.descricao}</h5>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </main>
            :
            <div className='loading'>
                <div className='load-circle'>
                    
                </div>
            </div>
            }
            {modalNovaVisita ? (
                <Modal titulo="Nova Visita" onClose={() => {setModalNovaVisita(false)}}>
                    <form onSubmit={createVisita}>
                        <div className="campos-form-visita">
                            <div className="campo-form campo-60">
                                <label htmlFor="empresa">Empresa:</label>
                                <input type="text" name="empresa" id="empresa" required/>
                            </div>
                            <div className="campo-form campo-40">
                                <label htmlFor="visitador">Visitante:</label>
                                <select name="visitador" id="visitador">
                                    <option value="default">Selecione</option>
                                    {users.map((item, index) => (
                                        <option key={index} value={item._id}>{item.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="campo-form campo-70">
                                <label htmlFor="endereco">Endereço da Empresa:</label>
                                <input type="text" name="endereco" id="endereco" required/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="anfitriao">Falar com:</label>
                                <input type="text" name="anfitriao" id="anfitriao" required/>
                            </div>
                            <div className="campo-form campo-100">
                                <label htmlFor="descricao">Descrição:</label>
                                <textarea type="text" name="descricao" id="descricao" rows="3"/>
                            </div>
                            <div className="campo-form campo-40">
                                <label htmlFor="dia">Dia da Visita:</label>
                                <input type="date" name="dia" id="dia" required/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="inicio">Horário de início:</label>
                                <input type="time" name="inicio" id="inicio" required/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="fim">Horário de saída:</label>
                                <input type="time" name="fim" id="fim"/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalNovaVisita(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Cadastrar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalVisita ? (
                <Modal titulo="Informações da visita" onClose={() => {setModalVisita(false)}}>
                    <div className="info-modal">
                        <div className="campo-info campo-60">
                            <h4>Empresa:</h4>
                            <h5>{visitaAtiva.empresa}</h5>
                        </div>
                        <div className="campo-info campo-40">
                            <h4>Visitador:</h4>
                            <h5>{visitaAtiva.visitador.name}</h5>
                        </div>
                        <div className="campo-info campo-80">
                            <h4>Endereço:</h4>
                            <h5>{visitaAtiva.endereco}</h5>
                        </div>
                        <div className="campo-info campo-20">
                            <h4>Falar com:</h4>
                            <h5>{visitaAtiva.anfitriao}</h5>
                        </div>
                        <div className="campo-info campo-100">
                            <h4>Descrição:</h4>
                            <h5>{visitaAtiva.descricao}</h5>
                        </div>
                        <div className="campo-info campo-40">
                            <h4>Dia da Visita:</h4>
                            <h5><Moment add={{ hours: 3 }} format='DD/MM/YYYY'>{visitaAtiva.dia}</Moment></h5>
                        </div>
                        <div className="campo-info campo-30">
                            <h4>Horário de início:</h4>
                            <h5><Moment format='HH:mm'>{visitaAtiva.inicio}</Moment></h5>
                        </div>
                        <div className="campo-info campo-30">
                            <h4>Horário de saída:</h4>
                            <h5><Moment format='HH:mm'>{visitaAtiva.fim}</Moment></h5>
                        </div>
                        <h3>Histórico</h3>
                        <div className="campo-info campo-100">
                            <h4>- Criado por <span>{visitaAtiva.created_by.name}</span> em <Moment format='DD/MM/YY [às] HH:mm'>{visitaAtiva.created_at}</Moment></h4>
                            {visitaAtiva.status.map((item, index) => (
                                <h4 key={index}>
                                    {item.situacao !== "Cadastrado" ? <Fragment> - <span>{item.changed_by.name}</span> alterou o status para <span>{item.situacao}</span> em <Moment format='DD/MM/YY [às] HH:mm'>{item.dataAlteracao}</Moment> { item.hasOwnProperty('observacao') ? item.observacao !== "" ? <span className='span-light'>({item.observacao})</span> : null : null}</Fragment> : null}
                                </h4>
                            ))}
                        </div>
                    </div>
                    <div className='buttons-info'>
                        <button type="reset" className="button-red" onClick={() => {setModalVisita(false)}}>Cancelar</button>
                        <button type="button" className="button-darkred" onClick={() => {setModalVisita(false); setModalDeleteVisita(true)}}>Excluir</button>
                        { visitaAtiva.status.at(-1).situacao === "Cancelado" || visitaAtiva.status.at(-1).situacao === "Concluído" ? null : <button type="button" className="button-cyan" onClick={() => {setModalVisita(false); setModalEditarVisita(true)}}>Editar</button>}
                        { visitaAtiva.status.at(-1).situacao === "Reagendar" ? <button type="button" className="button-green" onClick={() => { setModalReagendarVisita(true)}}>Reagendar</button> : visitaAtiva.status.at(-1).situacao === "Cancelado" || visitaAtiva.status.at(-1).situacao === "Concluído" ? null : <button type="button" className="button-blue" onClick={() => { setModalAlterarStatus(true)}}>Alterar Status</button>}
                    </div>
                </Modal>
            ): null}
            {modalEditarVisita ? (
                <Modal titulo="Editar Seguradora" onClose={() => {setModalEditarVisita(false)}}>
                    <form onSubmit={editVisita}>
                        <div className="campos-form-visita">
                            <div className="campo-form campo-60">
                                <label htmlFor="empresa">Empresa:</label>
                                <input type="text" name="empresa" id="empresa" defaultValue={visitaAtiva.empresa}/>
                            </div>
                            <div className="campo-form campo-40">
                                <label htmlFor="visitador">Visitante:</label>
                                <select name="visitador" id="visitador" defaultValue={visitaAtiva.visitador._id}>
                                    <option value="default">Selecione</option>
                                    {users.map((item, index) => (
                                        <option key={index} value={item._id}>{item.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="campo-form campo-70">
                                <label htmlFor="endereco">Endereço da Empresa:</label>
                                <input type="text" name="endereco" id="endereco" defaultValue={visitaAtiva.endereco}/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="anfitriao">Falar com:</label>
                                <input type="text" name="anfitriao" id="anfitriao" defaultValue={visitaAtiva.anfitriao}/>
                            </div>
                            <div className="campo-form campo-100">
                                <label htmlFor="descricao">Descrição:</label>
                                <textarea type="text" name="descricao" id="descricao" rows="3" defaultValue={visitaAtiva.descricao}/>
                            </div>
                            <div className="campo-form campo-40">
                                <label htmlFor="dia">Dia da Visita:</label>
                                <input type="date" name="dia" id="dia" defaultValue={visitaAtiva.dia}/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="inicio">Horário de início:</label>
                                <input type="time" name="inicio" id="inicio" defaultValue={visitaAtiva.inicio}/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="fim">Horário de saída:</label>
                                <input type="time" name="fim" id="fim" defaultValue={visitaAtiva.fim}/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalEditarVisita(false); setModalVisita(true)}}>Cancelar</button>
                            <button type="submit" className="button-green">Cadastrar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalAlterarStatus ? (
                <Modal titulo="Alterar Status" width="35%" onClose={() => {setModalAlterarStatus(false)}}>
                    <form onSubmit={editStatusVisita}>
                        <div id="campos-nova-visita">
                            <div className="campo-form campo-100">
                                <label htmlFor="situacao">Situação</label>
                                <select name="situacao" id="situacao">
                                    <option value='default'>Selecione</option>
                                    <option value='Não Concluído'>Não Concluído</option>
                                    <option value='Reagendar'>Reagendar</option>
                                    <option value='Cancelado'>Cancelado</option>
                                    <option value='Concluído'>Concluído</option>
                                </select>
                            </div>
                            <div className="campo-form campo-100">
                                <label htmlFor="observacao">Observação</label>
                                <input type="text" id='observacao' name="observacao"/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalAlterarStatus(false); setModalVisita(true)}}>Cancelar</button>
                            <button type="submit" className="button-green">Salvar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalReagendarVisita ? (
                <Modal titulo="Reagendar Visita" width="40%" onClose={() => {setModalReagendarVisita(false)}}>
                    <form onSubmit={reagendarVisita}>
                        <div className="campos-form-visita">
                            <div className="campo-form campo-40">
                                <label htmlFor="dia">Dia da Visita</label>
                                <input type="date" name="dia" id="dia"/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="inicio">Horário de início</label>
                                <input type="time" name="inicio" id="razao"/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="fim">Horário de saída</label>
                                <input type="time" name="fim" id="fim"/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalReagendarVisita(false); setModalVisita(true)}}>Cancelar</button>
                            <button type="submit" className="button-green">Salvar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalDeleteVisita ? (
                <ModalDangerSmall mensagem={['Deseja excluir a visita à empresa', <br/>,<span>{visitaAtiva.empresa}</span>,"?"]} onClose={() => {setModalDeleteVisita(false)}}>
                    <Fragment>
                        <div className='buttons-form'>
                            <button type="button" className="button-red" onClick={() => {setModalDeleteVisita(false); setModalVisita(true)}}>Cancelar</button>
                            <button type="submit" className="button-blue" onClick={() => {desativarVisita()}}>Excluir</button>
                        </div>
                    </Fragment>
                </ModalDangerSmall>
            ): null}
        </Fragment>
    )
};