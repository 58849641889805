import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faSearch } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';
import 'moment-timezone';
import "../../../styles/empresas.scss";
import "../../../styles/reset.scss";
import Modal from "../../layouts/modal";
import EmpresasService from '../../../services/empresas';
import jsonp from 'jsonp';
import Api from '../../../services/api';

export default function ContentAdministracaoEmpresas() {
    const navigate = useNavigate();
    const [empresas, setEmpresas] = useState([]);
    const [cnpj, setCnpj] = useState([]);
    const [disabledAdm, setDisabledAdm] = useState(false);
    const [modalNovaEmpresa, setModalNovaEmpresa] = useState(false);
    const [modalDoc, setModalDoc] = useState(false);

    async function getCnpj(cnpj) {
        jsonp(`https://receitaws.com.br/v1/cnpj/${cnpj}`, null, function (err, data) {
            if (err) {
                console.error(err.message);
            } else {
                console.log(data)
                setCnpj(data);
            }
        });
    }

    async function empresaAdministracao(event) {
        if (event.target.value === "true") {
            setDisabledAdm(true)
        } else{
            setDisabledAdm(false);
        }
    }

    async function getEmpresas() {
        const data = await EmpresasService.index();
        setEmpresas(data.data); 
    }

    async function createEmpresa(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let form = Object.fromEntries(formData);
        // form.data = new Date(`${form.data} ${form.horario}`);
        
        if (form.empresa_administracao === "true") {
            form.empresa_administracao = true;
        } else {
            form.empresa_administracao = false;
        }

        // delete form.horario;

        try {
            console.log(form)
            await EmpresasService.create(form);
            await getEmpresas();
            setModalNovaEmpresa(false);
        } catch (error) {
            console.log(error)
        }
    }

    // const [selectedFile, setSelectedFile] = React.useState(null);

    async function enviarDoc(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        // formData.append("selectedFile", selectedFile);
        let form = Object.fromEntries(formData);
        // form.data = new Date(`${form.data} ${form.horario}`);
        
        // if (form.empresa_administracao === "true") {
        //     form.empresa_administracao = true;
        // } else {
        //     form.empresa_administracao = false;
        // }

        // delete form.horario;

        try {
            // console.log(formData)
            await Api.post('/empresas/upload', form, { 
                headers: {
                    'Content-Type': `multipart/form-data; boundary=${form._boundary}`
                }
            });
            // await getHistoricos();
            // setModalCadastrarHistorico(false);
        } catch (error) {
            console.log(error)
        }
    }

    // const handleFileSelect = (event) => {
    //     setSelectedFile(event.target.files[0])
    //   }

    useEffect(() => {
        getEmpresas();
    }, []);
    
    const empresasAtivas = empresas?.filter(({ excluido }) => excluido === false);
    
    document.title = `Empresas - JMX Corretora de Seguros`;

    return(
        <Fragment>
            <main id='main-empresas'>
                <div className="conteudo-sinistros">
                    <div className="cabecalho-conteudo">
                        <h2>Empresas</h2>
                        <div className="buttons-conteudo">
                            <button className="button-red" onClick={()=> navigate(`/administracao`)}>Voltar</button>
                            <button className="button-blue" onClick={() => {setModalDoc(true)}}>Imprimir</button>
                            <button className="button-green add-sinistro" onClick={()=> setModalNovaEmpresa(true)}>Nova empresa</button>
                        </div>
                    </div>
                    <div className='container-search'>
                        <input type='text' placeholder='Buscar'/>
                        <FontAwesomeIcon icon={faSearch}/>
                    </div>
                    <div className="tabela-dados">
                        <div className="cabecalho-dados">
                            <div className="column-15">CNPJ</div>
                            <div className="column-35">Nome Fantasia</div>
                            <div className="column-40">Razão Social</div>
                            <div className="column-10">Cadastro</div>
                        </div>
                        <div className="corpo-dados corpo-empresas">
                            { empresasAtivas.length === 0 ? 
                                <div className="row-dados">
                                    <div className="column-zerado">
                                        Não foi encontrado nenhum resultado.
                                    </div>
                                </div>
                                : (
                                    (empresasAtivas.slice(0).reverse().map((item, index) => (
                                        <Fragment>
                                            <div key={index} className="row-dados" onClick={() => navigate(`/administracao/empresas/${item._id}`)}>
                                                <div className="column-15">
                                                    {item.cnpj}
                                                </div>
                                                <div className="column-35">
                                                    {item.fantasia}
                                                </div>
                                                <div className="column-40">
                                                    {item.razao_social}
                                                </div>
                                                <div className="column-10">
                                                    <Moment format="DD/MM/YYYY">
                                                        {item.created_at}
                                                    </Moment>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )))
                                )
                            }
                            <h5>Exibindo {empresasAtivas.length} {empresasAtivas.length === 1 ? "resultado" : "resultados"}</h5>
                        </div>
                    </div>
                </div>
            </main>
            {modalNovaEmpresa ? (
                <Modal titulo="Nova Empresa" width="70%" onClose={() => {setModalNovaEmpresa(false); setCnpj("")}}>
                    <form onSubmit={createEmpresa}>
                        <div id="campos-nova-seguradora">
                            <div className="campo-form campo-15">
                                <label htmlFor="empresa_administracao">Administração?</label>
                                <select name='empresa_administracao' id='empresa_administracao' onChange={(e) => {empresaAdministracao(e)}}>
                                    <option value="default">Selecione</option>
                                    <option value="false">Não</option>
                                    <option value="true">Sim</option>
                                </select>
                            </div>
                            <div className="campo-form campo-35">
                                <label htmlFor="apolice-empresa">Apólice</label>
                                <select name='apolice' id='apolice-empresa' disabled={disabledAdm}>
                                    <option value="default">Selecione</option>
                                </select>
                            </div>
                            <div className="campo-form campo-15">
                                <label htmlFor="primeiro_faturamento">1° Faturamento</label>
                                <input type="text" name="primeiro_faturamento" id="primeiro_faturamento" disabled={disabledAdm}/>
                            </div>
                            <div className="campo-form campo-15">
                                <label htmlFor="primeiro_vencimento">1° Vencimento</label>
                                <input type="text" name="primeiro_vencimento" id="primeiro_vencimento" disabled={disabledAdm}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="premio">Prêmio Individual</label>
                                <input type="text" name="premio" id="premio" disabled={disabledAdm}/>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="cnpj">CNPJ</label>
                                <input onBlur={(event) =>  (event.target.value.length !== 0 ? getCnpj(event.target.value) : setCnpj(""))} type="text" name="cnpj" id="cnpj"/>
                            </div>
                            <div className="campo-form campo-40">
                                <label htmlFor="razao_social">Razão Social</label>
                                <input type="text" name="razao_social" id="razao_social" defaultValue={cnpj["nome"]}/>
                            </div>
                            <div className="campo-form campo-35">
                                <label htmlFor="fantasia">Fantasia</label>
                                <input type="text" name="fantasia" id="fantasia" defaultValue={cnpj["fantasia"] === "" ? cnpj["nome"] : cnpj["fantasia"]}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="telefone">Telefone</label>
                                <input type="text" name="telefone" id="telefone"/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="telefone_secundario">Telefone <span>Secundário</span></label>
                                <input type="text" name="telefone_secundario" id="telefone_secundario"/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="celular">Celular</label>
                                <input type="text" name="celular" id="celular"/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="email">Email</label>
                                <input type="text" name="email" id="email" defaultValue={cnpj["email"]}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="email_secundario">Email <span>Secundário</span></label>
                                <input type="text" name="email_secundario" id="email_secundario"/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="cnae">CNAE</label>
                                <input type="text" name="cnae" id="cnae" defaultValue={cnpj.length === 0 ? null : `${cnpj?.atividade_principal[0].code}`}/>
                                {/*  */}
                                {/* <select onChange={(event) => {setCnaeId(event.target.value)}}> */}
                                    {/* {cnae.map((item, index) => (
                                        <option key={index} value={item.id}>{item.id}</option>
                                    ))} */}
                                {/* </select> */}
                            </div>
                            <div className="campo-form campo-70">
                                <label htmlFor="descricao_cnae">Descrição CNAE</label>
                                <input type="text" name="descricao_cnae" id="descricao_cnae" defaultValue={cnpj.length === 0 ? null : `${cnpj?.atividade_principal[0].text}`}/>
                                {/*  */}
                            </div>
                            <div className="campo-form campo-60">
                                <label htmlFor="representante_legal">Responsável Legal</label>
                                <input type="text" name="representante_legal" id="representante_legal" required/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="representante_legal_telefone">Telefone</label>
                                <input type="text" name="representante_legal_telefone" id="representante_legal_telefone" required/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="representante_legal_email">Email</label>
                                <input type="text" name="representante_legal_email" id="representante_legal_email" required/>
                            </div>
                            <div className="campo-form campo-60">
                                <label htmlFor="representante_financeiro">Responsável Financeiro</label>
                                <input type="text" name="representante_financeiro" id="representante_financeiro" required/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="representante_financeiro_telefone">Telefone</label>
                                <input type="text" name="representante_financeiro_telefone" id="representante_financeiro_telefone" required/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="representante_financeiro_email">Email</label>
                                <input type="text" name="representante_financeiro_email" id="representante_financeiro_email" required/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="cep">CEP</label>
                                <input type="text" name="cep" id="cep" defaultValue={cnpj["cep"]}/>
                            </div>
                            <div className="campo-form campo-60">
                                <label htmlFor="logradouro">Logradouro</label>
                                <input type="text" name="logradouro" id="logradouro" defaultValue={cnpj.length === 0 ? null : `${cnpj["logradouro"]}`}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="numero">Número</label>
                                <input type="text" name="numero" id="numero" defaultValue={cnpj["numero"]}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="complemento">Complemento</label>
                                <input type="text" name="complemento" id="complemento" defaultValue={cnpj["complemento"]}/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="bairro">Bairro</label>
                                <input type="text" name="bairro" id="bairro" defaultValue={cnpj["bairro"]}/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="cidade">Cidade</label>
                                <input type="text" name="cidade" id="cidade" defaultValue={cnpj["municipio"]}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="estado">Estado</label>
                                <select name="estado" id="estado" defaultValue={cnpj["uf"]}>
                                    <option value="default">Selecione</option>
                                    <option value="AC">Acre</option>
                                    <option value="AL">Alagoas</option>
                                    <option value="AM">Amazonas</option>
                                    <option value="AP">Amapá</option>
                                    <option value="BA">Bahia</option>
                                    <option value="CE">Ceará</option>
                                    <option value="DF">Distrito Federal</option>
                                    <option value="ES">Espirito Santo</option>
                                    <option value="GO">Goiás</option>
                                    <option value="MA">Maranhão</option>
                                    <option value="MT">Mato Grosso</option>
                                    <option value="MS">Mato Grosso do Sul</option>
                                    <option value="MG">Minas Gerais</option>
                                    <option value="PA">Pará</option>
                                    <option value="PB">Paraíba</option>
                                    <option value="PR">Paraná</option>
                                    <option value="PE">Pernambuco</option>
                                    <option value="PI">Piauí</option>
                                    <option value="RJ">Rio de Janeiro</option>
                                    <option value="RN">Rio Grande do Norte</option>
                                    <option value="RS">Rio Grande do Sul</option>
                                    <option value="SC">Santa Catarina</option>
                                    <option value="SP">São Paulo</option>
                                    <option value="SE">Sergipe</option>
                                    <option value="RO">Rondônia</option>
                                    <option value="RR">Roraima</option>
                                    <option value="TO">Tocantins</option>
                                </select>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalNovaEmpresa(false); setCnpj("")}}>Cancelar</button>
                            <button type="submit" className="button-green">Cadastrar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalDoc ? (
                <Modal titulo="Nova Empresa" width="70%" onClose={() => {setModalNovaEmpresa(false); setCnpj("")}}>
                    <form onSubmit={enviarDoc}>
                        <div id="campos-nova-seguradora">
                            <input type='file' name='testf'></input>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalNovaEmpresa(false); setCnpj("")}}>Cancelar</button>
                            <button type="submit" className="button-green">Cadastrar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
        </Fragment>
    )
};