import { Fragment } from 'react';
import Header from '../../components/header/index';
import ContentAdministracaoApolices from '../../components/administracao/apolices';

const ApolicesScreen = (props) => (
  <Fragment>
    <Header ativo={props.ativo}/>
    <ContentAdministracaoApolices/>
  </Fragment>
)

export default ApolicesScreen;