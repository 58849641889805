import React, { Fragment } from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivateRoute from './components/auth/private_route';
import LoginScreen from './screens/login/loginScreen';
import HomeScreen from './screens/home/homeScreen';
import CorretoraScreen from './screens/corretora/corretoraScreen';
import SinistrosScreen from './screens/corretora/sinistrosScreen';
import SinistroScreen from './screens/corretora/sinistroScreen';
import HistoricosScreen from './screens/corretora/historicosScreen';
import VidasScreen from './screens/vidas/vidasScreen';
import FinanceiroScreen from './screens/configuracoes/configuracoesScreen';
import AdministracaoScreen from './screens/administracao/administracaoScreen';
import UsuariosScreen from './screens/administracao/usuariosScreen';
import EmpresasScreen from './screens/administracao/empresasScreen';
import EmpresaScreen from './screens/administracao/empresaScreen';
import SeguradorasScreen from './screens/administracao/seguradorasScreen';
import VisitasScreen from './screens/administracao/visitasScreen';
import SuporteScreen from './screens/administracao/suporteScreen';
import OutrosScreen from './screens/administracao/outrosScreen';
import ExcluidosScreen from './screens/administracao/excluidosScreen';
import ApolicesScreen from './screens/administracao/apolicesScreen';
import SenhasScreen from './screens/administracao/senhasScreen';
import EmailsScreen from "./screens/administracao/emailScreen";
import TestesScreen from "./screens/administracao/testesScreen";
import NotificacoesScreen from "./screens/administracao/notificacaoScreen";
import ConfiguracoesScreen from "./screens/configuracoes/configuracoesScreen";
import ChamadosScreen from "./screens/chamados/chamadosScreen";
import ChamadoScreen from "./screens/chamados/chamadoScreen";
import ChamadosBugScreen from "./screens/chamados/chamadosBugsScreen";
import ChamadosCorrecoesScreen from "./screens/chamados/chamadosCorrecoesScreen";
import ChamadosSugestoesScreen from "./screens/chamados/chamadosSugestoesScreen";
import ChamadosSuporteScreen from "./screens/chamados/chamadosSuporteScreen";
import LogScreen from "./screens/administracao/logScreen";

const App = () => (
  <Fragment>
    <BrowserRouter>
        <Routes>
            <Route exact path='/login' element={<LoginScreen/>} />
            <Route exact path='/' element={<PrivateRoute/>}>
                <Route exact path='/' element={<HomeScreen ativo="inicio"/>}/>
                <Route exact path='/corretora' element={<CorretoraScreen ativo="corretora"/>}/>
                <Route exact path='/corretora/sinistros' element={<SinistrosScreen ativo="corretora"/>}/>
                <Route exact path='/corretora/sinistros/:id' element={<SinistroScreen ativo="corretora"/>}/>
                <Route exact path='/corretora/historicos' element={<HistoricosScreen ativo="corretora"/>}/>
                <Route exact path='/vidas' element={<VidasScreen ativo="vidas"/>}/>
                <Route exact path='/financeiro' element={<FinanceiroScreen ativo="financeiro"/>}/>
                <Route exact path='/administracao' element={<AdministracaoScreen ativo="administracao"/>}/>
                <Route exact path='/administracao/usuarios' element={<UsuariosScreen ativo="administracao"/>}/>
                <Route exact path='/administracao/empresas' element={<EmpresasScreen ativo="administracao"/>}/>
                <Route exact path='/administracao/empresas/:id' element={<EmpresaScreen ativo="administracao"/>}/>
                <Route exact path='/administracao/apolices' element={<ApolicesScreen ativo="administracao"/>}/>
                <Route exact path='/administracao/seguradoras' element={<SeguradorasScreen ativo="administracao"/>}/>
                <Route exact path='/administracao/visitas' element={<VisitasScreen ativo="administracao"/>}/>
                <Route exact path='/administracao/suporte' element={<SuporteScreen ativo="administracao"/>}/>
                <Route exact path='/administracao/log' element={<LogScreen ativo="administracao"/>}/>
                <Route exact path='/administracao/excluidos' element={<ExcluidosScreen ativo="administracao"/>}/>
                <Route exact path='/administracao/banco-de-senhas' element={<SenhasScreen ativo="administracao"/>}/>
                <Route exact path='/administracao/emails' element={<EmailsScreen ativo="administracao"/>}/>
                <Route exact path='/administracao/notificacoes' element={<NotificacoesScreen ativo="administracao"/>}/>
                <Route exact path='/administracao/outros' element={<OutrosScreen ativo="administracao"/>}/>
                <Route exact path='/administracao/testes' element={<TestesScreen ativo="administracao"/>}/>
                <Route exact path='/chamados' element={<ChamadosScreen ativo="chamados"/>}/>
                <Route exact path='/chamados/bugs' element={<ChamadosBugScreen ativo="chamados"/>}/>
                <Route exact path='/chamados/bugs/:id' element={<ChamadoScreen ativo="chamados"/>}/>
                <Route exact path='/chamados/sugestoes' element={<ChamadosSugestoesScreen ativo="chamados"/>}/>
                <Route exact path='/chamados/sugestoes/:id' element={<ChamadoScreen ativo="chamados"/>}/>
                <Route exact path='/chamados/correcoes' element={<ChamadosCorrecoesScreen ativo="chamados"/>}/>
                <Route exact path='/chamados/correcoes/:id' element={<ChamadoScreen ativo="chamados"/>}/>
                <Route exact path='/chamados/suporte' element={<ChamadosSuporteScreen ativo="chamados"/>}/>
                <Route exact path='/chamados/suporte/:id' element={<ChamadoScreen ativo="chamados"/>}/>
                <Route exact path='/configuracoes' element={<ConfiguracoesScreen ativo="config"/>}/>
            </Route>
        </Routes>
    </BrowserRouter>
  </Fragment>
)

export default App;