import { Fragment } from 'react';
import Header from '../../components/header/index';
import ContentSinistro from '../../components/corretora/sinistro';

const SinistroScreen = (props) => (
  <Fragment>
    <Header ativo={props.ativo}/>
    <ContentSinistro/>
  </Fragment>
)

export default SinistroScreen;