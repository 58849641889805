import Api from './api';

const VisitasService = {
    index: () => Api.get('/visitas'),
    create: (params) => Api.post('/visitas', params),
    edit: (id, params) => Api.put(`/visitas/${id}`, params),
    alterar_status: (id, params) => Api.put(`/visitas/${id}/status`, params),
    reagendar: (id, params) => Api.put(`/visitas/${id}/reagendar`, params),
    desativar: (id) => Api.put(`/visitas/${id}/desabilitar`),
    ativar: (id) => Api.put(`/visitas/${id}/habilitar`),
    delete: (id) => Api.delete(`/visitas/${id}`)
}

export default VisitasService;