import Api from './api';

const ApolicesService = {
    index: () => Api.get('/apolices'),
    create: (params) => Api.post('/apolices', params),
    edit: (id, params) => Api.put(`/apolices/${id}`, params),
    desativar: (id) => Api.put(`/apolices/${id}/desabilitar`),
    ativar: (id) => Api.put(`/apolices/${id}/habilitar`),
    delete: (id) => Api.delete(`/apolices/${id}`)
}

export default ApolicesService;