import { Fragment } from 'react';
import Header from '../../components/header/index';
import ContentAdministracaoVisitas from '../../components/administracao/visitas';

const VisitasScreen = (props) => (
  <Fragment>
    <Header ativo={props.ativo}/>
    <ContentAdministracaoVisitas/>
  </Fragment>
)

export default VisitasScreen;