import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulb, faBug, faWrench, faMicrochip } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/pt-br'
import "../../styles/chamados.scss";
import "../../styles/reset.scss";
Moment.globalLocale = 'pt-BR'

export default function ContentChamados() {
    const navigate = useNavigate();

    document.title = `Chamados - JMX Corretora de Seguros`;

    return(
        <Fragment>
            <main className='main'>
                <div className="conteudo-chamados">
                    <div className="cabecalho-conteudo">
                        <h2>Chamados</h2>
                        <div className="buttons-conteudo">
                            <button className="button-blue" onClick={() => {window.print()}}>Imprimir</button>
                        </div>
                    </div>
                    <div className='lista-chamados'>
                        <div className='item-chamados' onClick={() => {navigate('/chamados/bugs')}}>
                            <FontAwesomeIcon icon={faBug}/>
                            <div className='titulo-categoria'>
                                <h2>Bugs</h2>
                                <div className='descricao-categoria'>
                                    <p>Relate bugs encontrados no sistema.</p>
                                </div>
                            </div>
                        </div>
                        <div className='item-chamados' onClick={() => {navigate('/chamados/sugestoes')}}>
                            <FontAwesomeIcon icon={faLightbulb}/>
                            <div className='titulo-categoria'>
                                <h2>Sugestões</h2>
                                <div className='descricao-categoria'>
                                    <p>Abertura de chamados para novas ideias a serem implementadas.</p>
                                </div>
                            </div>
                        </div>
                        <div className='item-chamados' onClick={() => {navigate('/chamados/correcoes')}}>
                            <FontAwesomeIcon icon={faWrench}/>
                            <div className='titulo-categoria'>
                                <h2>Correções</h2>
                                <div className='descricao-categoria'>
                                    <p>Destinado a registrar as correções a serem feitas no sistema.</p>
                                    {/* <div className='item-info-categoria'>
                                        <FontAwesomeIcon icon={faComments}/>
                                        <p>2</p>
                                    </div>
                                    <div className='item-info-categoria'>
                                        <FontAwesomeIcon icon={faFolder}/>
                                        <p>5</p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className='item-chamados' onClick={() => {navigate('/chamados/suporte')}}>
                            <FontAwesomeIcon icon={faMicrochip}/>
                            <div className='titulo-categoria'>
                                <h2>Suporte</h2>
                                <div className='descricao-categoria'>
                                    <p>Chamados para suporte geral relacionado ao sistema ou tecnologias.</p>
                                </div>
                            </div>
                        </div>
                        {/* { treinamentos?.sort(ordemData).filter(({excluido}) => excluido === false).map((item, index) => (
                            <div className='item-treinamento' key={index} onClick={()=> navigate(`/treinamento/${item._id}`)}>
                                <div className='data-treinamento'>
                                    <Moment format='DD'>{item.data}</Moment>
                                    <Moment format='MMMM'>{item.data}</Moment>
                                </div>
                                <div className='informacoes-treinamento'>
                                    <div className='titulo-treinamento'>
                                        <h3>{item.titulo}</h3>
                                        <p>{item.local.nome}</p>
                                        <p>Carga Horária: {item.curso.carga_horaria}h</p>
                                    </div>
                                    <div className='numeros-treinamento'>
                                        <div className='item-numeros-treinamento'>
                                            <FontAwesomeIcon icon={faClock} />
                                            <p><Moment format='HH:mm'>{item.data}</Moment></p>
                                        </div>
                                        <div className='item-numeros-treinamento'>
                                            <FontAwesomeIcon icon={faPerson} />
                                            <p>{item.aluno.length}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))} */}
                    </div>
                </div>
            </main>
            {/* {modalCriarTreinamento ? (
                <Modal titulo="Novo Treinamento" width="50%" onClose={() => {setModalCriarTreinamento(false)}}>
                    <form onSubmit={createTreinamento}>
                        <div id="campos-novo-curso">
                            <div className="campo-form campo-60">
                                <label htmlFor="titulo">Título</label>
                                <input type="titulo" name="titulo" id="titulo"/>
                            </div>
                            <div className="campo-form campo-40">
                                <label htmlFor="curso">Curso</label>
                                <select name="curso" id="curso">
                                    <option value="default">Selecione</option>
                                    { cursos.sort(ordemAlfabetica).map((item, index) => (
                                        <option value={item._id} key={index}>{item.titulo}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="local">Local</label>
                                <select name="local" id="local">
                                    <option value="default">Selecione</option>
                                    { locais.map((item, index) => (
                                        <option value={item._id} key={index}>{item.nome}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="instrutor">Instrutor</label>
                                <select name="instrutor" id="instrutor">
                                    <option value="default">Selecione</option>
                                    { instrutores.map((item, index) => (
                                        <option value={item._id} key={index}>{item.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="data">Data</label>
                                <input type="date" name="data" id="data"/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="horario">Horário</label>
                                <input type="time" name="horario" id="horario"/>
                            </div>
                            <div className="campo-form campo-100">
                                <label htmlFor="descricao">Descrição:</label>
                                <textarea type="text" name="descricao" id="descricao" rows="3"/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalCriarTreinamento(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Cadastrar</button>
                        </div>
                    </form>
                </Modal>
            ): null} */}
        </Fragment>
    )
};