import { Fragment } from 'react';
import Header from '../../components/header/index';
import ContentAdministracaoEmpresas from '../../components/administracao/empresas';

const EmpresasScreen = (props) => (
  <Fragment>
    <Header ativo={props.ativo}/>
    <ContentAdministracaoEmpresas/>
  </Fragment>
)

export default EmpresasScreen;