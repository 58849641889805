import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
// import Moment from 'react-moment';
// import 'moment-timezone';
import "../../../styles/seguradoras.scss";
import "../../../styles/reset.scss";
import Api from '../../../services/api';
import SeguradorasService from '../../../services/seguradoras';
import Modal from "../../layouts/modal";
import ModalDangerSmall from '../../layouts/modalDangerSmall';

export default function ContentAdministracaoSeguradoras() {
    const navigate = useNavigate();
    const [seguradoras, setSeguradoras] = useState([]);
    const [seguradoraAtiva, setSeguradoraAtiva] = useState();
    const [modalNovaSeguradora, setModalNovaSeguradora] = useState(false);
    const [modalSeguradora, setModalSeguradora] = useState(false);
    const [modalEditarSeguradora, setModalEditarSeguradora] = useState(false);
    const [modalDeleteSeguradora, setModalDeleteSeguradora] = useState(false);

    function getSeguradoras() {
        Api.get('/seguradoras').then(({ data }) => {
            setSeguradoras(data?.filter(({ excluido }) => excluido === false));
        });
    };

    useEffect(() => {
        getSeguradoras();
    }, []);

    const createSeguradora = async event => {
        event.preventDefault();

        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData);
        
        try {
            await SeguradorasService.create(data);
            getSeguradoras();
            setModalNovaSeguradora(false);
        } catch (error) {
            console.log(error)
        }
    };

    const editSeguradora = async event => {
        event.preventDefault();

        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData);
        
        try {
            await SeguradorasService.edit(seguradoraAtiva._id, data);
            getSeguradoras();
            setModalEditarSeguradora(false);
        } catch (error) {
            console.log(error)
        }
    };

    async function desativarSeguradora() {
        await SeguradorasService.desativar(seguradoraAtiva._id);
        getSeguradoras();
        setModalDeleteSeguradora(false);
    };

    function getSeguradoraAtiva(id) {
        setSeguradoraAtiva(seguradoras.filter(({ _id }) => _id === id)[0])
    };

    document.title = `Seguradoras - JMX Corretora de Seguros`;

    return(
        <Fragment>
            <main id='main-seguradoras'>
                <div className="conteudo-sinistros">
                    <div className="cabecalho-conteudo">
                        <h2>Seguradoras</h2>
                        <div className="buttons-conteudo">
                            <button className="button-red" onClick={()=> navigate(`/administracao`)}>Voltar</button>
                            <button className="button-blue" onClick={() => {window.print()}}>Imprimir</button>
                            <button className="button-green" onClick={() => setModalNovaSeguradora(true)}>Nova seguradora</button>
                        </div>
                    </div>
                    <div className="filtro-conteudo">
                        <input type="text" name="src-geral-sinistros" id="src-geral-sinistro" placeholder="Faça sua busca aqui"/>
                        <button className="filtro-avancado">Filtro Avançado</button>
                        <button className="filtro-buscar button-green">
                        <FontAwesomeIcon icon={faMagnifyingGlass}/>
                        </button>
                    </div>
                    <div className="tabela-dados">
                        <div className="cabecalho-dados">
                            <div className="column-45">Razão Social</div>
                            <div className="column-15">CNPJ</div>
                            <div className="column-15">Telefone</div>
                            <div className="column-25">E-mail</div>
                        </div>
                        <div className="corpo-dados corpo-seguradoras">
                            { seguradoras.length === 0 ? 
                                <div className="row-dados">
                                    <div className="column-zerado">
                                        Não foi encontrado nenhum resultado.
                                    </div>
                                </div>
                                : (
                                    (seguradoras.slice(0).reverse().map((item, index) => (
                                        <Fragment>
                                            <div key={index} className="row-dados" onClick={() => {setModalSeguradora(true); getSeguradoraAtiva(item._id)}}>
                                                <div className="column-45">
                                                    {item.razao}
                                                </div>
                                                <div className="column-15">
                                                    {item.cnpj}
                                                </div>
                                                <div className="column-15">
                                                    {item.telefone}
                                                </div>
                                                <div className="column-25">
                                                    {item.email}
                                                </div>
                                            </div>
                                        </Fragment>
                                    )))
                                )
                            }
                            <h5>Exibindo {seguradoras.length} {seguradoras.length === 1 ? "resultado" : "resultados"}</h5>
                        </div>
                    </div>
                    {/* <div className="lista-dados">
                        <div className="cabecalho-dados">
                            <div className="column-razao-social">Razão Social</div>
                            <div className="column-cnpj">CNPJ</div>
                            <div className="column-telefone">Telefone</div>
                            <div className="column-email">E-mail</div>
                        </div>
                        <div className="corpo-dados">
                            {seguradoras?.slice(0).reverse().map((seguradoras) => (
                                <div key={seguradoras._id} className="row-empresas">
                                    <div className="column-razao-social">
                                        {seguradoras.razao}
                                    </div>
                                    <div className="column-cnpj">
                                        {seguradoras.cnpj}
                                    </div>
                                    <div className="column-telefone">
                                        {seguradoras.telefone}
                                    </div>
                                    <div className="column-email">
                                        {seguradoras.email}
                                    </div>
                                </div>
                            ))}
                            <h5>Exibindo {seguradoras.filter(({ excluido }) => excluido === false).length} {seguradoras.filter(({ excluido }) => excluido === false).length === 1 ? "resultado" : "resultados"}</h5>
                        </div>
                    </div> */}
                </div>
            </main>
            {modalNovaSeguradora ? (
                <Modal titulo="Nova Seguradora" onClose={() => {setModalNovaSeguradora(false)}}>
                    <form onSubmit={createSeguradora}>
                        <div id="campos-nova-seguradora">
                            <div className="campo-form campo-30">
                                <label htmlFor="cnpj">CNPJ</label>
                                <input type="text" name="cnpj" id="cnpj" required/>
                            </div>
                            <div className="campo-form campo-70">
                                <label htmlFor="razao">Razão Social</label>
                                <input type="text" name="razao" id="razao" required/>
                            </div>
                            <div className="campo-form campo-45">
                                <label htmlFor="fantasia">Nome Fantasia</label>
                                <input type="text" name="fantasia" id="fantasia"/>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="telefone">Telefone</label>
                                <input type="text" name="telefone" id="telefone" required/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="email">E-mail</label>
                                <input type="text" name="email" id="email" required/>
                            </div>
                            <h4>Representante</h4>
                            <div className="campo-form campo-45">
                                <label htmlFor="representante">Nome</label>
                                <input type="text" name="representante" id="representante"/>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="celularRep">Celular</label>
                                <input type="text" name="celularRep" id="celularRep"/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="emailRep">E-mail</label>
                                <input type="text" name="emailRep" id="emailRep"/>
                            </div>
                            <h4>Endereço</h4>
                            <div className="campo-form campo-20">
                                <label htmlFor="cep">CEP</label>
                                <input type="text" name="cep" id="cep" required/>
                            </div>
                            <div className="campo-form campo-60">
                                <label htmlFor="endereco">Logradouro</label>
                                <input type="text" name="endereco" id="endereco" required/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="numero">Número</label>
                                <input type="text" name="numero" id="numero" required/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="complemento">Complemento</label>
                                <input type="text" name="complemento" id="complemento"/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="bairro">Bairro</label>
                                <input type="text" name="bairro" id="bairro"/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="cidade">Cidade</label>
                                <input type="text" name="cidade" id="cidade" required/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="estado">Estado</label>
                                <select name="estado" id="estado">
                                    <option value="default">Selecione</option>
                                    <option value="AC">Acre</option>
                                    <option value="AL">Alagoas</option>
                                    <option value="AM">Amazonas</option>
                                    <option value="AP">Amapá</option>
                                    <option value="BA">Bahia</option>
                                    <option value="CE">Ceará</option>
                                    <option value="DF">Distrito Federal</option>
                                    <option value="ES">Espirito Santo</option>
                                    <option value="GO">Goiás</option>
                                    <option value="MA">Maranhão</option>
                                    <option value="MT">Mato Grosso</option>
                                    <option value="MS">Mato Grosso do Sul</option>
                                    <option value="MG">Minas Gerais</option>
                                    <option value="PA">Pará</option>
                                    <option value="PB">Paraíba</option>
                                    <option value="PR">Paraná</option>
                                    <option value="PE">Pernambuco</option>
                                    <option value="PI">Piauí</option>
                                    <option value="RJ">Rio de Janeiro</option>
                                    <option value="RN">Rio Grande do Norte</option>
                                    <option value="RS">Rio Grande do Sul</option>
                                    <option value="SC">Santa Catarina</option>
                                    <option value="SP">São Paulo</option>
                                    <option value="SE">Sergipe</option>
                                    <option value="RO">Rondônia</option>
                                    <option value="RR">Roraima</option>
                                    <option value="TO">Tocantins</option>
                                </select>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalNovaSeguradora(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Cadastrar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalSeguradora ? (
                <Modal titulo="Informações Seguradora" onClose={() => {setModalSeguradora(false)}}>
                    <div class="info-modal">
                        <div className="campo-info campo-25">
                            <h5>CNPJ</h5>
                            <h4>{seguradoraAtiva.cnpj}</h4>
                        </div>
                        <div className="campo-info campo-40">
                            <h5>Razão Social</h5>
                            <h4>{seguradoraAtiva.razao}</h4>
                        </div>
                        <div className="campo-info campo-35">
                            <h5>Nome Fantasia</h5>
                            <h4>{seguradoraAtiva.fantasia}</h4>
                        </div>
                        <div className="campo-info campo-30">
                            <h5>Telefone</h5>
                            <h4>{seguradoraAtiva.telefone}</h4>
                        </div>
                        <div className="campo-info campo-35">
                            <h5>E-mail</h5>
                            <h4>{seguradoraAtiva.email}</h4>
                        </div>
                        <div className="campo-info campo-35">
                            <h5>CEP</h5>
                            <h4>{seguradoraAtiva.cep}</h4>
                        </div>
                        <div className="campo-info campo-65">
                            <h5>Endereço</h5>
                            <h4>{seguradoraAtiva.endereco}, {seguradoraAtiva.numero}, {seguradoraAtiva.complemento}, {seguradoraAtiva.bairro}</h4>
                        </div>
                        <div className="campo-info campo-35">
                            <h5>Cidade / UF</h5>
                            <h4>{seguradoraAtiva.cidade} / {seguradoraAtiva.estado}</h4>
                        </div>
                        <div className="campo-info campo-45">
                            <h5>Nome Representante</h5>
                            <h4>{seguradoraAtiva.representante === "" ? "--" : seguradoraAtiva.representante}</h4>
                        </div>
                        <div className="campo-info campo-25">
                            <h5>Celular Representante</h5>
                            <h4>{seguradoraAtiva.celularRep === "" ? "--" : seguradoraAtiva.celularRep}</h4>
                        </div>
                        <div className="campo-info campo-30">
                            <h5>E-mail Representante</h5>
                            <h4>{seguradoraAtiva.emailRep === "" ? "--" : seguradoraAtiva.emailRep}</h4>
                        </div>
                    </div>
                    <div className='buttons-info'>
                        <button type="reset" className="button-red" onClick={() => {setModalSeguradora(false)}}>Cancelar</button>
                        <button type="button" className="button-darkred" onClick={() => {setModalSeguradora(false); setModalDeleteSeguradora(true)}}>Excluir</button>
                        <button type="button" className="button-cyan" onClick={() => {setModalSeguradora(false); setModalEditarSeguradora(true)}}>Editar</button>
                    </div>
                </Modal>
            ): null}
            {modalEditarSeguradora ? (
                <Modal titulo="Editar Seguradora" onClose={() => {setModalEditarSeguradora(false)}}>
                    <form onSubmit={editSeguradora}>
                        <div id="campos-nova-seguradora">
                            <div className="campo-form campo-30">
                                <label htmlFor="cnpj">CNPJ</label>
                                <input type="text" name="cnpj" id="cnpj" defaultValue={seguradoraAtiva.cnpj}/>
                            </div>
                            <div className="campo-form campo-70">
                                <label htmlFor="razao">Razão Social</label>
                                <input type="text" name="razao" id="razao" defaultValue={seguradoraAtiva.razao}/>
                            </div>
                            <div className="campo-form campo-45">
                                <label htmlFor="fantasia">Nome Fantasia</label>
                                <input type="text" name="fantasia" id="fantasia" defaultValue={seguradoraAtiva.fantasia}/>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="telefone">Telefone</label>
                                <input type="text" name="telefone" id="telefone" defaultValue={seguradoraAtiva.telefone}/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="email">E-mail</label>
                                <input type="text" name="email" id="email" defaultValue={seguradoraAtiva.email}/>
                            </div>
                            <h4>Representante</h4>
                            <div className="campo-form campo-45">
                                <label htmlFor="representante">Nome</label>
                                <input type="text" name="representante" id="representante" defaultValue={seguradoraAtiva.representante}/>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="celularRep">Celular</label>
                                <input type="text" name="celularRep" id="celularRep" defaultValue={seguradoraAtiva.celularRep}/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="emailRep">E-mail</label>
                                <input type="text" name="emailRep" id="emailRep" defaultValue={seguradoraAtiva.emailRep}/>
                            </div>
                            <h4>Endereço</h4>
                            <div className="campo-form campo-20">
                                <label htmlFor="cep">CEP</label>
                                <input type="text" name="cep" id="cep" defaultValue={seguradoraAtiva.cep}/>
                            </div>
                            <div className="campo-form campo-60">
                                <label htmlFor="endereco">Logradouro</label>
                                <input type="text" name="endereco" id="endereco" defaultValue={seguradoraAtiva.endereco}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="numero">Número</label>
                                <input type="text" name="numero" id="numero" defaultValue={seguradoraAtiva.numero}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="complemento">Complemento</label>
                                <input type="text" name="complemento" id="complemento" defaultValue={seguradoraAtiva.complemento}/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="bairro">Bairro</label>
                                <input type="text" name="bairro" id="bairro" defaultValue={seguradoraAtiva.bairro}/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="cidade">Cidade</label>
                                <input type="text" name="cidade" id="cidade" defaultValue={seguradoraAtiva.cidade}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="estado">Estado</label>
                                <select name="estado" id="estado" defaultValue={seguradoraAtiva.estado}>
                                    <option value="default">Selecione</option>
                                    <option value="AC">Acre</option>
                                    <option value="AL">Alagoas</option>
                                    <option value="AM">Amazonas</option>
                                    <option value="AP">Amapá</option>
                                    <option value="BA">Bahia</option>
                                    <option value="CE">Ceará</option>
                                    <option value="DF">Distrito Federal</option>
                                    <option value="ES">Espirito Santo</option>
                                    <option value="GO">Goiás</option>
                                    <option value="MA">Maranhão</option>
                                    <option value="MT">Mato Grosso</option>
                                    <option value="MS">Mato Grosso do Sul</option>
                                    <option value="MG">Minas Gerais</option>
                                    <option value="PA">Pará</option>
                                    <option value="PB">Paraíba</option>
                                    <option value="PR">Paraná</option>
                                    <option value="PE">Pernambuco</option>
                                    <option value="PI">Piauí</option>
                                    <option value="RJ">Rio de Janeiro</option>
                                    <option value="RN">Rio Grande do Norte</option>
                                    <option value="RS">Rio Grande do Sul</option>
                                    <option value="SC">Santa Catarina</option>
                                    <option value="SP">São Paulo</option>
                                    <option value="SE">Sergipe</option>
                                    <option value="RO">Rondônia</option>
                                    <option value="RR">Roraima</option>
                                    <option value="TO">Tocantins</option>
                                </select>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalEditarSeguradora(false); setModalSeguradora(true)}}>Cancelar</button>
                            <button type="submit" className="button-green">Salvar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalDeleteSeguradora ? (
                <ModalDangerSmall mensagem={['Deseja excluir a seguradora', <br/>,<span>{seguradoraAtiva.fantasia}</span>,"?"]} onClose={() => {setModalDeleteSeguradora(false)}}>
                    <Fragment>
                        <div className='buttons-form'>
                            <button type="button" className="button-red" onClick={() => {setModalDeleteSeguradora(false); setModalSeguradora(true)}}>Cancelar</button>
                            <button type="submit" className="button-blue" onClick={() => {desativarSeguradora()}}>Excluir</button>
                        </div>
                    </Fragment>
                </ModalDangerSmall>
            ): null}
        </Fragment>
    )
};