import { faCircleCheck, faCircleExclamation, faCircleInfo, faCircleXmark, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import "../../styles/notification.scss";

export default function notification(props) {
    return(
        <div className="notification">
            <div className='header-notification'>
                <FontAwesomeIcon icon={props.tipo === "Sucesso" ? faCircleCheck : props.tipo === "Erro" ? faCircleXmark : props.tipo === "Alerta" ? faCircleExclamation : faCircleInfo}/>
                <h3>{props.titulo}</h3>
                <FontAwesomeIcon icon={faXmark} onClick={props.onClose} className='icon-close-notification'/>
            </div>
            <p>{props.mensagem}</p>
        </div>
    )
};