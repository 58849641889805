import React, { Fragment, useState } from 'react';
import "../../styles/login.scss";
import LogoJMX from "../../assets/logos/LogoJMX_Registrado.jpg";
import UsersService from '../../services/users';
import axios from 'axios';

function LoginScreen () {
    const [codigoEnviado, setCodigoEnviado] = useState(false);
    const [primeiroAcesso, setPrimeiroAcesso] = useState(false);
    const [codigoCorreto, setCodigoCorreto] = useState(false);
    // const [disabledEnvio, setDisabledEnvio] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [mensagem, setMensagem] = useState("");

    const realizarLogin = async (evt) => {
        evt.preventDefault();
        
        try {
            await UsersService.login({ email: email.toLocaleLowerCase(), password: password });
            window.location.href = "../";
        } catch (error) {
            console.log(error)
        }
    }

    async function realizarPrimeiroLogin(evt) {
        evt.preventDefault();

        const formData = new FormData(evt.target);
        const data = Object.fromEntries(formData);

        try {
            await UsersService.primeiro_login(data);
            setCodigoEnviado(true);
            setMensagem("");
            setEmail(data.email);
        } catch (error) {
            if (error.response.status === 404) {
                setMensagem("Usuário não encontrado")
            } else if (error.response.status === 422) {
                setMensagem('Usuário com senha já cadastrada. Realize o login clicando em "voltar" abaixo.')
            }
        }
    }

    async function reenviarCodigo() {
        const data = {};
        data.email = email;

        document.querySelector("#codigo1").value = "";
        document.querySelector("#codigo2").value = "";
        document.querySelector("#codigo3").value = "";
        document.querySelector("#codigo4").value = "";

        try {
            await UsersService.primeiro_login(data);
            setMensagem("Código reenviado.")
        } catch (error) {
            console.log(error)
        }
    }

    function pularInput(e, inputIdAnt, inputIdProx) {
        setMensagem("");
        if (e.keyCode === 8) {
            document.getElementById(inputIdAnt).focus();
        } else if ((e.keyCode >= 48 & e.keyCode <= 57) || (e.keyCode >= 96 & e.keyCode <= 105)){
            document.getElementById(inputIdProx).focus();
        }
    }

    async function codigoPrimeiroLogin(evt) {
        evt.preventDefault();

        const formData = new FormData(evt.target);
        const data = Object.fromEntries(formData);

        const codigo = `${data.codigo1}${data.codigo2}${data.codigo3}${data.codigo4}`

        const params = {};

        params.codigo = parseInt(codigo);
        params.email = email;

        try {
            await UsersService.verificar_codigo(params);
            setCodigoCorreto(true);
            setMensagem("");
        } catch (error) {
            console.log(error)
        }
    };

    async function senhaCadastrada(evt) {
        evt.preventDefault();

        const formData = new FormData(evt.target);
        const data = Object.fromEntries(formData);

        if (data.senha1 === data.senha2 && data.senha1.length !== 0) {
            const params = {}
            params.password = data.senha1;
            params.email = email;
            const response = await axios.post(`https://api.jmxcorretora.com.br:3001/usuarios/primeira-senha`, (params), {headers: {'x-access-token': localStorage.getItem('token-codigo')}});
            localStorage.setItem('token', response.data.token);
            localStorage.removeItem('token-codigo');
            window.location.href = "../";
        } else {
            setMensagem("As senhas não correspondem!");
        }
    }

    document.title = 'Login - JMX Corretora de Seguros';

    return (
        <Fragment>
            <div className='background-login'>
                { primeiroAcesso === false ?
                    <div className="box-login">
                        <img src={LogoJMX} alt="Logo JMX"/>
                        <form onSubmit={realizarLogin}>
                            <div className='campo-form campo-100'>
                                <label htmlFor="user-login">Usuário</label>
                                <input type="email" value={email} onChange={e => setEmail(e.target.value)} name="email"/>
                            </div>
                            <div className='campo-form campo-100'>
                                <label htmlFor="pass-login">Senha</label>
                                <input type="password" value={password} onChange={e => setPassword(e.target.value)} name="password"/>
                            </div>
                            <h3>Esqueceu sua senha?</h3>
                            <div className='buttons-form'>
                                <button type="button" className='button-cyan' onClick={() => {setPrimeiroAcesso(true)}}>Primeiro Acesso</button>
                                <button type="submit" className='button-blue'>Entrar</button>
                            </div>
                        </form>
                    </div>
                :
                    codigoEnviado === false ?
                        <div className='box-primeiro-acesso'>
                            <h2>Bem-vindo ao sistema da JMX!</h2>
                            <h4>Siga atentamente os passos para poder cadastrar uma senha de acesso!</h4>
                            <p>Precisaremos do seu e-mail para verificar em nosso banco de dados se o e-mail está associado à alguma conta liberada, caso esteja liberado e seja o primeiro login, iremos te enviar um e-mail com um código de verificação para realizar o cadastro de uma senha de acesso.</p>
                            <form onSubmit={realizarPrimeiroLogin}>
                                <div className='column-100'>
                                    <input type="email" id='email-acesso' name='email' placeholder='Digite aqui seu e-mail' required/>
                                    {mensagem !== "" ? <p>{mensagem}</p> : null}
                                </div>
                                <div className='aceite-termos'>
                                    <input type="checkbox" id='acordo-termos' name='acordo-termos' required/>
                                    <label>Declaro que estou de acordo com os <span>termos de uso</span> e as <span>politicas de privacidade</span>.</label>
                                </div>
                                <button className='button-red' onClick={() => {setMensagem(""); setPrimeiroAcesso(false)}}>Voltar</button>
                                <button className='button-green' type='submit'>Enviar código</button>
                            </form>
                        </div>
                    :
                        codigoCorreto === true ?
                            <div className='box-cadastrar-senha'>
                                <h2>Enfim, a última parte!</h2>
                                <h4>Chegamos ao fim do processo, agora para a última etapa, preciso que cadastre sua senha de acesso ao sistema.</h4>
                                <p>Lembrando, essa senha é somente sua e não pode ser compartilhada para evitar problemas futuros com sua conta!</p>
                                <form onSubmit={senhaCadastrada}>
                                    <div>
                                        <label htmlFor='senha1'>Digite sua senha:</label>
                                        <input id='senha1' name='senha1' type="password"/>
                                    </div>
                                    <div>
                                        <label htmlFor='senha2'>Confirme a senha digitada:</label>
                                        <input id='senha2' name='senha2' type="password"/>
                                    </div>
                                    {mensagem !== "" ? <p>{mensagem}</p> : null}
                                    <button className='button-green' type='submit'>Cadastrar</button>
                                </form>
                            </div>
                        :
                            <div className='box-codigo-enviado'>
                                <h2>Nós enviamos o código de confirmação em seu e-mail, verifique e digite o código abaixo:</h2>
                                <form onSubmit={codigoPrimeiroLogin}>
                                    <div className='inputs-codigo'>
                                        <input type="text" pattern='\d*' autoComplete='off' id='codigo1' name='codigo1' min="0" max="1" maxLength={1} onKeyUp={(e) => pularInput(e, "codigo1", "codigo2")}/>
                                        <input type="text" pattern='\d*' autoComplete='off' id='codigo2' name='codigo2' maxLength="1" onKeyUp={(e) => pularInput(e, "codigo1", "codigo3")}/>
                                        <input type="text" pattern='\d*' autoComplete='off' id='codigo3' name='codigo3' maxLength="1" onKeyUp={(e) => pularInput(e, "codigo2", "codigo4")}/>
                                        <input type="text" pattern='\d*' autoComplete='off' id='codigo4' name='codigo4' maxLength="1" onKeyUp={(e) => pularInput(e, "codigo3", "codigo4")}/>
                                        {mensagem !== "" ? <p>{mensagem}</p> : null}
                                        <h4>Não recebeu o código?<br/><span onClick={reenviarCodigo}>Clique aqui para reenviar</span></h4>
                                    </div>
                                    <p>O código tem validade de 2 minutos, após esse tempo será necessário solicitar um novo código.</p>
                                    <button className='button-red' type='button' onClick={() => {setPrimeiroAcesso(false); setCodigoEnviado(false); setEmail("")}}>Cancelar</button>
                                    <button className='button-green' type='submit'>Verificar código</button>
                                </form>
                            </div>
                }
            </div>
            {/* <div className='background-login'>
                <div className="box-login">
                    <img src={LogoJMX} alt="Logo JMX"/>
                    { primeiroAcesso ? (codigoEnviado ?
                        <form onSubmit={disabledEnvio ? codigoPrimeiroLogin : null}>
                            <div className='campo-form campo-100'>
                                <label>Digite o código recebido por e-mail</label>
                                <div style={{display: 'flex'}}>
                                    <input type="text" maxLength={1} style={{textAlign: 'center'}} name="number1"/>
                                    <input type="text" maxLength={1} style={{textAlign: 'center'}} name="number2"/>
                                    <input type="text" maxLength={1} style={{textAlign: 'center'}} name="number3"/>
                                    <input type="text" maxLength={1} style={{textAlign: 'center'}} name="number4"/>
                                </div>
                            </div>
                            <p>Aguarde no mínimo 2 minutos para solicitar um novo código</p>
                            <div className='buttons-form'>
                                <button type="button" className='button-cyan' onClick={() => {setPrimeiroAcesso(false)}}>Voltar</button>
                                <button type="submit" className='button-blue'>Verificar Código</button>
                            </div>
                        </form>
                    :
                        <form onSubmit={realizarPrimeiroLogin}>
                            <div className='campo-form campo-100'>
                                <label htmlFor="email">E-mail</label>
                                <input type="email" name="email"/>
                            </div>
                            <p>Digite o seu e-mail e após isso será enviado um código em seu e-mail para criação de senha.</p>
                            <div className='buttons-form'>
                                <button type="button" className='button-red' onClick={() => {setPrimeiroAcesso(false)}}>Voltar</button>
                                <button type="submit" className='button-blue'>Enviar Código</button>
                            </div>
                        </form>)
                    :
                    <form onSubmit={realizarLogin}>
                        <div className='campo-form campo-100'>
                            <label htmlFor="user-login">Usuário</label>
                            <input type="email" value={email} onChange={e => setEmail(e.target.value)} name="email"/>
                        </div>
                        <div className='campo-form campo-100'>
                            <label htmlFor="pass-login">Senha</label>
                            <input type="password" value={password} onChange={e => setPassword(e.target.value)} name="password"/>
                        </div>
                        <div className='buttons-form'>
                            <button type="button" className='button-cyan' onClick={() => {setPrimeiroAcesso(true)}}>Primeiro Acesso</button>
                            <button type="submit" className='button-blue'>Entrar</button>
                        </div>
                    </form>}
                </div>
                <h3>Esqueceu sua senha?</h3>
            </div> */}
        </Fragment>
    )
};
   
export default LoginScreen;