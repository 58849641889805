import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faPen, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import "../../../styles/faq.scss";
import "../../../styles/reset.scss";
import Modal from '../../layouts/modal';
import ModalDangerSmall from '../../layouts/modalDangerSmall';
import FAQService from '../../../services/faq';
import UsersService from '../../../services/users';
import Loading from '../../layouts/loading';

function ContentAdministracaoFAQ() {
    const navigate = useNavigate();
    const [user, setUser] = useState([]);
    const [faqs, setFAQs] = useState([]);
    const [carregado, setCarregado] = useState(false);
    const [faqAtivo, setFAQAtivo] = useState();
    const [modalCreateFAQ, setModalCreateFAQ] = useState(false);
    const [modalEditFAQ, setModalEditFAQ] = useState(false);
    const [modalDeleteFAQ, setModalDeleteFAQ] = useState(false);
    const [faqSelected, setFAQSelected] = useState(null);

    async function getUser() {
        const response = await UsersService.logado();
        setUser(response.data.user);
    };

    async function getFAQs() {
        const response = await FAQService.index();
        setFAQs(response.data);
    };

    useEffect(() => {
        (async function() {
            await getUser();
            await getFAQs();
            setCarregado(true);
        })();
    }, []);

    async function createFAQ(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        const form = Object.fromEntries(formData);
        
        try {
            await FAQService.create(form);
            getFAQs();
            setModalCreateFAQ(false);
        } catch (error) {
            console.log(error);
        }
    };

    async function editFAQ(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData);
        
        try {
            await FAQService.edit(faqAtivo._id, data);
            getFAQs();
            setModalEditFAQ(false);
        } catch (error) {
            console.log(error)
        }
    };

    async function deleteFAQ() {
        try {
            await FAQService.delete(faqAtivo._id);
            getFAQs();
            setModalDeleteFAQ(false);
        } catch (error) {
            console.log(error);
        }
    };

    const toggleFAQ = (i) => {
        if (faqSelected === i) {
            setFAQSelected(null);
        } else {
            setFAQSelected(i)
        }
    };

    function ordemAlfabetica(a, b){
        return a.pergunta < b.pergunta ? -1 : a.pergunta > b.pergunta ? 1 : 0;
    }

    document.title = 'FAQ - JMX Corretora de Seguros';

    return(
        <Fragment>
            { carregado ?
                <main id='main-faq'>
                    <div className="cabecalho-conteudo">
                        <h2>FAQ</h2>
                        <div className="buttons-conteudo">
                            <button className="button-red" onClick={()=> navigate(`/administracao`)}>Voltar</button>
                            <button className="button-green" onClick={() => setModalCreateFAQ(true)}>Novo FAQ</button>
                        </div>
                    </div>
                    <div className="content-faq">
                        <div className="list-itens-faq">
                            <h3>Sinistros</h3>
                            {faqs.filter(({tipo}) => tipo === "Sinistros").sort(ordemAlfabetica).map((item, index) => (
                                <div className="content-item-faq" key={index}>
                                    <div className='titulo-content-item-faq' onClick={() => {toggleFAQ(item._id)}}>
                                        <h4>{item.pergunta}</h4>
                                        <div className='icons-content-item-faq'>
                                            { user.permission <= 2 ? <FontAwesomeIcon icon={faTrashCan} onClick={(e) => {e.stopPropagation(); setFAQAtivo(item); setModalDeleteFAQ(true)}}/> : null}
                                            <FontAwesomeIcon icon={faPen} onClick={(e) => {e.stopPropagation(); setFAQAtivo(item); setModalEditFAQ(true)}}/>
                                            <FontAwesomeIcon icon={faqSelected === item._id ? faChevronUp : faChevronDown}/>
                                        </div>
                                    </div>
                                    <div className={faqSelected === item._id ? 'content-answer show-content-answer' : 'content-answer'} onClick={() => {toggleFAQ(item._id)}}>
                                        <p>{item.resposta}</p> 
                                    </div>
                                </div>
                            ))}
                            <h3>Sistema</h3>
                            {faqs.filter(({tipo}) => tipo === "Sistema").sort(ordemAlfabetica).map((item, index) => (
                                <div className="content-item-faq" key={index}>
                                    <div className='titulo-content-item-faq' onClick={() => {toggleFAQ(item._id)}}>
                                        <h4>{item.pergunta}</h4>
                                        <div className='icons-content-item-faq'>
                                            { user.permission <= 2 ? <FontAwesomeIcon icon={faTrashCan} onClick={(e) => {e.stopPropagation(); setFAQAtivo(item); setModalDeleteFAQ(true)}}/> : null}
                                            <FontAwesomeIcon icon={faPen} onClick={(e) => {e.stopPropagation(); setFAQAtivo(item); setModalEditFAQ(true)}}/>
                                            <FontAwesomeIcon icon={faqSelected === item._id ? faChevronUp : faChevronDown}/>
                                        </div>
                                    </div>
                                    <div className={faqSelected === item._id ? 'content-answer show-content-answer' : 'content-answer'} onClick={() => {toggleFAQ(item._id)}}>
                                        <p>{item.resposta}</p> 
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </main>
            : 
                <Loading/>
            }
            {modalCreateFAQ ? (
                <Modal titulo="Novo FAQ" width="45%" onClose={() => {setModalCreateFAQ(false)}}>
                    <form onSubmit={createFAQ}>
                        <div id="campos-novo-faq">
                            <div className="campo-form campo-30">
                                <label htmlFor="tipo">Tipo</label>
                                <select name="tipo" id="tipo" required defaultValue="">
                                    <option value="" disabled>Selecione</option>
                                    <option value="Sinistros">Sinistros</option>
                                    <option value="Sistema">Sistema</option>
                                </select>
                            </div>
                            <div className="campo-form campo-70">
                                <label htmlFor="pergunta">Pergunta</label>
                                <input type="text" name="pergunta" id="pergunta" required autoComplete='off'/>
                            </div>
                            <div className="campo-form campo-100">
                                <label htmlFor="resposta">Resposta</label>
                                <textarea rows={3} name="resposta" id="resposta" required/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalCreateFAQ(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Cadastrar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalEditFAQ ? (
                <Modal titulo="Editar FAQ" onClose={() => {setModalEditFAQ(false)}}>
                    <form onSubmit={editFAQ}>
                        <div id="campos-novo-faq">
                            <div className="campo-form campo-30">
                                <label htmlFor="tipo">Tipo</label>
                                <select name="tipo" id="tipo" required defaultValue={faqAtivo.tipo}>
                                    <option value="Sinistros">Sinistros</option>
                                    <option value="Sistema">Sistema</option>
                                </select>
                            </div>
                            <div className="campo-form campo-70">
                                <label htmlFor="pergunta">Pergunta</label>
                                <input type="text" name="pergunta" id="pergunta" required autoComplete='off' defaultValue={faqAtivo.pergunta}/>
                            </div>
                            <div className="campo-form campo-100">
                                <label htmlFor="resposta">Resposta</label>
                                <textarea rows={3} name="resposta" id="resposta" required defaultValue={faqAtivo.resposta}/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="button" className="button-red" onClick={() => {setModalEditFAQ(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Salvar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalDeleteFAQ ? (
                <ModalDangerSmall mensagem={['Deseja excluir a pergunta', <br/>,<span>{faqAtivo.pergunta}</span>,"?"]} onClose={() => {setModalDeleteFAQ(false)}}>
                    <Fragment>
                        <div className='buttons-form'>
                            <button type="button" className="button-red" onClick={() => {setModalDeleteFAQ(false)}}>Cancelar</button>
                            <button type="submit" className="button-blue" onClick={() => {deleteFAQ()}}>Excluir</button>
                        </div>
                    </Fragment>
                </ModalDangerSmall>
            ): null}
        </Fragment>
    )
};
   
export default ContentAdministracaoFAQ;