import Api from './api';

const UsersService = {
    index: () => Api.get('/usuarios'),
    registrar: async (params) => await Api.post('/usuarios/registrar', (params)),
    login: async (params) => { const response = await Api.post('/usuarios/login', params); localStorage.setItem('token', response.data.token)},
    logout: () => { localStorage.removeItem('user', null); localStorage.removeItem('token', null)},
    editar_dados: async (params) => await Api.put(`/usuarios`, (params)),
    senha: async (params) => await Api.put('/usuarios/password', (params)),
    editar_usuario: async (id, params) => await Api.put(`/usuarios/${id}`, (params)),
    reset_senha: async (id) => await Api.put(`/usuarios/${id}/reset`),
    alterar_foto: async (params) => await Api.post(`/usuarios/alterar-foto`, (params), {headers: { 'Content-Type': `multipart/form-data; boundary=${FormData._boundary}`}}),
    desativar: async (id) => await Api.put(`/usuarios/desabilitar/${id}`),
    habilitar: async (id) => await Api.put(`/usuarios/habilitar/${id}`),
    deletar: async (id) => await Api.delete(`/usuarios/${id}`),
    adicionar_corretor: async (id) => await Api.put(`/usuarios/corretor/add/${id}`),
    excluir_corretor: async (id) => await Api.put(`/usuarios/corretor/remove/${id}`),
    logado: () => Api.get('/usuarios/logado'),
    primeiro_login: async (params) => await Api.post('/usuarios/primeiro-login', params),
    verificar_codigo: async (params) => {const response = await Api.post('/usuarios/verificar-codigo', params); localStorage.setItem('token-codigo', response.data.token)},
    criar_anotacao: (params) => Api.post('/usuarios/anotacoes', (params)),
    anotacoes: () => Api.get('/usuarios/anotacoes'),
    editar_anotacao: (params, id) => Api.put(`/usuarios/anotacoes/${id}`, (params)),
    delete_anotacao: (id) => Api.delete(`/usuarios/anotacoes/${id}`),
    criar_agenda: (params) => Api.post('/usuarios/agenda', (params)),
    editar_agenda: (params, id) => Api.put(`/usuarios/agenda/${id}`, (params)),
    finalizar_agenda: (id) => Api.put(`/usuarios/agenda/${id}/finalizar`),
    delete_agenda: (id) => Api.delete(`/usuarios/agenda/${id}`),
    //     headers:{'x-access-token': localStorage.getItem('token')}
    // }),
    // agenda: () => Api.get('/usuarios/agenda',{
    //     headers:{'x-access-token': localStorage.getItem('token')}
    // }),
    // editar_agenda: async (id, params) => {
    //     await Api.put(`/usuarios/agenda/${id}`, (params), {
    //         headers:{'x-access-token': localStorage.getItem('token')}
    //     })
    // },
    // delete_agenda: (id) => Api.delete(`/usuarios/agenda/${id}`, {
    //     headers:{'x-access-token': localStorage.getItem('token')}
    // })
}

export default UsersService;