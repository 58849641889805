import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import "../../styles/modal.scss";

export default function modal(props) {
    return(
        <Fragment>
            <div className="modal">
                <div className="fundo-modal" onClick={props.onClose}>

                </div>
                <div className='content-modal' style={{ width: props.width === undefined ? "50%" : props.width}}>
                    <div className='cabecalho-modal'>
                        <h3>{props.titulo}</h3>
                        <FontAwesomeIcon icon={faXmark} onClick={props.onClose}/>
                    </div>
                    {props.children}
                </div>
            </div>
        </Fragment>
    )
};