import { Fragment } from 'react';
import Header from '../../components/header/index';
import ContentSugestoesChamados from '../../components/chamados/sugestoes';

const ChamadosSugestoesScreen = (props) => (
  <Fragment>
    <Header ativo={props.ativo}/>
    <ContentSugestoesChamados/>
  </Fragment>
)

export default ChamadosSugestoesScreen;