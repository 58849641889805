import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faTrashCan, faPen } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';
import 'moment-timezone';
import "../../styles/empresas.scss";
import "../../styles/historicos.scss";
import "../../styles/reset.scss";
import UsersService from '../../services/users';
import HistoricosService from '../../services/historicos';
import ModalDangerSmall from '../layouts/modalDangerSmall';
import MatteusImage from "../../assets/users/Matteus.png";
import GeovanaImage from "../../assets/users/Geovana.jpeg";
import ClienteImage from "../../assets/users/Geovana.jpg";
import PadraoImage from "../../assets/users/padrão.jpg";
import RaphaelImage from "../../assets/users/Raphael.jpg";

export default function ContentAdministracaoEmpresas() {
    const navigate = useNavigate();
    const [historicos, setHistoricos] = useState([]);
    const [permission, setPermission] = useState();
    const [user, setUser] = useState();
    const [historicoAtivo, setHistoricoAtivo] = useState();
    const [modalExcluirHistorico, setModalExcluirHistorico] = useState(false);
    const [carregado, setCarregado] = useState(false);

    async function deleteHistorico() {
        await HistoricosService.delete(historicoAtivo);
        setModalExcluirHistorico(false);
        getHistoricos();
    }

    useEffect(() =>{
        (async function() {
            const response_user = await UsersService.logado();
            const response_historicos = await HistoricosService.index();
            setHistoricos(response_historicos.data);
            setUser(response_user.data.user);
            setPermission(response_user.data.permission);
            setCarregado(true);
        })();
    },[]);

    async function getHistoricos() {
        const response_historicos = await HistoricosService.index();
        setHistoricos(response_historicos.data);
    }
    
    // ORDENAR DATA
    function ordemData(a, b){
        return a.data > b.data ? -1 : a.data < b.data ? 1 : 0;
    }

    // const empresasAtivas = empresas?.filter(({ excluido }) => excluido === false);
    
    document.title = `Históricos - JMX Corretora de Seguros`;

    return(
        <Fragment>
            { carregado !== false ?
            <main id='main-empresas'>
                <div className="conteudo-sinistros">
                    <div className="cabecalho-conteudo">
                        <h2>Históricos</h2>
                        <div className="buttons-conteudo">
                            <button className="button-red" onClick={()=> navigate(`/corretora`)}>Voltar</button>
                            <button className="button-blue" onClick={() => {window.print()}}>Imprimir</button>
                            {/* <button className="button-green add-sinistro" onClick={()=> setModalNovaEmpresa(true)}>Nova empresa</button> */}
                        </div>
                    </div>
                    <div className="filtro-conteudo">
                        <input type="text" name="src-geral-sinistros" id="src-geral-sinistro" placeholder="Faça sua busca aqui"/>
                        <button className="filtro-avancado">Filtro Avançado</button>
                        <button className="filtro-buscar button-green">
                        <FontAwesomeIcon icon={faMagnifyingGlass}/>
                        </button>
                    </div>
                    <div className='lista-historicos'>
                    { historicos.length === 0 ? 
                        <p className='no-info'>Não foi cadastrado nenhum histórico ainda.</p>
                    :
                    ( historicos?.sort(ordemData).map((item, index) => (
                        <div key={index} className={item.autor._id === user._id ? 'card-historico is-owner' : 'card-historico is-not-owner'}>
                            <div className='tipo-historico'>
                                { item.localModel === "Sinistro" ?
                                    (() => {
                                        switch (true) {
                                            case (item.local.ramo === "Vida"):
                                                return(<span>Sinistro {item.local.ramo} - {item.local.sinistradoNome}</span>)
                                            case (item.local.ramo === "Auto"):
                                                return(<span>Sinistro {item.local.ramo} - {item.local.sinistradoPlacas}</span>)
                                            case (item.local.ramo === "Residêncial"):
                                                return(<span>Sinistro {item.local.ramo} - {item.local.sinistradoEndereco}, {item.local.sinistradoNumero}, {item.local.sinistradoCidade}/{item.local.sinistradoEstado.toUpperCase()}</span>)
                                            default:
                                            break;
                                        }
                                    })()
                                :
                                    <span>{item.local.tipo}</span>
                                }
                            </div>
                            <div className='foto-autor'>
                            {(() => {
                                switch (true) {
                                    case (item.autor.permission === 1):
                                        return(<Fragment><img src={RaphaelImage} alt=""/></Fragment>)
                                    case (item.autor.permission === 2):
                                        return(<Fragment><img src={MatteusImage} alt=""/></Fragment>)
                                    case (item.autor.permission === 3 || item.autor.permission === 6):
                                        return(<Fragment><img src={PadraoImage} alt=""/></Fragment>)
                                    case (item.autor.permission === 4):
                                        return(<Fragment><img src={GeovanaImage} alt=""/></Fragment>)
                                    case (item.autor.permission === 5):
                                        return(<Fragment><img src={ClienteImage} alt=""/></Fragment>)
                                    default:
                                    break;
                                }
                        })()}
                            </div>
                            <div className='informacoes-historico'>
                                <div className="cabecalho-historico">
                                    <h3>{item.assunto} {item.sucesso === false ? <span>Sem sucesso</span> : null}</h3>
                                    { item.autor.name === user.name || permission === 1?
                                        <div className='options-cabecalho-historico'>
                                            { item.autor.name === user.name ?
                                                <FontAwesomeIcon icon={faPen}/>
                                            : null}
                                            <FontAwesomeIcon icon={faTrashCan} onClick={() => {setModalExcluirHistorico(true); setHistoricoAtivo(item._id)}}/>
                                        </div>
                                    : null}
                                </div>
                                <div className="dados-historico">
                                    <div className="campo-20">
                                        <h5>Data/Hora:</h5>
                                        <p>
                                            <Moment format='DD/MM/YYYY HH:mm'>
                                                {item.data}
                                            </Moment>
                                        </p>
                                    </div>
                                    <div className="campo-20">
                                        <h5>Usuário:</h5>
                                        <p>{item.autor.name}</p>
                                    </div>
                                    {item.sucesso === false ?
                                        <div className="campo-60">
                                            <h5>Motivo de não ter sucesso:</h5>
                                            <p>{item.motivo_nao_sucesso}</p>
                                        </div>
                                    : null}
                                    <div className="campo-100">
                                        <h5>Descrição:</h5>
                                        <p>{item.descricao}</p>
                                    </div>
                                </div>
                                <div className="buttons-historico">
                                    { item.localModel === "Sinistro" ? 
                                        <button className='button-blue' onClick={()=> navigate(`/corretora/sinistros/${item.local._id}`)}>Acessar Sinistro</button>
                                    :
                                        <button className='button-blue' onClick={()=> navigate(`/`)}>Acessar Cliente</button>
                                    }
                                </div>
                            </div>
                        </div>
                    )))}
                    </div>
                    {/* <div className="tabela-dados">
                        <div className="cabecalho-dados">
                            <div className="column-15">CNPJ</div>
                            <div className="column-35">Nome Fantasia</div>
                            <div className="column-40">Razão Social</div>
                            <div className="column-10">Cadastro</div>
                        </div>
                        <div className="corpo-dados corpo-empresas">
                            { empresasAtivas.length === 0 ? 
                                <div className="row-dados">
                                    <div className="column-zerado">
                                        Não foi encontrado nenhum resultado.
                                    </div>
                                </div>
                                : (
                                    (empresasAtivas.slice(0).reverse().map((empresas) => (
                                        <Fragment>
                                            <div key={empresas._id} className="row-dados">
                                                <div className="column-15">
                                                    {empresas.cnpj}
                                                </div>
                                                <div className="column-35">
                                                    {empresas.fantasia}
                                                </div>
                                                <div className="column-40">
                                                    {empresas.razao}
                                                </div>
                                                <div className="column-10">
                                                    <Moment format="DD/MM/YYYY">
                                                        {empresas.created_at}
                                                    </Moment>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )))
                                )
                            }
                            <h5>Exibindo {empresasAtivas.length} {empresasAtivas.length === 1 ? "resultado" : "resultados"}</h5>
                        </div>
                    </div> */}
                </div>
            </main>
            : 
            <div className='loading'>
                <div className='load-circle'>
                    
                </div>
            </div>
            }
            {modalExcluirHistorico ? (
                <ModalDangerSmall mensagem={"Deseja excluir esse histórico definitivamente?"} onClose={() => {setModalExcluirHistorico(false)}}>
                    <div className='buttons-form'>
                        <button type="reset" className="button-red" onClick={() => {setModalExcluirHistorico(false)}}>Cancelar</button>
                        <button type="submit" className="button-blue" onClick={() => {deleteHistorico()}}>Excluir</button>
                    </div>
                </ModalDangerSmall>
            ): null}
            {/* {modalNovaEmpresa ? (
                <Modal titulo="Nova Empresa" width="70%" onClose={() => {setModalNovaEmpresa(false)}}>
                    <form >
                        <div id="campos-nova-seguradora">
                            <div className="campo-form campo-15">
                                <label htmlFor="empresa_administracao">Administração?</label>
                                <select name='empresa_administracao' id='empresa_administracao' onChange={(e) => {empresaAdministracao(e)}}>
                                    <option value="default">Selecione</option>
                                    <option value="nao">Não</option>
                                    <option value="sim">Sim</option>
                                </select>
                            </div>
                            <div className="campo-form campo-35">
                                <label htmlFor="apolice-empresa">Apólice</label>
                                <select name='apolice' id='apolice-empresa' disabled={disabledAdm}>
                                    <option value="default">Selecione</option>
                                </select>
                            </div>
                            <div className="campo-form campo-15">
                                <label htmlFor="primeiro_faturamento">1° Faturamento</label>
                                <input type="text" name="primeiro_faturamento" id="primeiro_faturamento" required disabled={disabledAdm}/>
                            </div>
                            <div className="campo-form campo-15">
                                <label htmlFor="primeiro_vencimento">1° Vencimento</label>
                                <input type="text" name="primeiro_vencimento" id="primeiro_vencimento" disabled={disabledAdm}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="premio">Prêmio Individual</label>
                                <input type="text" name="premio" id="premio" required disabled={disabledAdm}/>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="cnpj">CNPJ</label>
                                <input type="text" name="cnpj" id="cnpj" required/>
                            </div>
                            <div className="campo-form campo-40">
                                <label htmlFor="razao_social">Razão Social</label>
                                <input type="text" name="razao_social" id="razao_social"/>
                            </div>
                            <div className="campo-form campo-35">
                                <label htmlFor="fantasia">Fantasia</label>
                                <input type="text" name="fantasia" id="fantasia"/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="telefone">Telefone</label>
                                <input type="text" name="telefone" id="telefone"/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="telefone_secundario">Telefone <span>Secundário</span></label>
                                <input type="text" name="telefone_secundario" id="telefone_secundario"/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="celular">Celular</label>
                                <input type="text" name="celular" id="celular"/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="email">Email</label>
                                <input type="text" name="email" id="email"/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="email_secundario">Email <span>Secundário</span></label>
                                <input type="text" name="email_secundario" id="email_secundario"/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="cnae">CNAE</label>
                                <input type="text" name="cnae" id="cnae"/>
                            </div>
                            <div className="campo-form campo-70">
                                <label htmlFor="descricao_cnae">Descrição CNAE</label>
                                <input type="text" name="descricao_cnae" id="descricao_cnae" required/>
                            </div>
                            <div className="campo-form campo-60">
                                <label htmlFor="representante_legal">Responsável Legal</label>
                                <input type="text" name="representante_legal" id="representante_legal" required/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="representante_legal_telefone">Telefone</label>
                                <input type="text" name="representante_legal_telefone" id="representante_legal_telefone" required/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="representante_legal_email">Email</label>
                                <input type="text" name="representante_legal_email" id="representante_legal_email" required/>
                            </div>
                            <div className="campo-form campo-60">
                                <label htmlFor="representante_financeiro">Responsável Financeiro</label>
                                <input type="text" name="representante_financeiro" id="representante_financeiro" required/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="representante_financeiro_telefone">Telefone</label>
                                <input type="text" name="representante_financeiro_telefone" id="representante_financeiro_telefone" required/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="representante_financeiro_email">Email</label>
                                <input type="text" name="representante_financeiro_email" id="representante_financeiro_email" required/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="cep">CEP</label>
                                <input type="text" name="cep" id="cep" required/>
                            </div>
                            <div className="campo-form campo-60">
                                <label htmlFor="logradouro">Logradouro</label>
                                <input type="text" name="logradouro" id="logradouro" required/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="numero">Número</label>
                                <input type="text" name="numero" id="numero" required/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="complemento">Complemento</label>
                                <input type="text" name="complemento" id="complemento"/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="bairro">Bairro</label>
                                <input type="text" name="bairro" id="bairro"/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="cidade">Cidade</label>
                                <input type="text" name="cidade" id="cidade" required/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="estado">Estado</label>
                                <select name="estado" id="estado">
                                    <option value="default">Selecione</option>
                                    <option value="AC">Acre</option>
                                    <option value="AL">Alagoas</option>
                                    <option value="AM">Amazonas</option>
                                    <option value="AP">Amapá</option>
                                    <option value="BA">Bahia</option>
                                    <option value="CE">Ceará</option>
                                    <option value="DF">Distrito Federal</option>
                                    <option value="ES">Espirito Santo</option>
                                    <option value="GO">Goiás</option>
                                    <option value="MA">Maranhão</option>
                                    <option value="MT">Mato Grosso</option>
                                    <option value="MS">Mato Grosso do Sul</option>
                                    <option value="MG">Minas Gerais</option>
                                    <option value="PA">Pará</option>
                                    <option value="PB">Paraíba</option>
                                    <option value="PR">Paraná</option>
                                    <option value="PE">Pernambuco</option>
                                    <option value="PI">Piauí</option>
                                    <option value="RJ">Rio de Janeiro</option>
                                    <option value="RN">Rio Grande do Norte</option>
                                    <option value="RS">Rio Grande do Sul</option>
                                    <option value="SC">Santa Catarina</option>
                                    <option value="SP">São Paulo</option>
                                    <option value="SE">Sergipe</option>
                                    <option value="RO">Rondônia</option>
                                    <option value="RR">Roraima</option>
                                    <option value="TO">Tocantins</option>
                                </select>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalNovaEmpresa(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Cadastrar</button>
                        </div>
                    </form>
                </Modal>
            ): null} */}
        </Fragment>
    )
};