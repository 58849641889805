import { Fragment } from 'react';
import Header from '../../components/header/index';
import ContentSuporteChamados from '../../components/chamados/suporte';

const ChamadosSuporteScreen = (props) => (
  <Fragment>
    <Header ativo={props.ativo}/>
    <ContentSuporteChamados/>
  </Fragment>
)

export default ChamadosSuporteScreen;