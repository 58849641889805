import { Fragment } from 'react';
import Header from '../../components/header/index';
import ContentChamado from '../../components/chamados/chamado';

const ChamadoScreen = (props) => (
  <Fragment>
    <Header ativo={props.ativo}/>
    <ContentChamado/>
  </Fragment>
)

export default ChamadoScreen;