import { Fragment } from 'react';
import Header from '../../components/header/index';
import ContentAdministracaoExcluidos from '../../components/administracao/excluidos';

const ExcluidosScreen = (props) => (
  <Fragment>
    <Header ativo={props.ativo}/>
    <ContentAdministracaoExcluidos/>
  </Fragment>
)

export default ExcluidosScreen;