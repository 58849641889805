import Api from './api';

const ChamadosService = {
    index: () => Api.get('/chamados'),
    create: async (params) => await Api.post(`/chamados`, (params)),
    bugs: () => Api.get('/chamados/bugs'),
    sugestoes: () => Api.get('/chamados/sugestoes'),
    correcoes: () => Api.get('/chamados/correcoes'),
    suporte: () => Api.get('/chamados/suporte'),
    chamado: (id) => Api.get(`/chamados/${id}`),
    habilitar: (id) => Api.put(`/chamados/${id}/habilitar`),
    desabilitar: (id) => Api.put(`/chamados/${id}/desabilitar`),
    finalizar: (id, params) => Api.put(`/chamados/${id}/finalizar`, (params)),
    // adicionar_aluno: async (id, params) => await Api.put(`/chamados/${id}/aluno`, (params)),
    // editar_aluno: async (id, otherId, params) => await Api.put(`/chamados/${id}/aluno/${otherId}`, (params)),
    // concluir_aluno: async (id, otherId, params) => await Api.put(`/chamados/${id}/aluno/${otherId}/concluir`, (params)),
    // excluir_aluno: async (id, otherId) => await Api.delete(`/chamados/${id}/aluno/${otherId}`),
    // excluir_treinamento: async (id) => await Api.put(`/chamados/${id}/desabilitar`),
    // restaurar_treinamento: async (id) => await Api.put(`/chamados/${id}/habilitar`),
    // delete_treinamento: async (id) => await Api.delete(`/chamados/${id}`)
}

export default ChamadosService;