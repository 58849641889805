import { Fragment } from 'react';
import Header from '../../components/header/index';
import ContentUsuarios from '../../components/administracao/usuarios';

const UsuariosScreen = (props) => (
  <Fragment>
    <Header ativo={props.ativo}/>
    <ContentUsuarios/>
  </Fragment>
)

export default UsuariosScreen;