import React, { Fragment } from 'react';
import "../../styles/loading.scss";

export default function Loading(props) {
    return(
        <Fragment>
            <div className='loading' style={{width: props.width, height: props.height}}>
                <div className='load-circle'>
                    
                </div>
            </div>
        </Fragment>
    )
};