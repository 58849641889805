import { Fragment } from 'react';
import Header from '../../components/header/index';
import ContentSuporte from '../../components/administracao/suporte';

const SuporteScreen = (props) => (
  <Fragment>
    <Header ativo={props.ativo}/>
    <ContentSuporte/>
  </Fragment>
)

export default SuporteScreen;