import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRotateLeft, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';
import 'moment-timezone';
import "../../../styles/excluidos.scss";
import "../../../styles/reset.scss";
import SinistrosService from '../../../services/sinistros';
import ModalDangerSmall from '../../layouts/modalDangerSmall';
import UsersService from '../../../services/users';
import SeguradorasService from '../../../services/seguradoras';
import ApolicesService from '../../../services/apolices';
import VisitasService from '../../../services/visitas';

export default function ContentAdministracaoSeguradoras() {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [userAtivo, setUserAtivo] = useState();
    const [apolices, setApolices] = useState([]);
    const [seguradoras, setSeguradoras] = useState([]);
    const [visitas, setVisitas] = useState([]);
    const [sinistros, setSinistros] = useState([]);
    const [sinistroAtivo, setSinistroAtivo] = useState();
    const [apoliceAtiva, setApoliceAtiva] = useState();
    const [seguradoraAtiva, setSeguradoraAtiva] = useState();
    const [visitaAtiva, setVisitaAtiva] = useState();
    const [modalDeleteUsuario, setModalDeleteUsuario] = useState(false);
    const [modalDeleteSinistro, setModalDeleteSinistro] = useState(false);
    const [modalDeleteApolice, setModalDeleteApolice] = useState(false);
    const [modalDeleteSeguradora, setModalDeleteSeguradora] = useState(false);
    const [modalDeleteVisita, setModalDeleteVisita] = useState(false);

    async function getUsuarios() {
        const res = await UsersService.index();
        setUsers(res.data.filter(({ excluido }) => excluido === true));
    };

    async function getSinistros() {
        const res = await SinistrosService.index();
        setSinistros(res.data.filter(({ excluido }) => excluido === true));
    };

    async function getApolices() {
        const res = await ApolicesService.index();
        setApolices(res.data.filter(({ excluido }) => excluido === true));
    };

    async function getSeguradoras() {
        const res = await SeguradorasService.index();
        setSeguradoras(res.data.filter(({ excluido }) => excluido === true));
    };

    async function getVisitas() {
        const res = await VisitasService.index();
        setVisitas(res.data.filter(({ excluido }) => excluido === true));
    };

    useEffect(() => {
        getUsuarios();
        getSinistros();
        getApolices();
        getSeguradoras();
        getVisitas();
    }, []);

// USUÁRIOS
    async function restoreUsuario(id) {
        await UsersService.habilitar(id);
        getUsuarios();
    }

    async function deleteUsuario(id) {
        await UsersService.deletar(id);
        getUsuarios();
        setModalDeleteUsuario(false);
    }

// SINISTROS
    async function restoreSinistro(id) {
        await SinistrosService.restaurar_sinistro(id);
        getSinistros();
    }

    async function deleteSinistro(id) {
        await SinistrosService.delete_sinistro(id);
        getSinistros();
        setModalDeleteSinistro(false);
    }

// APÓLICES
    async function restoreApolice(id) {
        await ApolicesService.ativar(id);
        getApolices();
    }

    async function deleteApolice(id) {
        await ApolicesService.delete(id);
        getApolices();
        setModalDeleteApolice(false);
    }

// SEGURADORAS
    async function restoreSeguradora(id) {
        await SeguradorasService.ativar(id);
        getSeguradoras();
    }

    async function deleteSeguradora(id) {
        await SeguradorasService.delete(id);
        getSeguradoras();
        setModalDeleteSeguradora(false);
    }

// VISITAS
    async function restoreVisita(id) {
        await VisitasService.ativar(id);
        getVisitas();
    }

    async function deleteVisita(id) {
        await VisitasService.delete(id);
        getVisitas();
        setModalDeleteVisita(false);
    }

    document.title = `Excluidos - JMX Corretora de Seguros`;

    return(
        <Fragment>
            <main id='main-excluidos'>
                <div className="cabecalho-conteudo">
                    <h2>Excluidos</h2>
                    <div className="buttons-conteudo">
                        <button className="button-red" onClick={()=> navigate(`/administracao`)}>Voltar</button>
                        <button className="button-blue" onClick={() => {window.print()}}>Imprimir</button>
                    </div>
                </div>
                <div className="tabela-dados">
                    <div className='informacoes-dados'>
                        <h3>Usuários</h3>
                    </div>
                    <div className="cabecalho-dados">
                        <div className="column-50">Nome</div>
                        <div className="column-45">E-mail</div>
                    </div>
                    <div className="corpo-dados corpo-usuarios">
                        { users.length === 0 ? 
                            <div className="row-dados">
                                <div className="column-zerado">
                                    Não foi encontrado nenhum resultado.
                                </div>
                            </div>
                            : (
                                (users.slice(0).reverse().map((users) => (
                                    <Fragment>
                                        <div key={users._id} className="row-dados">
                                            <div className="column-50">
                                                {users.name}
                                            </div>
                                            <div className="column-45">
                                                {users.email}
                                            </div>
                                            <div className="column-icon column-icon-green">
                                                <FontAwesomeIcon icon={faArrowRotateLeft} onClick={() => restoreUsuario(users._id)}/>
                                            </div>
                                            <div className="column-icon column-icon-red">
                                                <FontAwesomeIcon icon={faTrashCan} onClick={() => {setUserAtivo(users); setModalDeleteUsuario(true)}}/>
                                            </div>
                                        </div>
                                    </Fragment>
                                )))
                            )
                        }
                        <h5>Exibindo {users.length} {users.length === 1 ? "resultado" : "resultados"}</h5>
                    </div>
                </div>
                <div className="tabela-dados">
                    <div className='informacoes-dados'>
                        <h3>Sinistros</h3>
                    </div>
                    <div className="cabecalho-dados">
                        <div className="column-25">Sinistrado</div>
                        <div className="column-25">Segurado</div>
                        <div className="column-25">Tipo/Motivo</div>
                        <div className="column-20">Data de Ocorrência</div>
                    </div>
                    <div className="corpo-dados corpo-seguradoras">
                        { sinistros.length === 0 ? 
                            <div className="row-dados">
                                <div className="column-zerado">
                                    Não foi encontrado nenhum resultado.
                                </div>
                            </div>
                            : (
                                (sinistros.slice(0).reverse().map((item) => (
                                    <Fragment>
                                        <div key={item._id} className="row-dados">
                                            <div className="column-25">
                                                {(() => {
                                                    switch (true) {
                                                        case (item.ramo === "Vida"):
                                                            return(item.sinistradoNome)
                                                        case (item.ramo === "Auto"):
                                                            return(`${item.sinistradoPlacas} - ${item.sinistradoModelo} / ${item.sinistradoMarca}`)
                                                        case (item.ramo === "Residêncial"):
                                                            return(`${item.sinistradoEndereco}, ${item.sinistradoNumero}, ${item.sinistradoCidade} / ${item.sinistradoEstado.toUpperCase()}`)
                                                        default:
                                                        break;
                                                    }
                                                })()}
                                            </div>
                                            <div className="column-25">
                                                {item.titularNome}
                                            </div>
                                            <div className="column-25">
                                                {item.ramo} / {item.tipo} / {item.motivo}
                                            </div>
                                            <div className="column-20">
                                                { item.dataSinistro === "" || item.dataSinistro === undefined || item.dataSinistro === null ?
                                                    "--"
                                                :
                                                    <Moment format="DD/MM/YYYY">
                                                        {item.dataSinistro}
                                                    </Moment>
                                                }
                                            </div>
                                            <div className="column-icon column-icon-green">
                                                <FontAwesomeIcon icon={faArrowRotateLeft} onClick={() => restoreSinistro(item._id)}/>
                                            </div>
                                            <div className="column-icon column-icon-red">
                                                <FontAwesomeIcon icon={faTrashCan} onClick={() => {setSinistroAtivo(item); setModalDeleteSinistro(true)}}/>
                                            </div>
                                        </div>
                                    </Fragment>
                                )))
                            )
                        }
                        <h5>Exibindo {sinistros.length} {sinistros.length === 1 ? "resultado" : "resultados"}</h5>
                    </div>
                </div>
                <div className="tabela-dados">
                    <div className='informacoes-dados'>
                        <h3>Apólices</h3>
                    </div>
                    <div className="cabecalho-dados">
                        <div className="column-50">Titulo / Seguradora</div>
                        <div className="column-45">N° de Apólice</div>
                    </div>
                    <div className="corpo-dados corpo-apolices">
                        { apolices.length === 0 ? 
                            <div className="row-dados">
                                <div className="column-zerado">
                                    Não foi encontrado nenhum resultado.
                                </div>
                            </div>
                            : (
                                (apolices.slice(0).reverse().map((item) => (
                                    <Fragment>
                                        <div key={item._id} className="row-dados">
                                            <div className="column-50">
                                                {item.titulo} / {item.seguradora.fantasia}
                                            </div>
                                            <div className="column-45">
                                                {item.apolice_vg} / {item.apolice_ap}
                                            </div>
                                            <div className="column-icon column-icon-green">
                                                <FontAwesomeIcon icon={faArrowRotateLeft} onClick={() => restoreApolice(item._id)}/>
                                            </div>
                                            <div className="column-icon column-icon-red">
                                                <FontAwesomeIcon icon={faTrashCan} onClick={() => {setModalDeleteApolice(true); setApoliceAtiva(item)}}/>
                                            </div>
                                        </div>
                                    </Fragment>
                                )))
                            )
                        }
                        <h5>Exibindo {apolices.length} {apolices.length === 1 ? "resultado" : "resultados"}</h5>
                    </div>
                </div>
                <div className="tabela-dados">
                    <div className='informacoes-dados'>
                        <h3>Seguradoras</h3>
                    </div>
                    <div className="cabecalho-dados">
                        <div className="column-50">Razão Social</div>
                        <div className="column-45">CNPJ</div>
                    </div>
                    <div className="corpo-dados corpo-seguradoras">
                        { seguradoras.length === 0 ? 
                            <div className="row-dados">
                                <div className="column-zerado">
                                    Não foi encontrado nenhum resultado.
                                </div>
                            </div>
                            : (
                                (seguradoras.slice(0).reverse().map((item) => (
                                    <Fragment>
                                        <div key={item._id} className="row-dados">
                                            <div className="column-50">
                                                {item.razao}
                                            </div>
                                            <div className="column-45">
                                                {item.cnpj}
                                            </div>
                                            <div className="column-icon column-icon-green">
                                                <FontAwesomeIcon icon={faArrowRotateLeft} onClick={() => restoreSeguradora(item._id)}/>
                                            </div>
                                            <div className="column-icon column-icon-red">
                                                <FontAwesomeIcon icon={faTrashCan} onClick={() => {setModalDeleteSeguradora(true); setSeguradoraAtiva(item)}}/>
                                            </div>
                                        </div>
                                    </Fragment>
                                )))
                            )
                        }
                        <h5>Exibindo {seguradoras.length} {seguradoras.length === 1 ? "resultado" : "resultados"}</h5>
                    </div>
                </div>
                <div className="tabela-dados">
                    <div className='informacoes-dados'>
                        <h3>Visitas</h3>
                    </div>
                    <div className="cabecalho-dados">
                        <div className="column-50">Empresa</div>
                        <div className="column-15">Dia da Visita</div>
                        <div className="column-15">Horário de início</div>
                        <div className="column-15">Horário de saída</div>
                    </div>
                    <div className="corpo-dados corpo-visitas">
                        { visitas.length === 0 ? 
                            <div className="row-dados">
                                <div className="column-zerado">
                                    Não foi encontrado nenhum resultado.
                                </div>
                            </div>
                            : (
                                (visitas.slice(0).reverse().map((item) => (
                                    <Fragment>
                                        <div key={item._id} className="row-dados">
                                            <div className="column-50">
                                                {item.empresa}
                                            </div>
                                            <div className="column-15">
                                                <Moment add={{ hours: 3 }} format="DD [de] MMMM">{item.dia}</Moment>
                                            </div>
                                            <div className="column-15">
                                                <Moment format='HH:mm'>{item.inicio}</Moment>
                                            </div>
                                            <div className="column-15">
                                                <Moment format='HH:mm'>{item.fim}</Moment>
                                            </div>
                                            <div className="column-icon column-icon-green">
                                                <FontAwesomeIcon icon={faArrowRotateLeft} onClick={() => restoreVisita(item._id)}/>
                                            </div>
                                            <div className="column-icon column-icon-red">
                                                <FontAwesomeIcon icon={faTrashCan} onClick={() => {setModalDeleteVisita(true); setVisitaAtiva(item)}}/>
                                            </div>
                                        </div>
                                    </Fragment>
                                )))
                            )
                        }
                        <h5>Exibindo {visitas.length} {visitas.length === 1 ? "resultado" : "resultados"}</h5>
                    </div>
                </div>
            </main>
            {modalDeleteUsuario ? (
                <ModalDangerSmall mensagem={['Deseja excluir definitivamente o usuário', <br/>,<span>{userAtivo.name}</span>,"?"]} onClose={() => {setModalDeleteUsuario(false)}}>
                    <Fragment>
                        <div className='buttons-form'>
                            <button type="button" className="button-red" onClick={() => {setModalDeleteUsuario(false)}}>Cancelar</button>
                            <button type="submit" className="button-blue" onClick={() => {deleteUsuario(userAtivo._id)}}>Excluir</button>
                        </div>
                    </Fragment>
                </ModalDangerSmall>
            ): null}
            {modalDeleteSinistro ? (
                <ModalDangerSmall mensagem={['Deseja excluir definitivamente o sinistro do', <br/>,<span>{sinistroAtivo.titularNome}</span>,"?"]} onClose={() => {setModalDeleteSinistro(false)}}>
                    <Fragment>
                        <div className='buttons-form'>
                            <button type="button" className="button-red" onClick={() => {setModalDeleteSinistro(false)}}>Cancelar</button>
                            <button type="submit" className="button-blue" onClick={() => {deleteSinistro(sinistroAtivo._id)}}>Excluir</button>
                        </div>
                    </Fragment>
                </ModalDangerSmall>
            ): null}
            {modalDeleteApolice ? (
                <ModalDangerSmall mensagem={['Deseja excluir definitivamente a apolice', <br/>,<span>{apoliceAtiva.titulo}</span>,"?"]} onClose={() => {setModalDeleteApolice(false)}}>
                    <Fragment>
                        <div className='buttons-form'>
                            <button type="button" className="button-red" onClick={() => {setModalDeleteApolice(false)}}>Cancelar</button>
                            <button type="submit" className="button-blue" onClick={() => {deleteApolice(apoliceAtiva._id)}}>Excluir</button>
                        </div>
                    </Fragment>
                </ModalDangerSmall>
            ): null}
            {modalDeleteSeguradora ? (
                <ModalDangerSmall mensagem={['Deseja excluir definitivamente a seguradora', <br/>,<span>{seguradoraAtiva.fantasia}</span>,"?"]} onClose={() => {setModalDeleteSeguradora(false)}}>
                    <Fragment>
                        <div className='buttons-form'>
                            <button type="button" className="button-red" onClick={() => {setModalDeleteSeguradora(false)}}>Cancelar</button>
                            <button type="submit" className="button-blue" onClick={() => {deleteSeguradora(seguradoraAtiva._id)}}>Excluir</button>
                        </div>
                    </Fragment>
                </ModalDangerSmall>
            ): null}
            {modalDeleteVisita ? (
                <ModalDangerSmall mensagem={['Deseja excluir definitivamente a visita à empresa', <br/>,<span>{visitaAtiva.empresa}</span>,"?"]} onClose={() => {setModalDeleteVisita(false)}}>
                    <Fragment>
                        <div className='buttons-form'>
                            <button type="button" className="button-red" onClick={() => {setModalDeleteVisita(false)}}>Cancelar</button>
                            <button type="submit" className="button-blue" onClick={() => {deleteVisita(visitaAtiva._id)}}>Excluir</button>
                        </div>
                    </Fragment>
                </ModalDangerSmall>
            ): null}
        </Fragment>
    )
};